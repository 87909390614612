import PopupForm from "../../../components/popup-form/PopupFormV2";
import { useState, useRef, useEffect } from "react";
import { DateBox, NumberBox, CheckBox, Button, DropDownButton } from "devextreme-react";
import CustomizedLookup from "../../../components/lookup/Lookup";
import baseapi from "../../../api/baseapi";
import DataGrid, { Column, Editing, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import utils, { loading, closeLoading, showMessage } from "../../../utils/common";
import Validator, { CustomRule, RequiredRule } from "devextreme-react/validator";

export default function BankReconciliationForm(props) {
	const filterDefaultValue = {
		MarkOnly: false,
		ListAll: false,
		FromDate: new Date("1900-01-01")
	};
	const calculationDefaultValue = {
		BankStatementBalance: 0,
		BankStatementBalance2: 0,
		BankLedger: 0,
		OutstandingBankLedger: 0
	};
	const currentToken = localStorage.getItem("Authorization").substring(7);
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [dataSource, setDataSource] = useState({
		Parent: {},
		GLBankMonth: []
	});
	const [filterValue, setFilterValue] = useState(filterDefaultValue);
	const [calculationValue, setCalculationValue] = useState(calculationDefaultValue);
	const [disabledState, setDisabledState] = useState(false);

	const gridRef = useRef(null);
	const formRef = useRef(null);
	const chartCodeLookupRef = useRef(null);
	const documentViewerLink = useRef(null);
	const formHandlingRef = useRef({
		checkFilter: false,
		submitForm: false
	});
	const saveButtonItems = [
		{ id: 1, text: "Save & Close" },
		{ id: 2, text: "Save & Print" }
	];
	const [savePrint, setSavePrint] = useState(false);

	const onValueChanged = (e, receivedDataField = null) => {
		const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
		const copied = { ...filterValue };

		if (dataField === "BankStatementDate") {
			copied["ToDate"] = e.value;
		} else if (dataField === "MarkOnly") {
			applyFilter();
			if (e.value) {
				copied["ListAll"] = false;
			}
		} else if (dataField === "ListAll") {
			applyFilter();
			if (e.value) {
				copied["MarkOnly"] = false;
			}
		}

		copied[dataField] = e.value;
		setFilterValue(copied);
	};

	const calculationValueChanged = (e, receivedDataField = null) => {
		if (!utils.isNullOrEmpty(e.value)) {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...calculationValue };

			if (dataField === "BankStatementBalance") {
				const bankStatementBalance = e.value;
				copied["OutstandingBankLedger"] = utils.subtract(copied["BankStatementBalance2"], bankStatementBalance);
				copied[dataField] = e.value;
				setCalculationValue(copied);
			}
		}
	};

	const bankReconGet = () => {
		const formData = props.formDetails;

		if (!utils.isNullOrEmpty(formData)) {
			loading(`Loading ${props.title} records`);
			baseapi
				.httpget(`/api/BankReconciliation/Get`, {
					id: formData["id"],
					chartAccountID: filterValue["ChartAccountID"],
					bankStatementDate: filterValue["BankStatementDate"],
					fromDate: filterValue["FromDate"],
					toDate: filterValue["ToDate"],
					mark: filterValue["MarkOnly"],
					listAll: filterValue["ListAll"]
				})
				.then((response) => {
					const data = response.data.model;
					const copied = { ...calculationValue };
					const array = data.GLBankMonth;
					const parent = data.Parent;
					//Format the data value
					for (var i = 0; i < array.length; i++) {
						array[i]["ReconDate"] = reconDate(array[i]);
					}

					// copied["BankStatementBalance"] = parent["BankStatementBalance"];

					setDataSource({
						Parent: dataSource.Parent,
						GLBankMonth: array
					});

					if (array.length === 0) {
						showMessage("Message", "No Data Found");
					} else {
						closeLoading();
					}
				})
				.catch(() => {
					throw "Network error";
				});
		}
	};

	const onInitialized = () => {
		const formData = props.formDetails;
		setSavePrint(false);

		if (formData["id"] !== "new") {
			loading(`Loading Existing ${props.title}...`);

			const filterCopied = { ...filterValue };
			const calculationCopied = { ...calculationValue };
			filterCopied["BankStatementDate"] = formData["BankStatementDate"];
			filterCopied["ChartAccountID"] = formData["ChartAccountID"];
			filterCopied["ChartCodeName"] = formData["ChartAccountName"];
			filterCopied["ModifiedDate"] = formData["ModifiedDate"];
			filterCopied["FromDate"] = formData["FromDate"];
			filterCopied["ToDate"] = formData["ToDate"];
			filterCopied["ListAll"] = true;
			calculationCopied["BankStatementBalance"] = formData["BankStatementBalance"];
			setFilterValue(filterCopied);
			setCalculationValue(calculationCopied);
			setDisabledState(true);
			applyFilter();
		} else {
			setDisabledState(false);
		}
	};

	const handleSubmit = (saveClose) => {
		// loading(`Saving ${props.title}...`);
		const submitValue = {
			Parent: Object.assign(Object.assign(dataSource.Parent, calculationValue), filterValue),
			GLBankMonth: dataSource.GLBankMonth.filter((row) => row.Reconcile && !row.Disable)
		};

		baseapi.httppost(utils.extendUrlVar("/api/BankReconciliation/save", { id: props.formDetails["id"] || "" }), submitValue).then((response) => {
			const { data } = response;
			closeLoading();
			if (data.status) {
				if (saveClose) {
					baseapi.httpget(`${utils.ReportURL}/api/Report/Get?accessToken=${currentToken}`).then((response) => {
						const { data } = response;
						if (Array.isArray(data)) {
							const found = data.find((report) => report.ID === "gvQYn8Lfi0c%3d");

							if (!utils.isNullOrEmpty(found)) {
								documentViewerLink.current = found.ViewerAccessURL + `&AsDate=${filterValue["BankStatementDate"]}&ChartAccountID=${filterValue["ChartAccountID"]}`;
								loading("Redirecting to Report...");
								window.open(documentViewerLink.current.toString(), "_blank");
								closeLoading();
							} else {
								utils.popupFormSetErrorForm(formRef, {
									visible: true,
									message: `Report Not Found`,
									type: "Danger"
								});
							}
						}
					});
				}
				utils.popupFormSuccessfulSubmit(formRef, data);
				// clearForm();
			}

			utils.popupFormSetErrorForm(formRef, {
				visible: true,
				message: data.message,
				type: data.status ? "Success" : "Danger",
				action: data.action
			});
			// if (data.docNo) setReportDocumentNo(data.docNo);
		});
	};

	const clearForm = () => {
		setFilterValue(filterDefaultValue);
		setCalculationValue(calculationDefaultValue);
		setDataSource({
			Parent: {},
			GLBankMonth: []
		});
		props.closePopup(null);
	};

	const reconDate = (data) => {
		if (utils.isNullOrEmpty(data.ReconDate) && !data.Reconcile) {
			return "";
		} else if (utils.isNullOrEmpty(data.ReconDate) && data.Reconcile) {
			return utils.dateFormatter(filterValue["BankStatementDate"]);
		} else if (!utils.isNullOrEmpty(data.ReconDate)) {
			return data.ReconDate;
		} else {
			return "";
		}
	};

	const onRowUpdating = (e) => {
		// const oldData = e.oldData;
		const newData = e.newData;

		if (newData.Reconcile) {
			newData["ReconDate"] = utils.dateFormatter(filterValue["BankStatementDate"]);
		} else {
			newData["ReconDate"] = "";
		}
	};

	const fromDateValidation = (e) => {
		const fromDate = new Date(e?.value) ?? new Date(filterValue["FromDate"]);
		const toDate = new Date(filterValue["ToDate"]);

		// if(toDate == "Invalid Date" || bankStatementDate == "Invalid Date") return true;
		return fromDate && toDate && toDate.getDate() >= fromDate.getDate();
	};

	const toDateValidation = (e) => {
		const bankStatementDate = new Date(filterValue["BankStatementDate"]);
		const toDate = new Date(e?.value) ?? new Date(filterValue["ToDate"]);

		// console.log("to date", toDate.getTime(), "statement date", bankStatementDate.getTime());

		// if(toDate == "Invalid Date" || bankStatementDate == "Invalid Date") return true;
		return toDate && bankStatementDate && bankStatementDate.getDate() >= toDate.getDate();
	};

	const applyFilter = () => {
		formHandlingRef.current["checkFilter"] = true; //Submit Filter Form
		setStartValidation(crypto.randomUUID());
	};

	const saveForm = () => {
		formHandlingRef.current["submitForm"] = true; //Submit Filter Form
		setStartValidation(crypto.randomUUID());
	};

	const updateCalculation = ({ children = null }) => {
		const copied = { ...calculationValue };
		const childrenCopied = children ? children : gridRef.current.instance.getVisibleRows();
		var depositCheque = 0;
		var unpresentedCheque = 0;
		var bankStatementBalance2 = 0;

		for (var i = 0; i < childrenCopied.length; i++) {
			const rowData = children ? childrenCopied[i] : childrenCopied[i]["data"];

			if (!rowData["Reconcile"]) {
				depositCheque = utils.add(depositCheque, rowData["DebitAmount"]);
				unpresentedCheque = utils.add(unpresentedCheque, rowData["CreditAmount"]);
			}
		}

		copied["DepositCheque"] = depositCheque;
		copied["UnpresentedCheque"] = unpresentedCheque;

		bankStatementBalance2 = utils.add(copied["BankLedger"], utils.subtract(copied["UnpresentedCheque"], copied["DepositCheque"]));
		copied["BankStatementBalance2"] = bankStatementBalance2;
		copied["OutstandingBankLedger"] = utils.subtract(copied["BankStatementBalance"], copied["BankStatementBalance2"]);
		setCalculationValue(copied);
	};

	const markAll = (e) => {
		const copied = [...dataSource.GLBankMonth];
		const value = e.value;

		if (value) {
			for (var i = 0; i < copied.length; i++) {
				copied[i]["Reconcile"] = true;
				copied[i]["ReconDate"] = utils.dateFormatter(filterValue["BankStatementDate"]);
			}
		} else {
			for (var i = 0; i < copied.length; i++) {
				copied[i]["Reconcile"] = false;
				copied[i]["ReconDate"] = "";
			}
		}
		updateCalculation({ children: copied });
		// setDataSource({
		//     Parent: dataSource.Parent,
		//     GLBankMonth: copied
		// });
	};

	const checkValidation = (valid) => {
		if (valid) {
			if (!utils.isNullOrEmpty(formHandlingRef.current)) {
				const formHandling = formHandlingRef.current;

				if (formHandling["checkFilter"] === true) {
					bankReconGet();
					formHandling["checkFilter"] = false;
				} else if (formHandling["submitForm"] === true) {
					handleSubmit(savePrint);
					formHandling["submitForm"] = false;
				}
			}
		}
	};

	const onEditorPreparing = (e) => {
		//console.log('onEditorPreparing')
		if (e.row.data.Disable) {
			e.editorOptions.disabled = true;
		}
	};

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (!utils.isNullOrEmpty(props.formDetails)) {
			const id = props.formDetails?.id;
			utils.popupFormOpen(formRef);

			if (id !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formDetails]);

	useEffect(() => {
		const copied = { ...calculationValue };
		var depositCheque = 0;
		var unpresentedCheque = 0;

		for (var i = 0; i < dataSource.GLBankMonth.length; i++) {
			const rowData = dataSource.GLBankMonth[i];

			if (!rowData["Reconcile"]) {
				depositCheque = utils.add(depositCheque, rowData["DebitAmount"]);
				unpresentedCheque = utils.add(unpresentedCheque, rowData["CreditAmount"]);
			}
		}

		copied["DepositCheque"] = depositCheque;
		copied["UnpresentedCheque"] = unpresentedCheque;

		if (dataSource.GLBankMonth.length > 0) {
			baseapi
				.httpget(`/api/BankReconciliation/bankBookBalance`, {
					chartAccountID: filterValue["ChartAccountID"],
					bankStatementDate: filterValue["BankStatementDate"],
					local: true
				})
				.then((response) => {
					const { result } = response.data;
					copied["BankLedger"] = result;
					copied["BankStatementBalance2"] = utils.add(result, utils.subtract(copied["UnpresentedCheque"], copied["DepositCheque"]));
					copied["OutstandingBankLedger"] = utils.subtract(copied["BankStatementBalance"], copied["BankStatementBalance2"]);
					setCalculationValue(copied);
				});
		} else {
			//Reset all the values to 0 if there is no data found
			Object.keys(copied).forEach((key) => {
				copied[key] = 0;
			});
			setCalculationValue(copied);
		}

		if (!utils.isNullOrEmpty(props.formDetails)) {
			if (props.formDetails["id"] !== "new") closeLoading();
		}
	}, [dataSource]);

	const saveFormActionButton = (e) => {
		const { text } = e.itemData;

		if (text === "Save & Close") {
			// saveAndClose();
			setSavePrint(false);
		} else if (text === "Save & Print") {
			// saveAndPrint();
			setSavePrint(true);
		}
		saveForm();
	};

	return (
		<div>
			<PopupForm
				ref={formRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				headerClassName={"gl-module-theme-color gl-module-title-font-size"}
				title={props.title}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				onValidating={setStartValidation}
				validationResult={checkValidation}
				startValidation={startValidation}
				isEditing={isEditing}
				lookupValidateRef={[chartCodeLookupRef]}
				customizedFormFooter={
					<div className='popup-form-item-container2 item-align-bottom' style={{ padding: "0px 20px" }}>
						<div>
							<div className='display-inline-block margin-right-1'>
								<CheckBox elementAttr={{ dataField: "MarkOnly" }} onValueChanged={onValueChanged} value={filterValue["MarkOnly"]} />
								<span className='marginl-1'>Mark Only</span>
							</div>

							<div className='display-inline-block'>
								<CheckBox elementAttr={{ dataField: "ListAll" }} onValueChanged={onValueChanged} value={filterValue["ListAll"]} />
								<span className='marginl-1'>List All</span>
							</div>
						</div>

						<div style={{ textAlign: "right" }}>
							{/* <Button text='Save' type='success' onClick={saveForm} /> */}

							<DropDownButton
								width={"auto"}
								id='submit-link'
								splitButton={true}
								useSelectMode={true}
								// disabled={isSaveButtonDisabled() || props.disableButtons}
								text='Save'
								items={saveButtonItems}
								displayExpr='text'
								keyExpr='id'
								dropDownOptions={{ width: 120 }}
								elementAttr={{ class: "popup-form-submit-btn" }}
								style={{ backgroundColor: "#5cb85c", borderRadius: "4px", marginRight: "5px" }}
								stylingMode='text'
								onItemClick={(e) => saveFormActionButton(e, "Save")}
								onButtonClick={() => {
									// if (isEditing) {
									// 	saveAndClose();
									// } else {
									// 	continueOnButtonClick();
									// }
									saveForm();
								}}
							/>
						</div>
					</div>
				}>
				<div style={{ padding: "0px 20px 20px 20px" }}>
					<div className='gl-bank-recon-filter-container'>
						<div className='popup-form-item-container1 gl-bank-recon-input-container'>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Chart Code: </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={chartCodeLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"name"}
										valueExpr={"id"}
										value={filterValue["ChartAccountID"]}
										onSelectionChanged={(e) => onValueChanged(e, "ChartAccountID")}
										dataSourceURL={"api/Utilities/GetGLBKAccount"}
										displayText={filterValue["ChartCodeName"]}
										disabled={disabledState}
										required={true}
										requiredMessage={"Chart Code is required!"}
										startValidation={startValidation !== 0}
									>
										<Column dataField='code'></Column>
										<Column dataField='name'></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Bank Statement Date: </div>

								<div className='popup-group-form-input'>
									<DateBox
										elementAttr={{ dataField: "BankStatementDate" }}
										displayFormat='dd/MM/yyyy'
										placeholder='Bank Statement Date'
										useMaskBehavior={true}
										type='date'
										showClearButton={true}
										onValueChanged={onValueChanged}
										value={filterValue["BankStatementDate"]}
										disabled={disabledState}
										dateSerializationFormat='yyyy-MM-dd'>
										<Validator>{startValidation !== 0 && <RequiredRule message={"Bank Statement Date is required"} />}</Validator>
									</DateBox>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Date Filter: </div>

								<div className='popup-group-form-input'>
									<div className='popup-form-item-container2'>
										<div style={{ marginRight: "10px" }}>
											<DateBox
												elementAttr={{ dataField: "FromDate" }}
												displayFormat='dd/MM/yyyy'
												dateSerializationFormat='yyyy-MM-dd'
												placeholder='From'
												useMaskBehavior={true}
												type='date'
												showClearButton={true}
												onValueChanged={onValueChanged}
												value={filterValue["FromDate"]}>
												<Validator>
													{startValidation !== 0 && <RequiredRule message={"From Date is required"} />}

													{startValidation !== 0 && <CustomRule message={"From Date cannot exceed To Date!"} validationCallback={fromDateValidation} />}
												</Validator>
											</DateBox>
										</div>

										<div>
											<DateBox
												elementAttr={{ dataField: "ToDate" }}
												displayFormat='dd/MM/yyyy'
												dateSerializationFormat='yyyy-MM-dd'
												placeholder='To'
												useMaskBehavior={true}
												type='date'
												showClearButton={true}
												onValueChanged={onValueChanged}
												value={filterValue["ToDate"]}>
												<Validator>
													{startValidation !== 0 && <RequiredRule message={"To Date is required!"} />}

													{startValidation !== 0 && <CustomRule message={"To Date cannot exceed Bank Statement Date!"} validationCallback={toDateValidation} />}
												</Validator>
											</DateBox>
										</div>
									</div>
								</div>
							</div>

							<div style={{ textAlign: "right" }} className='margin-top-1 margin-right-1'>
								<Button text='Apply' type='success' onClick={applyFilter} />
							</div>
						</div>

						<div className='gl-bank-recon-total-container'>
							<div className='popup-form-item-container1 margin-1'>
								<div className='bank-recon-filter-item-container3'>
									<div className='margin-05'>Bank Statement Balance</div>

									<div className='margin-05'>
										<NumberBox elementAttr={{ dataField: "BankStatementBalance" }} onValueChanged={calculationValueChanged} value={calculationValue["BankStatementBalance"]} format='#,##0.00' />
									</div>

									<div className='margin-05'>
										<NumberBox value={calculationValue["BankStatementBalance2"]} readOnly={true} format='#,##0.00' />
									</div>
								</div>

								<div className='bank-recon-filter-item-container3'>
									<div className='margin-05'>Unpresented Cheque</div>

									<div className='margin-05'>
										<NumberBox value={calculationValue["UnpresentedCheque"]} readOnly={true} format='#,##0.00' />
									</div>

									<div></div>
								</div>

								<div className='bank-recon-filter-item-container3'>
									<div className='margin-05'>Deposit Cheque</div>

									<div className='margin-05'>
										<NumberBox value={calculationValue["DepositCheque"]} readOnly={true} format='#,##0.00' />
									</div>

									<div></div>
								</div>

								<div className='bank-recon-filter-item-container3 item-align-bottom'>
									<div className='margin-05'>Bank Book Balance</div>

									<div className='margin-05 margin-top-none'>
										<NumberBox value={calculationValue["BankLedger"]} readOnly={true} format='#,##0.00' />
									</div>

									<div className='margin-05 margin-top-none'>
										<span className='text-red font-mini'>Outstanding Balance</span>
										<NumberBox value={calculationValue["OutstandingBankLedger"]} readOnly={true} format='#,##0.00' />
									</div>
								</div>
							</div>
						</div>
					</div>

					<DataGrid
						ref={gridRef}
						dataSource={dataSource.GLBankMonth}
						keyExpr='ID'
						showBorders={true}
						columnAutoWidth={true}
						allowColumnReordering={true}
						allowColumnResizing={true}
						loadPanel={{ enabled: false }}
						onRowUpdating={onRowUpdating}
						onRowUpdated={updateCalculation}
						onEditorPreparing={onEditorPreparing}
					>
						<Toolbar>
							<ToolbarItem>
								<CheckBox elementAttr={{ dataField: "MarkOnly" }} onValueChanged={markAll} />

								<span className='margin-right-1 marginl-1'>Mark All</span>
							</ToolbarItem>

							<ToolbarItem name='columnChooserButton' locateInMenu='auto' location='after' />
						</Toolbar>
						<ColumnChooser enabled={true} />
						<Editing allowAdding={false} allowUpdating={true} allowDeleting={false} mode='cell' useIcons={true} newRowPosition={"last"}></Editing>
						<Column dataField={"DocumentDate"} caption={"Bank Statement Date"} format='dd/MM/yyyy' dataType={"date"} editorOptions={{ readOnly: true }} />
						<Column dataField={"DocumentNo"} caption={"Doc No"} editorOptions={{ readOnly: true }} />
						<Column dataField={"ChequeNo"} editorOptions={{ readOnly: true }} />
						<Column dataField={"Description"} editorOptions={{ readOnly: true }} />
						<Column dataField={"DebitAmount"} caption={"Debit"} format={{ type: "fixedPoint", precision: 2 }} editorOptions={{ readOnly: true }} />
						<Column dataField={"CreditAmount"} caption={"Credit"} format={{ type: "fixedPoint", precision: 2 }} editorOptions={{ readOnly: true }} />
						<Column dataField={"Reconcile"} dataType={"boolean"}/>
						<Column dataField={"ReconDate"} editorOptions={{ readOnly: true }} />
						<Column dataField={"ForexDebit"} visible={false} format={{ type: "fixedPoint", precision: 2 }} editorOptions={{ readOnly: true }} />
						<Column dataField={"ForexCredit"} visible={false} format={{ type: "fixedPoint", precision: 2 }} editorOptions={{ readOnly: true }} />
						<Column dataField={"ForexName"} visible={false} caption={"Forex Code"} editorOptions={{ readOnly: true }} />

						<Summary>
							<TotalItem
								column='DebitAmount'
								summaryType='sum'
								valueFormat={"#,##0.00"}
								customizeText={(data) => {
									return data.valueText.replace("Sum: ", "");
								}}
							/>

							<TotalItem
								column='CreditAmount'
								summaryType='sum'
								valueFormat={"#,##0.00"}
								customizeText={(data) => {
									return data.valueText.replace("Sum: ", "");
								}}
							/>

							<TotalItem
								column='ForexDebit'
								summaryType='sum'
								valueFormat={"#,##0.00"}
								customizeText={(data) => {
									return data.valueText.replace("Sum: ", "");
								}}
							/>

							<TotalItem
								column='ForexCredit'
								summaryType='sum'
								valueFormat={"#,##0.00"}
								customizeText={(data) => {
									return data.valueText.replace("Sum: ", "");
								}}
							/>
						</Summary>
					</DataGrid>
				</div>
			</PopupForm>
		</div>
	);
}
