import { useEffect, useRef, useState, forwardRef } from "react";
import baseapi from "../../api/baseapi";
import DataGrid, { Editing, Toolbar, Item as ToolbarItem, Popup } from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react";
import DropDownBox from "devextreme-react/drop-down-box";
import utils from "../../utils/common";
import ValidationGroup from "devextreme-react/validation-group";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import ErrorPopUpForm from "../popup-form/ErrorPopupForm";

export default forwardRef(function MultipleSelectionLookup(props, ref) {
	const apiURL = props.apiURL !== undefined ? props.apiURL : null;
	const [selectedKey, setSelectedKey] = useState(null);
	const [dropdownOpened, setDropdownOpened] = useState(false);
	const [customDataSource, setCustomDataSource] = useState([]);
	const [confirmValue, setConfirmValue] = useState(null);
	const gridRef = useRef(null);
	const searchBoxRef = useRef(null);
	const popupMessageRef = useRef(null);
	const defaultValueExpr = props.valueExpr !== undefined ? props.valueExpr : "id";
	const defaultDisplayExpr = props.displayExpr !== undefined ? props.displayExpr : "id";
	
	useEffect(() => {
		if (selectedKey === null) {
			if (searchBoxRef.current !== null) searchBoxRef.current.instance.focus();
		}

		//Trigger on value changed
		onValueChanged();
	}, [selectedKey]);

	useEffect(() => {
		// console.log("value changed", props.value)
		if (props.hasOwnProperty("value")) {
			setSelectedKey(props.value);
			setConfirmValue(props.value);
		}
	}, [props.value]);

	useEffect(() => {
		if (props.dataSource !== undefined) {
			if (props.allowAdd !== undefined) {
				setCustomDataSource({
					store: new CustomStore({
						key: "id",
						load: () => {
							return props.dataSource;
						},
						insert: (values) => {
							return baseapi
								.httppost(apiURL + "/save?del=false&id='new'", values)
								.then((response) => {
									const { data } = response;
									if (data.status) {
										props.onAdded(data);
									}
									utils.displayPopupMessage(popupMessageRef, {
										visible: true,
										message: data.message,
										type: data.status ? "Success" : "Danger",
									});
								})
								.catch((error) => {});
						}
					})
				});
			} else {
				setCustomDataSource(props.dataSource);
			}
		}
	}, [props.dataSource]);

	useEffect(() => {
		if (!dropdownOpened && isConfirmButtonEnabled()) {
			//Reset the selected option to confirm value if the dropdown is closed
			setSelectedKey(confirmValue);
		}

		if (!dropdownOpened) {
			//Clear the searching value
			if (searchBoxRef.current !== null) {
				searchBoxRef.current.instance.option("value", "");
			}
		}
	}, [dropdownOpened]);

	const isConfirmButtonEnabled = () => {
		return props.confirmButton === true;
	};

	const onValueChanged = () => {
		if (!isConfirmButtonEnabled()) {
			if (props.onValueChanged !== undefined) {
				props.onValueChanged({ value: selectedKey });
			}
		}
	};

	const onSelectionChanged = (changedValue) => {
		if (!isConfirmButtonEnabled()) {
			if (props.onSelectionChanged !== undefined) {
				props.onSelectionChanged({ value: changedValue });
			}
		}

		setSelectedKey(changedValue);
	};

	const confirmSelectionValue = () => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged({ value: selectedKey });
		}

		if (props.onSelectionChanged !== undefined) {
			props.onSelectionChanged({ value: selectedKey });
		}

		if (props.onConfirmSelection !== undefined) {
			props.onConfirmSelection(true);
		}

		setConfirmValue(selectedKey);
		setDropdownOpened(false);
	};

	const clearConfirmValue = () => {
		if (isConfirmButtonEnabled()) {
			setSelectedKey([]);
			setConfirmValue([]);

			if (props.onValueChanged !== undefined) {
				props.onValueChanged({ value: [] });
			}

			if (props.onSelectionChanged !== undefined) {
				props.onSelectionChanged({ value: [] });
			}
		}
	};

	const DataGridRender = () => {
		return (
			<div className='customized-lookup-container'>
				<div className='customized-lookup-search-container'>
					<div>
						<TextBox
							ref={searchBoxRef}
							placeholder='Search...'
							width={160}
							valueChangeEvent='keyup'
							onValueChanged={(e) => {
								gridRef.current.instance.searchByText(e.value);
							}}
							onKeyDown={(e) => {
								// console.log("key down", e.event)
								if (e.event.key === "ArrowDown") {
									gridRef.current.instance.focus();
								}
							}}
						/>
					</div>

					{props.allowAdd === true && (
						<div className='customized-lookup-btn-section'>
							<Button
								icon='add'
								onClick={(e) => {
									gridRef.current.instance.addRow();
								}}
							/>
						</div>
					)}

					<div className='customized-lookup-btn-section'>
						<Button
							text='Clear'
							onClick={(e) => {
								onSelectionChanged([]);
								gridRef.current.instance.deselectAll();
								clearConfirmValue();
							}}
						/>
					</div>

					{props.confirmButton === true && (
						<div className='customized-lookup-btn-section'>
							<Button text='Confirm' type={"success"} onClick={(e) => confirmSelectionValue()} />
						</div>
					)}
				</div>
				<DataGrid
					ref={gridRef}
					className={"lookup-datagrid ".concat(props.className !== undefined ? props.className : "")}
					height={props.gridHeight !== undefined ? props.height : "200px"}
					showBorders={true}
					dataSource={customDataSource}
					columnChooser={{ enabled: false }}
					allowColumnResizing={true}
					allowColumnReordering={true}
					hoverStateEnabled={true}
					columnAutoWidth={props.gridColumnAutoWidth !== undefined ? props.gridColumnAutoWidth : true}
					paging={{ enabled: false, pageSize: 10 }}
					keyExpr={defaultValueExpr}
					scrolling={{ columnRenderingMode: "standard", showScrollbar: "onHover" }}
					noDataText={props.noDataText !== undefined ? props.noDataText : "No data"}
					onKeyDown={(e) => {
						if (e.event.key === "Enter") {
							setDropdownOpened(false);
						}
					}}
					selection={{ mode: "multiple", showCheckBoxesMode: "always", selectAllMode: "page" }}
					onSelectionChanged={(e) => {
						onSelectionChanged(e.selectedRowKeys);
					}}
					selectedRowKeys={selectedKey}>
					<Toolbar visible={false} />
					{props.children}
					<Editing mode={"popup"} allowAdding={true}>
						<Popup title={props.lookupTitle !== undefined ? props.lookupTitle : "Title Not Given"} showTitle={true} width='auto' height='auto' dragEnabled={false} />
					</Editing>
				</DataGrid>
			</div>
		);
	};

	return (
		<div>
			<ValidationGroup ref={ref}>
				<DropDownBox
					onOptionChanged={(e) => {
						if (e.name === "opened") {
							setDropdownOpened(e.value);
						}
					}}
					className={props.dropdownClassName}
					height={props.height}
					opened={dropdownOpened}
					dropDownOptions={{ width: "auto" }}
					dataSource={props.dataSource !== undefined ? props.dataSource : null}
					value={isConfirmButtonEnabled() ? confirmValue : selectedKey}
					displayExpr={defaultDisplayExpr}
					valueExpr={defaultValueExpr}
					contentRender={DataGridRender}
					disabled={props.disabled}>
					<Validator>{props.required && props.startValidation && <ValidatorRequired message={props.requiredMessage !== undefined ? props.requiredMessage : "This field is required!"} />}</Validator>
				</DropDownBox>
			</ValidationGroup>

			<ErrorPopUpForm
				ref={popupMessageRef}
			/>
		</div>
	);
});
