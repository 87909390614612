import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import './HeaderV2.scss';
import { Button } from "devextreme-react";
import UserPanel from "../user-panel/UserPanelV2";
import baseapi from "../../api/baseapi";

export default function Header(props) {
    const [companyName, setCompanyName] = useState(null);
    const [menuOpen, setMenuOpen] = useState(props.menuOpen);
    const navigate = useNavigate();

    const onInitialized = (e) => {
        const userID = JSON.parse(localStorage.getItem('data'));
        // var logoLink = "https://greenplus-api.onlinestar.com.my/upload/";
        // var logoLinkLocal = "https://localhost:44320/upload/";
        // console.log( userID?.userID)
        // baseapi.httpget(`api/User/Get`, { id: userID?.userID }).then(response => {
        //     const { data } = response;
        //     // console.log(data)
        //     // setCompanyName(data.CompanyName);
           
        // });

        baseapi.httpget(`api/Company/Get`).then(response2 => {
            const { data } = response2;
            // console.log(data)
            if (data.Name !== undefined) {
                setCompanyName(data.Name);
            }
        });
    }

    useEffect(() => {
        if (companyName === null) {
            onInitialized();
        }
    }, [companyName])

    useEffect(() => {
        if (props.menuOpen !== undefined) {
            setMenuOpen(props.menuOpen);
        }
    }, [props.menuOpen]);

    return (
        <div className="nav-header-container">
            <div className={`header-logo-container logo-container-white-${!menuOpen}`}>
                <div className="header-nav-button-container">
                    <Button
                        icon="menu"
                        stylingMode="text"
                        className="header-nav-button"
                        onClick={() => { props.onMenuButtonClick(!menuOpen); setMenuOpen(!menuOpen); }}
                    />
                </div>

                <div style={props.logoStyling} onClick={() => navigate("/home")}>
                    <img src={"/images/nav-logo.png"} alt={"Greenplus Accounting"} width={110} id='nav-logo' />
                </div>

            </div>

            <div className="user-panel-container">
                <div className="nav-header-right-section">
                    <UserPanel
                        companyName={companyName}
                        isMobile={props.isMobile}
                    />
                </div>

            </div>
        </div>
    );
}