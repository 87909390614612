import React, { useState, useEffect } from 'react';
import { Column, Lookup} from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import { customerOptions, workshopRefInv, workshopRegistration, workshopTypeOfService, workshopUsedInvoice } from '../../../utils/lookupstore';
import JobSheetForm from './form';
import DropDownButton from 'devextreme-react/drop-down-button';
import { Button } from 'devextreme-react/button';
import { useNavigate } from "react-router-dom";
import { saveToLS } from '../../../utils/localstorage';
import baseapi from '../../../api/baseapi';
import { loading, done } from '../../../utils/common';

export default function JobSheetListing() {

    const navigate = useNavigate();
    const apiURL = `/api/WKJobsheet`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [inputType, setInputType] = useState(null);
    const [registrationList, setRegistrationList] = useState([]);
    const [referInvoiceList, setReferInvoiceList] = useState([]);
    const [typeOfServiceList, setTypeOfServiceList] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [usedInvoiceList, setUsedInvoiceList] = useState([]);
    const [dropDownRead, setDropDownRead] = useState(false);
    const [lockForm, setLockForm] = useState(false);

    useEffect(() => {
        // Promise.allSettled([forexOptions.store.load(), workshopRegistration.store.load(),
        // workshopRefInv.store.load(), workshopTypeOfService.store.load(), workshopJobSheet.store.load(),
        // customerOptions.store.load(), workshopUsedInvoice.store.load()]).then((lists) => {
        //     setDropDownList({
        //         forexList: lists[0].value.data, registrationList: lists[1].value.data,
        //         referInvoiceList: lists[2].value.data, typeOfServiceList: lists[3].value.data,
        //         jobList: lists[4].value.data, customerList: lists[5].value.data, 
        //         usedInvoiceList: lists[6].value.data 
        //     })
        // });

        if(dropDownRead !== true){
            workshopRegistration.store.load().then((list) => {
                setRegistrationList(list.data);
            });

            workshopRefInv.store.load().then((list) => {
                setReferInvoiceList(list.data);
            });

            workshopTypeOfService.store.load().then((list) => {
                setTypeOfServiceList(list.data);
            });

            customerOptions.store.load().then((list) => {
                setCustomerList(list.data);
            });

            workshopUsedInvoice.store.load().then((list) => {
                setUsedInvoiceList(list.data);
            });

            setDropDownRead(true);
        }
    }, [dropDownRead]);

    function handleEdit(e){
        const id = e.row.key;
        const data = e.row.data;

        if(data.WKInvoiceID !== "i1tRx0pzXa0%3d"){
            setLockForm(true);
        }
        else{
            setLockForm(false);
        }
        setCurrentEditID(id);
    }

    const onItemClick = (e) => {
        const data = e.itemData;

        if(data.name === "Come Back Job"){
            setInputType("Come Back Job");
            setCurrentEditID("new");
        }
        // console.log("item click", data);
    }

    const addToInvoice = (e) =>{
        const rowData = e.data;

        if(rowData.WKInvoiceID === "i1tRx0pzXa0%3d"){
            return(
                <Button height={"22px"} icon='export' text='Add to Invoice' onClick={() => {
                    loading("Adding To Invoice...")
    
                    baseapi.httpget('/api/WKJobsheet/Get', { id: rowData["id"] })
                    .then(response => {
                        const { data } = response;
                        const model = data.model;
                        // console.log("Data", data)
                        const customerFound = customerList.find(c => c.id === model["Parent"]["CustomerID"]);
    
                        if(customerFound !== null){
                            const address1 = customerFound?.address1 === null ? "" : customerFound?.address1;
                            const address2 = customerFound?.address2 === null ? "" : customerFound?.address2;
                            const address3 = customerFound?.address3 === null ? "" : customerFound?.address3;
                            model["Parent"]["Address"] = address1 + '\n' + address2 + '\n' + address3;
                            model["Parent"]["Address1"] = address1;
                            model["Parent"]["Address2"] = address2;
                            model["Parent"]["Address3"] = address3;
                            model["Parent"]["Terms"] = customerFound?.term;
                        }

                        // Set default value of chart account to Cash Sales
                        for(var i = 0; i < model["WKJobsheetDetails"].length; i++){
                            model["WKJobsheetDetails"][i]["ChartAccountID"] = "qSLUQqNDMRs=";
                        }
        
                        saveToLS("WorkshopJobSheetAddToInvoice", model);
                        done();
                        navigate(`/workshopinvoice?addToInvoice=${e.data.id}`)
                    });
    
                }}/>
            )
        }
        
    }
    
    return <Container>
        <div id='normal-listing'>
        <Listing
            apiURL={apiURL}
            listingURL="/api/WKJobsheet/list"
            className={"workshop-listing-datagrid"}
            sortColumn={"DocumentNo"}
            listingTitle={"Job Sheet"}
            storageName={"WorkshopJobSheetListing"}
            customizedAddButton={
                <DropDownButton
                    className="workshop-listing-page-add-btn customized-listing-add-btn"
                    splitButton={true}
                    useSelectMode={false}
                    icon='add'
                    text="Add"
                    items={[{ id: 1, name: 'Come Back Job' }]}
                    displayExpr="name"
                    keyExpr="id"
                    width={"100px"}
                    dropDownOptions={
                        {width: "120px"}
                    }
                    onButtonClick={() => {setCurrentEditID("new"); setInputType(null); setLockForm(false);}}
                    onItemClick={onItemClick}
              />
            }
            disabledSortColumn={"AddToInvoice"}
            handleEdit={handleEdit}
        >
            <Column dataField="DocumentNo" />
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
            <Column dataField="RegisterID" caption={"Reg No"}>
                <Lookup dataSource={registrationList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="ModelCode" />
            <Column dataField="CustomerCode" />
            <Column dataField="CustomerName" />
            <Column dataField="ReferInvoiceID" caption={"Ref. Inv"} >
                <Lookup dataSource={referInvoiceList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="ServiceTypeID" caption={"Type of Service"} >
                <Lookup dataSource={typeOfServiceList} valueExpr="id" displayExpr={"name"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="InvoiceAmount" format={{ type: "fixedPoint", precision: 2}} >

            </Column>
            <Column dataField="WKInvoiceID" caption={"Invoice"}>
                <Lookup dataSource={usedInvoiceList} valueExpr={"id"} displayExpr={"name"}/>    
            </Column>
            <Column dataField="AddToInvoice" allowSorting={false} cellRender={addToInvoice}/>
        </Listing>
        </div>
        
        <JobSheetForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            inputType={inputType}
            lockedForm={lockForm}
        />
    </Container>;
}