import React, { useEffect, useState, useRef } from "react";
import baseapi from "../../../api/baseapi";
import Container from "../../../components/container";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { DateBox } from "devextreme-react/date-box";
import utils from "../../../utils/common";
import TreeList, { Column, Lookup, Scrolling } from "devextreme-react/tree-list";
import CustomizedLookup from "../../../components/lookup/Lookup";
import { customerOptions, workshopMechanic, workshopRegistration } from "../../../utils/lookupstore";
import { saveToLS, getFromLS } from "../../../utils/localstorage";
import CustomStore from "devextreme/data/custom_store";
import { useNavigate } from "react-router-dom";
import WorkshopInvoiceForm from "../Invoice/form";

export default function VehicleServiceRecordListing() {
	const [listingDataSource, setListingDataSource] = useState(null);
	const [registrationList, setRegistrationList] = useState([]);
	const [mechanicList, setMechanicList] = useState([]);
	const [customerList, setCustomerList] = useState([]);
	const [currentEditID, setCurrentEditID] = useState(null);
	const [inputType, setInputType] = useState(null);
	const [regNoValue, setRegNoValue] = useState(null);
	const treelistRef = useRef(null);
	const searchBoxRef = useRef(null);
	const fromDate = useRef(null);
	const toDate = useRef(null);
	const query = useRef(null);
	const pageSize = useRef(25);
	const selectedPage = useRef(1);
	const sortOdr = useRef(true);
	const sortColumn = useRef(null);
	const storageName = "WorkshopVehicleServiceRecordListing";

	const RegisterNoColumnWidth = utils.getColumnWidth("RegisterNo", storageName) === null ? 80 : utils.getColumnWidth("RegisterNo", storageName);
	const DocumentNoColumnWidth = utils.getColumnWidth("DocumentNo", storageName) === null ? 100 : utils.getColumnWidth("DocumentNo", storageName);
	const DocumentDateColumnWidth = utils.getColumnWidth("DocumentDate", storageName) === null ? 95 : utils.getColumnWidth("DocumentDate", storageName);
	const MileageColumnWidth = utils.getColumnWidth("Mileage", storageName) === null ? 85 : utils.getColumnWidth("Mileage", storageName);
	const DescriptionColumnWidth = utils.getColumnWidth("Description", storageName) === null ? 390 : utils.getColumnWidth("Description", storageName);
	const QuantityColumnWidth = utils.getColumnWidth("Quantity", storageName) === null ? 60 : utils.getColumnWidth("Quantity", storageName);
	const UnitPriceColumnWidth = utils.getColumnWidth("UnitPrice", storageName) === null ? 105 : utils.getColumnWidth("UnitPrice", storageName);
	const TaxInclusiveColumnWidth = utils.getColumnWidth("TaxInclusive", storageName) === null ? 95 : utils.getColumnWidth("TaxInclusive", storageName);
	const NettAmountColumnWidth = utils.getColumnWidth("NettAmount", storageName) === null ? 105 : utils.getColumnWidth("NettAmount", storageName);
	const ItemDiscountAmtColumnWidth = utils.getColumnWidth("ItemDiscountAmt", storageName) === null ? 135 : utils.getColumnWidth("ItemDiscountAmt", storageName);
	const AmountColumnWidth = utils.getColumnWidth("Amount", storageName) === null ? 135 : utils.getColumnWidth("Amount", storageName);
	const MechanicIDColumnWidth = utils.getColumnWidth("MechanicID", storageName) === 186 ? undefined : utils.getColumnWidth("MechanicID", storageName);

	const RegisterNoVisibleIndex = utils.getColumnVisibleIndex("RegisterNo", storageName) === null ? 0 : utils.getColumnVisibleIndex("RegisterNo", storageName);
	const DocumentNoVisibleIndex = utils.getColumnVisibleIndex("DocumentNo", storageName) === null ? 1 : utils.getColumnVisibleIndex("DocumentNo", storageName);
	const DocumentDateVisibleIndex = utils.getColumnVisibleIndex("DocumentDate", storageName) === null ? 2 : utils.getColumnVisibleIndex("DocumentDate", storageName);
	const MileageVisibleIndex = utils.getColumnVisibleIndex("Mileage", storageName) === null ? 3 : utils.getColumnVisibleIndex("Mileage", storageName);
	const DescriptionVisibleIndex = utils.getColumnVisibleIndex("Description", storageName) === null ? 4 : utils.getColumnVisibleIndex("Description", storageName);
	const QuantityVisibleIndex = utils.getColumnVisibleIndex("Quantity", storageName) === null ? 5 : utils.getColumnVisibleIndex("Quantity", storageName);
	const UnitPriceVisibleIndex = utils.getColumnVisibleIndex("UnitPrice", storageName) === null ? 6 : utils.getColumnVisibleIndex("UnitPrice", storageName);
	const TaxInclusiveVisibleIndex = utils.getColumnVisibleIndex("TaxInclusive", storageName) === null ? 7 : utils.getColumnVisibleIndex("TaxInclusive", storageName);
	const NettAmountVisibleIndex = utils.getColumnVisibleIndex("NettAmount", storageName) === null ? 8 : utils.getColumnVisibleIndex("NettAmount", storageName);
	const ItemDiscountAmtVisibleIndex = utils.getColumnVisibleIndex("ItemDiscountAmt", storageName) === null ? 9 : utils.getColumnVisibleIndex("ItemDiscountAmt", storageName);
	const AmountVisibleIndex = utils.getColumnVisibleIndex("Amount", storageName) === null ? 10 : utils.getColumnVisibleIndex("Amount", storageName);
	const MechanicIDVisibleIndex = utils.getColumnVisibleIndex("MechanicID", storageName) === null ? 11 : utils.getColumnVisibleIndex("MechanicID", storageName);

	useEffect(() => {
		if (regNoValue === null) {
			// Promise.allSettled([workshopRegistration.store.load(), workshopMechanic.store.load(), customerOptions.store.load()]).then((lists) => {
			//     setDropDownList({
			//         registrationList: lists[0].value.data, mechanicList: lists[1].value.data, customerList: lists[2].value.data
			//     });
			// });
			workshopRegistration.store.load().then((list) => {
				setRegistrationList(list.data);
			});

			workshopMechanic.store.load().then((list) => {
				setMechanicList(list.data);
			});

			customerOptions.store.load().then((list) => {
				setCustomerList(list.data);
			});
		} else {
			setListingDataSource({
				store: new CustomStore({
					key: "ID",
					load: () => {
						return baseapi
							.httpget(`api/WKInvoice/GetInvoiceByRegID?regID=${regNoValue}`)
							.then((response) => {
								const { data } = response;
								console.log(data);
								if (data !== null) {
									var completeArray = [];
									var invoiceMainArray = [];
									var invoiceDetailsArray = [];
									for (var i = 0; i < data.length; i++) {
										invoiceMainArray.push(data[i].InvoiceMain);
										invoiceDetailsArray = invoiceDetailsArray.concat(data[i].InvoiceDetail);
									}
									completeArray = invoiceMainArray.concat(invoiceDetailsArray);
									console.log("Complete Array", completeArray);
									return completeArray;
								} else {
									return [];
								}
							})
							.catch(() => {
								throw "Network error";
							});
					}
				})
			});
		}
	}, [regNoValue]);

	const refresh = () => {};

	return (
		<Container>
			<div className='listing-page-title-container'>
				<span className='datagrid-customized-title'>Vehicle Service Record</span>
			</div>

			<div className='listing-datebox-container'>
				<div>
					<span>Reg No: </span>

					<CustomizedLookup
						className={"workshop-listing-datagrid"}
						dropdownClassName={"listing-page-searching-lookup"}
						dataSource={registrationList}
						displayExpr={"code"}
						valueExpr={"id"}
						height={"36px"}
						onSelectionChanged={(e) => setRegNoValue(e.value)}>
						<Column dataField='code'></Column>
						<Column dataField='modelCode' caption={"Model"}></Column>
						<Column dataField='customerID' caption={"Customer Code"}>
							<Lookup dataSource={customerList} valueExpr={"id"} displayExpr={"code"} />
						</Column>
						<Column dataField='customerID' caption={"Customer Name"} name={"customerID2"}>
							<Lookup dataSource={customerList} valueExpr={"id"} displayExpr={"name"} />
						</Column>
					</CustomizedLookup>
				</div>

				<TextBox
					width={"250px"}
					height={"36px"}
					ref={searchBoxRef}
					className='listing-page-search-box'
					placeholder='Search'
					// value={query.current}
					valueChangeEvent='keyup'
					onValueChanged={(e) => {
						query.current = e.value;
						refresh();
						treelistRef.current.instance.searchByText(e.value);
						// console.log("On change", e)
					}}
				/>

				<div className='listing-date-from-container'>
					<span>From: </span>
					<DateBox
						format='dd/MM/yyyy'
						dateSerializationFormat= 'yyyy-MM-dd'
						type='date'
						showClearButton={true}
						onValueChanged={(e) => {
							fromDate.current = e.value;
							refresh();
							// console.log("On change", e)
						}}
					/>
				</div>

				<div className='listing-date-to-container'>
					<span>To: </span>
					<DateBox
						format='dd/MM/yyyy'
						dateSerializationFormat= 'yyyy-MM-dd'
						type='date'
						showClearButton={true}
						onValueChanged={(e) => {
							toDate.current = e.value;
							refresh();
							// console.log("On change", e)
						}}
					/>
				</div>

				{/* <Button
                    height={"38px"}
                    icon='plus'
                    onClick={handleAdd}
                    text="Add"
                    className="listing-page-add-btn"
                /> */}
			</div>

			<TreeList
				ref={treelistRef}
				className='listing-page-treelist workshop-listing-treelist'
				dataSource={listingDataSource}
				allowColumnReordering={true}
				allowColumnResizing={true}
				columnAutoWidth={false}
				columnResizingMode='widget'
				showRowLines={true}
				showBorders={true}
				autoExpandAll={true}
				height={"65vh"}
				width={"100%"}
				keyExpr='ID'
				parentIdExpr='TreeListDocNoRef'
				rootValue={0}
				onRowPrepared={(e) => {
					if (e.rowType === "header" && storageName !== null) {
						const columns = e.columns;
						const previousRecord = getFromLS(storageName);

						if (previousRecord === undefined || previousRecord.length !== columns.length) {
							for (var i = 0; i < columns.length; i++) {
								columns[i]["columnWidth"] = null;
							}
						} else {
							for (var i = 0; i < columns.length; i++) {
								columns[i]["columnWidth"] = previousRecord[i]["columnWidth"];
							}
						}
						saveToLS(storageName, columns);
					}

					// if(e.rowType === "data"){
					//     const data = e.data;

					//     if(data.DocumentNo !== undefined){
					//         e.rowElement.classList.add("workshop-treelist-main");
					//     }
					// }
				}}
				onOptionChanged={(e) => {
					if (e.name === "columns" && e.fullName.includes("width") && storageName !== null) {
						//Sample format : columns[0].width
						const columns = getFromLS(storageName);
						const columnIndex = parseInt(e.fullName.substring(8, 9));
						const arrayIndex = columns.findIndex((x) => x.index === columnIndex);
						// Save the new width
						columns[arrayIndex]["columnWidth"] = e.value;
						saveToLS(storageName, columns);
					}
				}}
				loadPanel={{ enabled: true }}>
				{/* <Column dataField="RegisterNo" width={RegisterNoColumnWidth} visibleIndex={RegisterNoVisibleIndex}>
                    <Lookup dataSource={registrationList} valueExpr="id" displayExpr="code" />
                </Column> */}
				<Column
					dataField='DocumentNo'
					caption={"Doc No"}
					width={DocumentNoColumnWidth}
					visibleIndex={DocumentNoVisibleIndex}
					cellRender={(e) => {
						// console.log("Cell render", e)
						const data = e.data;
						const id = e.key;

						if (data.DocumentNo !== undefined) {
							return (
								<span
									className='clickable-span-tag'
									onClick={() => {
										if (data.JobNo !== "") {
											setInputType("Add By Job Sheet");
										}
										setCurrentEditID(id);
										// navigate(`/workshopinvoice?ID=${e.key}`)
									}}>
									{e.displayValue}
								</span>
							);
						} else {
							return e.displayValue;
						}
					}}
				/>
				<Column
					dataField='DocumentDate'
					caption={"Date"}
					width={DocumentDateColumnWidth}
					visibleIndex={DocumentDateVisibleIndex}
					cellRender={(e) => {
						const data = e.data;
						if (data.DocumentNo !== undefined) {
							return <span className='highlight-span-tag'>{utils.dateFormatter(e.displayValue)}</span>;
						}
					}}
				/>
				<Column
					dataField='Mileage'
					width={MileageColumnWidth}
					visibleIndex={MileageVisibleIndex}
					cellRender={(e) => {
						const data = e.data;
						if (data.DocumentNo !== undefined) {
							return <span className='highlight-span-tag'>{e.displayValue}</span>;
						}
					}}
				/>
				<Column
					dataField='Description'
					dataType={"string"}
					caption={"Part/Description"}
					width={DescriptionColumnWidth}
					visibleIndex={DescriptionVisibleIndex}
					cellRender={(e) => {
						if (utils.isNumberString(e.value)) {
							return <span className='highlight-span-tag'>{"Grand Total: " + utils.numberToCurrency(e.value)}</span>;
						} else {
							return e.value;
						}
					}}
				/>
				<Column dataField='Quantity' caption={"Qty"} width={QuantityColumnWidth} visibleIndex={QuantityVisibleIndex} />
				<Column dataField='UnitPrice' caption={"U/Price"} width={UnitPriceColumnWidth} visibleIndex={UnitPriceVisibleIndex} format={{ type: "fixedPoint", precision: 2 }} />
				<Column
					dataField='TaxInclusive'
					visible={false}
					caption={"SST"}
					dataType={"string"}
					width={TaxInclusiveColumnWidth}
					visibleIndex={TaxInclusiveVisibleIndex}
					customizeText={(v) => {
						if (v.value === true) {
							return "Inclusive";
						} else if (v.value === false) {
							return "Exclusive";
						} else {
							return "";
						}
					}}
				/>
				<Column dataField='NettAmount' caption={"Net/Price"} width={NettAmountColumnWidth} visibleIndex={NettAmountVisibleIndex} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField='ItemDiscount' caption={"Discount"} width={ItemDiscountAmtColumnWidth} visibleIndex={ItemDiscountAmtVisibleIndex} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField='Amount' caption={"Amount"} width={AmountColumnWidth} visibleIndex={AmountVisibleIndex} format={{ type: "fixedPoint", precision: 2 }} />
				<Column dataField='MechanicID' caption={"Mechanic"} width={MechanicIDColumnWidth} visibleIndex={MechanicIDVisibleIndex}>
					<Lookup dataSource={mechanicList} valueExpr='id' displayExpr='name' />
				</Column>

				<Scrolling columnRenderingMode='standard' showScrollbar={"onHover"} useNative={true} />
			</TreeList>

			<WorkshopInvoiceForm formID={currentEditID} closePopup={setCurrentEditID} inputType={inputType} onClosed={setInputType} />
		</Container>
	);
}
