import { useEffect, useRef, useState, useCallback } from "react";
import utils from "../../../utils/common";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea } from "devextreme-react";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../utils/module-calculation";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import { purchaseDebitNoteReportNames } from "../../../utils/lookupstore";
import CreditTermForm from "../../../components/popup-form/CreditTermForm";
import ErrorPopUpForm from "../../../components/popup-form/ErrorPopupForm";
import { uomOptions } from "../../../utils/lookupstore";
import AsyncDateBox from "../../../components/async-date-box/AsyncDateBox";

export default function PurchaseDebitNoteForm(props) {
	const formID = useRef(null);
	const formRef = useRef(null);
	const formRead = useRef(false);
	const childrenGridRef = useRef(null);
	const tempForm = useRef(null);

	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [formValue, setFormValue] = useState({});
	const [lockedForm, setLockedForm] = useState(false);

	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const customerLookupRef = useRef(null);
	const forexLookupRef = useRef(null);
	const areaLookupRef = useRef(null);
	const salesmanLookupRef = useRef(null);
	const GridRequiredColumns = ["StockID", "ChartAccountID"];
	const projectLookupRef = useRef(null);

	const [reportDocumentNo, setReportDocumentNo] = useState(null);
	const [creditTerm, setCreditTerm] = useState(0);
	const [overdueDays, setOverdueDays] = useState(0);
	const [userType, setUserType] = useState("");
	const [creditTermErrorPopup, setCreditTermErrorPopUp] = useState(false);
	const [lockForm, setLockForm] = useState(false);
	const [totalOutAmt, setTotalOutAmt] = useState(0);
	const popupMessageRef = useRef(null);

	useEffect(() => {
		if (!utils.isNullOrEmpty(formValue["DocumentNo"])) {
			setReportDocumentNo(formValue["DocumentNo"]);
		}
	}, [formValue["DocumentNo"]]);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			} else {
				setIsEditing(false);
			}
		}
	}, [props.formID]);

	function onInitialized(e) {
		if (formID.current === "new") loading(`Loading New ${props.title}...`);
		else loading(`Loading Existing ${props.title}...`);

		baseapi.httpget("/api/PurchaseDebitNote/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;
			utils.popupFormAfterInitialization(formRef, props, data);
			tempForm.current = data.model.Parent;

			if (formID.current === "new") {
				//If there is transfer data
				if (!utils.isNullOrEmpty(props.transferData)) {
					const transferData = props.transferData;
					const parent = transferData["Parent"];

					if (parent.IsFullTransferred || parent.IsPartialTransferred) {
						tempForm.current = transferData["Parent"];
						tempForm.current["DocumentNo"] = data.model.Parent["DocumentNo"];
						tempForm.current["DocumentDate"] = data.model.Parent["DocumentDate"];
						tempForm.current["IssueBy"] = data.model.Parent["IssueBy"];
						tempForm.current["TransactionTypeID"] = data.model.Parent["TransactionTypeID"];
						tempForm.current["DocumentPrefix"] = data.model.Parent["DocumentPrefix"];
					}

					//Assignment of default value
					tempForm.current["TransferType"] = transferData["Parent"]["TransferType"];
					tempForm.current["IsFullTransferred"] = transferData["Parent"]["IsFullTransferred"];
					tempForm.current["IsPartialTransferred"] = transferData["Parent"]["IsPartialTransferred"];

					if (!utils.isNullOrEmpty(transferData["Children"])) {
						//Assign children chart account id based on stock group id
						if(Array.isArray(transferData["Children"]) && Array.isArray(transferData["stockGroupDetails"])){
							for(var i = 0; i < transferData["Children"].length; i++){
								const record = transferData["Children"][i];
								const foundStockGroup = transferData["stockGroupDetails"].find(c => c.ID === record.ID);

								if(!utils.isNullOrEmpty(foundStockGroup)){
									record["ChartAccountID"] = foundStockGroup.PurchaseCodeID;
									record["ChartAccountCode"] = foundStockGroup.PurchaseCode;
								}
							}
						}
						const fomulatedChildren = utils.roundUpObjectProperties(moduleCalculation(transferData["Children"], tempForm.current));
						tempForm.current = ParentValueCalculation(transferData["Children"], tempForm.current).parent;
						utils.childrenGridSetSource(childrenGridRef, fomulatedChildren);
					}

					utils.popupFormSetErrorForm(formRef, {
						visible: true,
						message: transferData["status"] ? "Data Transfered Successful" : "Data Transfered Unsuccessful",
						type: transferData["status"] ? "Success" : "Danger"
					});

					//Clear transfer data
					if (!utils.isNullOrEmpty(props.clearTransfer)) {
						props.clearTransfer(null);
					}
				} else {
					utils.childrenGridAddRow(childrenGridRef); //Add row for the frist grid
					setInitialFormState();
				}
			} else {
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
				handleExistingForm(tempForm.current);
			}

			setFormValue(tempForm.current);
			closeLoading();
			formRead.current = true;
		});
	}

	function setInitialFormState() {
		setUserType("");
		setCreditTerm(null);
		setOverdueDays(0);
		setCreditTermErrorPopUp(false);
		setLockForm(false);
		setTotalOutAmt(0);
	}

	function handleExistingForm(parentData) {
		if (parentData.Cancelled) {
			setInitialFormState();
		} else {
			baseapi.httpget("/api/Utilities/GetCreditTermOutAmtList", { documentDate: parentData.DocumentDate, id: parentData.SupplierID, isAR: false }).then((res) => {
				const { data } = res;

				const getParentDate = new Date(data.dateTime);
				const dateToCompare = new Date(parentData.DocumentDate);
				const getExpiredDate = new Date(new Date(getParentDate.getFullYear(), getParentDate.getMonth(), getParentDate.getDate() + parentData.CreditTermsDay));
				const daysDifference = utils.calculateDaysDifferenceExcluding31st(getExpiredDate, dateToCompare);

				setCreditTerm(parentData.CreditTermsDay);
				setOverdueDays(daysDifference);
				setUserType(parentData.SupplierCode);
				setLockForm(daysDifference > 0);

				setTotalOutAmt(data.outAmt);
			});
		}
	}

	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		formID.current = null;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};

	function handleSubmit(e) {
		if(!utils.isNullOrEmpty(e.validData)){
			const validData = e.validData;

			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};
	
			loading(`Saving ${props.title}...`);
	
			baseapi
			.httppost(
				utils.extendUrlVar("/api/PurchaseDebitNote/save", {
					id: formID.current || "",
					del: false
				}),
				submittedForm
			)
			.then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger",
					action: data.action
				});
				if (data.docNo) setReportDocumentNo(data.docNo);
			});
		}
	}

	function valueOnChange(e, receivedDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {
			const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);
			if (!utils.isNullOrEmpty(e.value)) {
				if (dataField === "SupplierID" && formRead && !utils.isNullOrEmpty(e.value)) {
					baseapi.httpget(`api/Supplier/Get`, { id: e.value }).then((response) => {
						const { data } = response;
						const copied = { ...tempForm.current };
						var termDays = data.model.CreditTermDays;

						copied["SupplierName"] = data.model.Name;
						copied["ForexID"] = data.model.ForexID;
						copied["SalesmanID"] = data.model.SalesmanID;
						copied["SalesmanCode"] = data.model.SalesmanCode;
						copied["AreaID"] = data.model.AreaID;
						copied["AreaCode"] = data.model.AreaCode;
						copied["CreditTermsID"] = data.model.CreditTermID;
						copied["CreditTermsCode"] = data.model.CreditTermCode;
						copied["Address"] = data.model.SupplierAddress;
						setCreditTerm(termDays);
						setUserType(data.model.Code);

						baseapi
							.httpget(`/api/Forex/Get`, { id: data.model.ForexID })
							.then((response) => {
								const { data } = response;
								copied["LocalRate"] = data.model.LocalRate;
								copied["ForexRate"] = data.model.ForexRate;

								tempForm.current = copied;
								setFormValue(tempForm.current);
							})
							.catch((error) => {
								console.log(error);
							});

						baseapi.httpget("/api/Utilities/GetCreditTermOutAmtList", { documentDate: formValue["DocumentDate"], id: e.value, isAR: false }).then((res) => {
							const { data } = res;

							const getParentDate = new Date(data.dateTime);
							const dateToCompare = new Date(formValue["DocumentDate"]);
							const getExpiredDate = new Date(new Date(getParentDate.getFullYear(), getParentDate.getMonth(), getParentDate.getDate() + termDays));
							const daysDifference = utils.calculateDaysDifferenceExcluding31st(getExpiredDate, dateToCompare);

							setOverdueDays(daysDifference === -1 ? 0 : daysDifference);
							setTotalOutAmt(data.outAmt);
							setLockForm(daysDifference > 0);
						});
					});
				} else if (dataField === "ForexID" && formRead && !utils.isNullOrEmpty(e.value)) {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;
							tempForm.current = copied;

							setFormValue(tempForm.current);
						})
						.catch((error) => {
							console.log(error);
						});
				} else if (dataField === "LocalRate" && formRead) {
					const copied = { ...tempForm.current };
					const copiedDetails = utils.childrenGridGetSource(childrenGridRef);
					var balance = 0;

					for (var i = 0; i < Object.keys(copiedDetails).length; i++) {
						if (copiedDetails[i]["NettAmount"] === undefined) {
							balance += parseFloat(copiedDetails[i]["ForexAmount"]);
						} else {
							balance += parseFloat(copiedDetails[i]["NettAmount"]);
						}
					}

					copied["MainLocalAmount"] = balance * e.value;

					setFormValue(copied);
				} else if (dataField == "CreditTermsID" && formRead.current) {
					copied[dataField] = e.value;
					tempForm.current = copied;

					setFormValue(tempForm.current);

					baseapi.httpget("/api/CreditTerms/Get", { id: e.value }).then((res) => {
						const { data } = res;
						const copied = { ...tempForm.current };
						var termDays = data.model.Day;
						setCreditTerm(termDays);

						baseapi.httpget("/api/Utilities/GetCreditTermOutAmtList", { documentDate: formValue["DocumentDate"], id: copied["SupplierID"], isAR: false }).then((res) => {
							const { data } = res;

							const getParentDate = new Date(data.dateTime);
							const dateToCompare = new Date(formValue["DocumentDate"]);
							const getExpiredDate = new Date(new Date(getParentDate.getFullYear(), getParentDate.getMonth(), getParentDate.getDate() + termDays));
							const daysDifference = utils.calculateDaysDifferenceExcluding31st(getExpiredDate, dateToCompare);

							setOverdueDays(daysDifference === -1 ? 0 : daysDifference);
							setTotalOutAmt(data.outAmt);
							setLockForm(daysDifference > 0);
						});

						tempForm.current = copied;
						setFormValue(tempForm.current);
					});
				} else if (dataField === "DocumentDate" && formRead.current) {
					copied[dataField] = e.value;
					tempForm.current = copied;

					setFormValue(tempForm.current);

					baseapi.httpget("/api/Utilities/GetCreditTermOutAmtList", { documentDate: e.value, id: copied["SupplierID"], isAR: false }).then((res) => {
						const { data } = res;

						const getParentDate = new Date(data.dateTime);
						const dateToCompare = new Date(e.value);
						const getExpiredDate = new Date(new Date(getParentDate.getFullYear(), getParentDate.getMonth(), getParentDate.getDate() + creditTerm));
						const daysDifference = utils.calculateDaysDifferenceExcluding31st(getExpiredDate, dateToCompare);

						setOverdueDays(daysDifference === -1 ? 0 : daysDifference);
						setTotalOutAmt(data.outAmt);
						setLockForm(daysDifference > 0);
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	const onRowUpdating = (e) => {
		const oldValue = { ...e.oldData };
		const newValue = e.newData;
		const currentRow = Object.assign(oldValue, newValue);
		const parentValue = { ...formValue };
		e.newData = utils.roundUpObjectProperties(moduleCalculation(currentRow, parentValue));
	};

	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		const ParentValue = { ...tempForm.current };
		const calculationResult = ParentValueCalculation(childrenDetails, ParentValue);
		tempForm.current = calculationResult.parent;
		setFormValue(tempForm.current);
		utils.childrenGridSetSource(childrenGridRef, calculationResult.children);
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const selectedItem = e.selectedItem;
		const dataSource = e.dataSource;
		currentRecords[rowIndex][columnName] = e.value;

		if (columnName === "StockID") {
			currentRecords[rowIndex]["StockCode"] = selectedItem?.code;
			currentRecords[rowIndex]["Description"] = selectedItem?.name;
			currentRecords[rowIndex]["Description2"] = selectedItem?.description2;
			currentRecords[rowIndex]["MoreDescription"] = selectedItem?.moreDescription;
			currentRecords[rowIndex]["UnitPrice"] = selectedItem?.price1;
			currentRecords[rowIndex]["UOMID"] = selectedItem?.uomID;
			currentRecords[rowIndex]["UOMCode"] = selectedItem?.uomCode;
			currentRecords[rowIndex]["ChartAccountID"] = selectedItem?.purchaseChartAccountID;
			currentRecords[rowIndex]["ChartAccountCode"] = selectedItem?.purchaseChartAccountCode;
			
		} else if (columnName === "TaxCodeID") {
			currentRecords[rowIndex]["TaxRate"] = selectedItem?.rate;
			currentRecords[rowIndex]["TaxCode"] = selectedItem?.code;
		} else if (columnName === "ProjectID") {
			currentRecords[rowIndex]["ProjectCode"] = selectedItem?.code;
		} else if (columnName === "ChartAccountID") {
			currentRecords[rowIndex]["ChartAccountCode"] = selectedItem?.code;
		}

		e["key"] = key;
		e["data"] = currentRecords[rowIndex];
		onRowUpdated(e, currentRecords);
	};

	const onInitNew = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			Quantity: 0,
			BankInDate: new Date(),
			NettAmount: 0,
			NettLocalAmount: 0,
			BankCharges: 0,
			ProjectID: utils.popupFormGetDefaultValues(formRef)["ProjectID"],
			ProjectCode: utils.popupFormGetDefaultValues(formRef)["ProjectCode"],
			ChartAccountID : utils.popupFormGetDefaultValues(formRef)["ChartAccountID"],
			ChartAccountCode: utils.popupFormGetDefaultValues(formRef)["ChartAccountCode"],
			DetailLN: 0,
			Point: 0
		};
	};

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	return (
		<div>
			<PopupForm
				ref={formRef}
				childrenGridRef={childrenGridRef}
				onClosing={clearForm}
				fullScreen={false}
				width={"95%"}
				height={"90%"}
				disableButtons={lockedForm === true}
				headerClassName={"purchase-module-form-title-bg popup-form-title"}
				title={props.title}
				apiURL={props.apiURL}
				onShown={onInitialized}
				onSuccessfulSubmit={({ stay }) => {
					if (stay) onInitialized();
				}}
				requiredColumns={GridRequiredColumns}
				filterRules={{
					Children:[
						{name: "StockID", rules : [{name : "Required"}]},
						{name: "Description", rules : [{name : "Required"}]},
						{name: "Description2", rules : [{name : "Required"}]},
						{name: "Quantity", rules : [{name : "Min", value: 1}]},
					]
				}}
				copyFields={{
					Parent: formValue,
					Children : utils.childrenGridGetSource(childrenGridRef)
				}}
				onPasteForm={(e) => {
					tempForm.current = e.Parent;
					setFormValue(e.Parent);
					handleExistingForm(e.Parent);
				}}
				copyStorage={"PurchaseDebitNoteCopiedData"}
				ReportDocumentNo={reportDocumentNo}
				defaultSelectedReport={"XJd7NZrZnfM%3d"}
				reportURL={purchaseDebitNoteReportNames.url}
				formData={formValue}
				isEditing={isEditing}
				onValidating={setStartValidation}
				startValidation={startValidation}
				onSaving={handleSubmit}
				lockFormOnChanged={setLockedForm}
				lookupValidateRef={[customerLookupRef, forexLookupRef, projectLookupRef, areaLookupRef, salesmanLookupRef]}
				cancelDocumentCustomization={
					<span className='popup-form-header-cancel'>
						<CheckBox
							value={formValue["Cancelled"]}
							elementAttr={{ dataField: "Cancelled" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"12px"}
							height={"12px"}
							iconSize={"12px"}
							readOnly={lockedForm === true}
							className='popup-header-cancel-input'
						/>

						<span className='popup-header-cancel-text'>Cancelled</span>
					</span>
				}>
				<div className='popup-form-main-content'>
					<div className='purchase-module-parent-section custom-form-grid-section1'>
						<div className=''>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Supplier Code: </div>

								<div className='popup-group-form-input'>
									<CustomizedLookup
										ref={customerLookupRef}
										className={"ar-listing-datagrid"}
										displayExpr={"code"}
										valueExpr={"id"}
										value={formValue["SupplierID"]}
										onSelectionChanged={(e) => {
											valueOnChange(e, "SupplierID");
										}}
										startValidation={startValidation !== 0}
										required={true}
										requiredMessage={"Supplier Code is required!"}
										dataSourceURL={"api/Utilities/GetSuppliers"}
										readOnly={lockedForm === true}
										displayText={formValue["SupplierCode"]}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Supplier Name"}></Column>
									</CustomizedLookup>
								</div>
							</div>

							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Supplier Name: </div>

								<div className='popup-group-form-input'>
									<TextBox
										value={formValue["SupplierName"]}
										elementAttr={{ dataField: "SupplierName" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										readOnly={lockedForm === true}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Supplier Name is required!"} />}</Validator>
									</TextBox>
								</div>
							</div>

							<div className='popup-group-form-item group-form-item-align-top'>
								<div className='popup-group-form-label'>Address: </div>

								<div className='popup-group-form-input'>
									<TextArea
										value={formValue["Address"]}
										elementAttr={{ dataField: "Address" }}
										onValueChanged={(e) => {
											valueOnChange(e);
										}}
										alignment='left'
										width={"auto"}
										height={100}
										readOnly={lockedForm === true}>
										<Validator>{startValidation !== 0 && <ValidatorRequired message={"Delivery Address is required!"} />}</Validator>
									</TextArea>
								</div>
							</div>
						</div>

						<div>
							<div className='popup-form-item-container2'>
								<div className='popup-group-form-item'>
									<div className='popup-group-form-label next-doc-label'>P/O No: </div>

									<div className='popup-group-form-input'>
										<div className='next-doc-display'>Next Doc : {formValue["NextDocNo"]}</div>
										<AsyncTextBox
											value={formValue["DocumentNo"]}
											elementAttr={{ dataField: "DocumentNo" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											placeholder={"<NEW>"}
											alignment='left'
											width={"auto"}
											disabled={formID.current !== "new" || lockedForm === true}
											asyncURL={"api/ARDebitNote/CheckDuplicateDocNo"}
											asyncMessage={"This D/N No is already taken!"}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Date: </div>

									<div className='popup-group-form-input'>
										<AsyncDateBox
											value={formValue["DocumentDate"]}
											elementAttr={{ dataField: "DocumentDate" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											displayFormat='dd/MM/yyyy'
											dateSerializationFormat='yyyy-MM-dd'
											useMaskBehavior={true}
											readOnly={lockedForm === true}
											asyncURL={"api/Utilities/CheckAfterFiscalYear"}
											asyncMessage={"Date cannot set before current fiscal year"}
											asyncProperty={"inputDate"}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Salesman: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={salesmanLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["SalesmanID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "SalesmanID");
											}}
											dataSourceURL={"api/Utilities/GetSalesmans"}
											displayText={formValue["SalesmanCode"]}
											readOnly={lockedForm === true}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Salesman is required!"}>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Ref: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["Remarks"]}
											elementAttr={{ dataField: "Remarks" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={lockedForm === true}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Project: </div>

									<div className='popup-group-form-input' id='customized-lookup1'>
										<CustomizedLookup
											ref={projectLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ProjectID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ProjectID");
											}}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Project is required!"}
											dataSourceURL={"api/Utilities/GetProjects"}
											readOnly={lockedForm === true}
											displayText={formValue["ProjectCode"]}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption={"Project Name"}></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Area: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={areaLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["AreaID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "AreaID");
											}}
											displayText={formValue["AreaCode"]}
											dataSourceURL={"api/Utilities/GetAreas"}
											readOnly={lockedForm === true}
											startValidation={startValidation !== 0}
											required={true}
											requiredMessage={"Area is required!"}
										>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Terms: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["CreditTermsID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "CreditTermsID");
											}}
											displayText={formValue["CreditTermsCode"]}
											dataSourceURL={"api/Utilities/GetCreditTerms"}
											readOnly={lockedForm === true}
										>
											<Column dataField='code'></Column>
											<Column dataField='name' caption='Days'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Issue By: </div>

									<div className='popup-group-form-input'>
										<TextBox
											value={formValue["IssueBy"]}
											elementAttr={{ dataField: "IssueBy" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											readOnly={true}
										/>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Forex: </div>

									<div className='popup-group-form-input'>
										<CustomizedLookup
											ref={forexLookupRef}
											className={"ar-listing-datagrid"}
											displayExpr={"code"}
											valueExpr={"id"}
											value={formValue["ForexID"]}
											onSelectionChanged={(e) => {
												valueOnChange(e, "ForexID");
											}}
											required={true}
											requiredMessage={"Forex is required!"}
											readOnly={true}
											dataSourceURL={"api/Utilities/GetForexs"}
											preread={true}
										>
											<Column dataField='code'></Column>
											<Column dataField='name'></Column>
										</CustomizedLookup>
									</div>
								</div>

								<div className='popup-group-form-item'>
									<div className='popup-group-form-label'>Local Rate: </div>

									<div className='popup-group-form-input'>
										<NumberBox
											value={formValue["LocalRate"]}
											elementAttr={{ dataField: "LocalRate" }}
											onValueChanged={(e) => {
												valueOnChange(e);
											}}
											alignment='left'
											width={"auto"}
											format={utils.LocalRateFormat}
											readOnly={lockedForm === true || formValue["ForexID"] === utils.getDefaultForexRM()}>
											<Validator>{startValidation !== 0 && <ValidatorRequired message={"Forex Rate is required!"} />}</Validator>
										</NumberBox>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='children-datagrid-container purchase-module-children-datagrid' style={{ padding: "0 0" }}>
						<ChildrenDatagrid
							ref={childrenGridRef}
							name={"Children"}
							defaultColumnValues={onInitNew}
							lookupFields={["StockID", "ProjectID", "ChartAccountID", "TaxCodeID", "ChartAccountID"]}
							keyExpr='ID'
							showBorders={true}
							loadPanel={false}
							allowColumnReordering={true}
							allowColumnResizing={true}
							onRowUpdating={onRowUpdating}
							onRowUpdated={onRowUpdated}
							onRowRemoved={onRowUpdated}
							disabled={lockedForm === true}
							gridLookupOpen={setGridLookupOpen}
							storageName={"SalesCashSalesChildrenGrid"}
							onParentValueChanged={valueOnChange}
							defaultDescription={formValue["Description"]}>
							<Column dataField='Line' dataType='number' defaultColumnIndex={0} />

							<Column
								visible={true}
								dataField='StockID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.StockCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}
										dataSourceURL={"/api/Utilities/GetStock"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column dataField='Description' />

							<Column dataField='Description2' />

							<Column visible={true} dataField='MoreDescription' dataType='string' isHtmlEditor={true} />

							<Column
								visible={true}
								dataField='ProjectID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.ProjectCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
										dataSourceURL={"/api/Utilities/GetProjects"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}
								editorOptions={{ readOnly: true }}></Column>

							<Column visible={true} dataField='Quantity' dataType='number' />

							<Column
								visible={true}
								dataField='UOMID'
								cellRender={(e) => {
									const data = e.data;
									return data.UOMCode;
								}}
								editorOptions={{ readOnly: true }}
							/>

							<Column visible={true} dataField='ItemDiscount' dataType='number' />

							<Column visible={true} dataField='UnitPrice' dataType='number' format='#,##0.00' />

							<Column visible={true} dataField='NettPrice' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

							<Column
								visible={true}
								dataField='TaxCodeID'
								width={150}
								cellRender={(e) => {
									const data = e.data;
									return data.TaxCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
										dataSourceURL={"api/Utilities/GetGSTRate"}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)}></Column>

							<Column visible={true} dataField='TaxRate' dataType='number' editorOptions={{ readOnly: true }} />

							<Column visible={true} dataField='TaxInclusive' dataType='boolean' />

							<Column visible={true} dataField='ForexTaxable' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

							<Column visible={true} dataField='ForexTaxAmount' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={true} dataField='ForexTaxablePlusTax' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={true} dataField='ImportDocumentNo' caption={"From Doc No"} />

							<Column
								visible={true}
								dataField='ChartAccountID'
								dataType='string'
								cellRender={(e) => {
									const data = e.data;
									return data.ChartAccountCode;
								}}
								editCellComponent={(component) => (
									<CustomizedGridLookup
										{...component}
										opened={gridLookupOpen}
										className={"ar-listing-datagrid"}
										onSelectionChanged={(e) => gridOnSelectionChanged(e, "ChartAccountID")}
										dataSourceURL={"api/Utilities/GetGLChartAccounts"}
										mergeParams={{ chartAccountType: "Sales" }}
									>
										<Column dataField='code'></Column>
										<Column dataField='name' caption={"Description"} width={"300px"}></Column>
									</CustomizedGridLookup>
								)} 
							/>

							<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

							<Column visible={false} dataField='Location' />

							<Column visible={false} dataField='PartNo' />

							<Column visible={false} dataField='BrandCode' />

							<Column visible={false} dataField='LinePrint' />

							<Column visible={false} dataField='PrintPrice' dataType='boolean' />

							<Column visible={false} dataField='Confirmation' dataType='boolean' />

							<Column visible={false} dataField='Show' dataType='boolean' />

							<Column visible={false} dataField='SalesType' />

							<Column visible={false} dataField='PriceCheckID' />

							<Column visible={false} dataField='SalesCode' />

							<Column visible={false} dataField='OrderType' />

							<Column visible={false} dataField='BOM' dataType='boolean' />

							<Column visible={false} dataField='ProductionNo' />

							<Column visible={false} dataField='Details' dataType='boolean' />

							<Column visible={false} dataField='UniCost' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ModelCode' />

							<Column visible={false} dataField='BinShelfNo' />

							<Column visible={false} dataField='SerialNo' />

							<Column visible={false} dataField='Project' />

							<Column visible={false} dataField='GroupNo' />

							<Column visible={false} dataField='GroupUp' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='GroupAmt' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='BaseRate' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='PriceDisplay' dataType='boolean' />

							<Column visible={false} dataField='ForexTaxAmountOrignal' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexTaxAdjust' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='ForexAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAmountOrignal' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxAdjust' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

							<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='NettAmount' caption='Nett Amount' dataType='number' format='#,##0.00' />

							<Column visible={false} dataField='LocalAmount' dataType='number' format='#,##0.00' />

							<Summary recalculateWhileEditing={true}>
								<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettLocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettPrice' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='UniCost' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='ItemDiscountAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='GroupUp' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='GroupAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='BaseRate' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

								<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							</Summary>
						</ChildrenDatagrid>
					</div>

					<div className='purchase-module-total-section custom-form-grid-section2'>
						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Local Total: </div>

							<div className='popup-group-form-input' style={{ width: "150px" }}>
								<NumberBox
									value={formValue["MainLocalAmount"]}
									elementAttr={{ dataField: "MainLocalAmount" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									readOnly={true}
									format='#,##0.00'>
									{/* <Validator 
						>
							<ValidatorRequired message={"Local Amount is required!"} />
						</Validator> */}
								</NumberBox>
							</div>
						</div>

						<div className='popup-group-form-item'>
							<div className='popup-group-form-label'>Total: </div>

							<div className='popup-group-form-input' style={{ width: "150px" }}>
								<NumberBox
									value={formValue["MainForexAmount"]}
									elementAttr={{ dataField: "MainForexAmount" }}
									onValueChanged={(e) => {
										valueOnChange(e);
									}}
									alignment='left'
									width={"auto"}
									readOnly={true}
									format='#,##0.00'>
									{/* <Validator 
						>
							<ValidatorRequired message={"Outstanding is required!"} />
						</Validator> */}
								</NumberBox>
							</div>
						</div>
					</div>
				</div>
			</PopupForm>

			{creditTermErrorPopup && (
				<CreditTermForm
					errorVisible={creditTermErrorPopup}
					isCustomer={false}
					userType={userType}
					totalOutAmt={totalOutAmt}
					onValueChanged={(e) => {
						setCreditTermErrorPopUp(e.value);
					}}
					closedOnly={(e) => {
						if (e.value) {
							utils.displayPopupMessage(popupMessageRef, {
								visible: true,
								message: `This account had violated the credit term limit. You are not allowed to save`,
								type: "Danger"
							});
						}
					}}
					ReturnSaveResult={(e) => {
						handleSubmit(e.canSave);
					}}
					creditTerm={creditTerm}
					overdueDays={overdueDays}
				/>
			)}

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
