import TreeList, { Column, Lookup, Scrolling, Editing } from "devextreme-react/tree-list";
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import { useRef, useState, useEffect, useCallback } from "react";
import Container from "../../../components/container";
import { NumberBox, Button } from "devextreme-react";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomStore from "devextreme/data/custom_store";
import baseapi from "../../../api/baseapi";
import utils from "../../../utils/common";
import { loading, closeLoading } from "../../../utils/common";
import ErrorPopupForm from "../../../components/popup-form/ErrorPopupForm";
import  PopupForm  from "../../../components/popup-form/PopupFormV2";
import { TextBox } from "devextreme-react/text-box";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { Column as GridColumn, Summary, TotalItem } from "devextreme-react/data-grid";
import { saveToLS } from "../../../utils/localstorage";

export default function OpeningBalanceListing() {
	const treelistRef = useRef(null);
	const popupMessageRef = useRef(null);
	const formRef = useRef(null);
	const childrenGridRef = useRef(null);
	const fiscalYearForm = useRef({});
	const currentFocusCell = useRef({
		columnIndex: 0,
		rowIndex: 0,
		key : null,
	})
	const [listingDataSource, setListingDataSource] = useState(null);
	const [projectList, setProjectList] = useState(null);
	const [disableSave, setDisableSave] = useState(true);
	const [projectID, setProjectID] = useState("53OsK3yBsKs%3d");
	const [formValue, setFormValue] = useState({});
	const [enableUpdate, setEnableUpdate] = useState(false);
	const [cancelVisible, setCancelVisible] = useState(false);
	const [editVisible, setEditVisible] = useState(true);
	const [expandAll, setExpandAll] = useState(true);
	const [popupTitle, setPopupTitle] = useState(null);
	const [popupParent, setPopupParent] = useState({});
	const [currentFiscalYear, setCurrentFiscalYear] = useState(null);

	const handleEdit = () => {
		setEnableUpdate(true);
		setDisableSave(false);
		setCancelVisible(true);
		setEditVisible(false);
	};

	const handleSubmit = async () => {
		const obj = fiscalYearForm.current;
		var popupArray = [];
		for (let key in obj) {
			if (obj.hasOwnProperty(key)) { // Check to ensure the key is the object's own property
				popupArray = popupArray.concat(obj[key])
			}
		}

		const children = [...treelistRef.current.props.dataSource]
		.filter(c => !c.HasChildren && c.BeforeFiscalYearChildren === 0)
		.filter(c => (utils.isNumber(c.OpeningDebit) || utils.isNumber(c.OpeningCredit)))
		.map(item => ({
			...item,
			OpeningDebit: Number(item.OpeningDebit),
			OpeningCredit : Number(item.OpeningCredit),
		}));

		// for(var i = 0; i < children.length; i++){
		// 	const record = children[i];

		// 	//sum debtors and creditors back to its parent
		// 	if(record["BeforeFiscalYearChildren"] === 1){
		// 		const parentRecord = children.find(c => c.GLChartAccountID === record["ParentID"]);
		// 		const parentIndex = children.findIndex(c => c.GLChartAccountID === record["ParentID"]);

		// 		parentRecord["OpeningDebit"] += record["OpeningDebit"];
		// 		parentRecord["OpeningCredit"] += record["OpeningCredit"];
		// 		children[parentIndex] = parentRecord;
		// 	}
		// }

		const submit = {
			ProjectID: projectID,
			Children: children,
			PopupForm: popupArray
		};

		loading("Saving Opening Balance...");
		baseapi.httppost(utils.extendUrlVar("/api/GLOpeningBalance/save"), submit).then(async (res) => {
			// console.log(res);
			res = res.data;
			if (res.status) {
				utils.displayPopupMessage(popupMessageRef, {
					visible: true,
					message: res.message,
					type: "Success"
				});
				setExpandAll(false);
				setDisableSave(true);
				setEnableUpdate(false);
				setCancelVisible(false);
				setEditVisible(true);
				await refreshTreeList();
			} 
			else {
				utils.displayPopupMessage(popupMessageRef, {
					visible: true,
					message: res.message,
					type: "Warning"
				});
			}
			closeLoading();
		});
	};

	const handleCancel = async () => {
		setDisableSave(true);
		setEnableUpdate(false);
		setCancelVisible(false);
		setEditVisible(true);
		await refreshTreeList();
	};

	const refreshTreeList = async () => {
		setListingDataSource(
			await baseapi
				.httpget(`/api/GLOpeningBalance/list?projectID=${projectID}`)
				.then((response) => {
					const { data } = response;
					//console.log(data.items);
					if (data !== null) {
						const parsedData = data.items.map(item => ({
							...item,
							OpeningDebit: Number(item.OpeningDebit),
							OpeningCredit : Number(item.OpeningCredit),
						}));
						const filteredItems = parsedData
						.filter(c => 
							!c.HasChildren 
							&& !(c.BeforeFiscalYearChildren === 0 && (c.SpecialAccountType === "CC" || c.SpecialAccountType === "SC"))
						)
						.filter(c => (c.OpeningDebit > 0 || c.OpeningCredit > 0))
						// console.log("dta item", data.items, "filter", filteredItems)
						recalculateTotal(filteredItems);
						
						fiscalYearForm.current = data.beforeFiscalYear.reduce((acc, obj) => {
							const key = obj.GLChartAccountID;
							if (!acc[key]) {
							  acc[key] = [];
							}
							acc[key].push(obj);
							return acc;
						}, {});

						return data.items;
					} else {
						return [];
					}
				})
				.catch(() => {
					throw "Network error";
				})
		);
	};

	const getDebtorInfo = (id) =>{
		loading("Loading Debtor Info...");
		baseapi.httpget("/api/GLOpeningBalance/GetDebtorInfo", { GLChartAccountID: id }).then((response) => {
			const { data } = response;
			const formData = {};

			if(utils.isNullOrEmpty(data.status)){
				formData["AccountNo"] = data.AccountNo;
				formData["CurrencyCode"] = data.CurrencyCode;
				formData["CompanyName"] = data.CompanyName;
				setPopupParent(formData);
				setCurrentFiscalYear(data.FiscalYearDate);

				const existingRecord = fiscalYearForm.current[id];

				if(!utils.isNullOrEmpty(existingRecord)){
					utils.childrenGridSetSource(childrenGridRef, existingRecord);
				}
				else{
					utils.childrenGridSetSource(childrenGridRef, data.Children);
				}

			}

			closeLoading();
		});
	}

	const getCreditorInfo = (id) =>{
		loading("Loading Creditor Info...");
		baseapi.httpget("/api/GLOpeningBalance/GetCreditorInfo", { GLChartAccountID: id }).then((response) => {
			const { data } = response;
			const formData = {};

			if(utils.isNullOrEmpty(data.status)){
				formData["AccountNo"] = data.AccountNo;
				formData["CurrencyCode"] = data.CurrencyCode;
				formData["CompanyName"] = data.CompanyName;
				setPopupParent(formData);
				setCurrentFiscalYear(data.FiscalYearDate);

				const existingRecord = fiscalYearForm.current[id];

				if(!utils.isNullOrEmpty(existingRecord)){
					utils.childrenGridSetSource(childrenGridRef, existingRecord);
				}
				else{
					utils.childrenGridSetSource(childrenGridRef, data.Children);
				}
			}
			closeLoading();
		});
	}

	const recalculateTotal = (dataSource) => {
		if(Array.isArray(dataSource)){
			var totalDebit = 0
			var totalCredit = 0;
			const form = {};

			for(var i = 0; i < dataSource.length; i++){
				if(!utils.isNullOrEmpty(dataSource[i]["OpeningDebit"])){
					totalDebit += dataSource[i]["OpeningDebit"];
				}

				if(!utils.isNullOrEmpty(dataSource[i]["OpeningCredit"])){
					totalCredit += dataSource[i]["OpeningCredit"];
				}
			}

			form["DebitTotal"] = totalDebit;
			form["CreditTotal"] = totalCredit;
			form["OutOfBalance"] = totalDebit - totalCredit;
			setFormValue(form);
		}
	};

	const onEditorPreparing = useCallback((e) => {
		//console.log('onEditorPreparing')
		if (e.row.data.ChartAccountCode === "" || !e.row.data.CanEdit) {
			e.editorOptions.readOnly = true;
		}
	}, []);

	const onRowPrepared = useCallback((e) => {
		//console.log(e);
		//console.log('onRowPrepared'); return;
		if (e.rowType === "data") {
			const thirdColumnCell = e.rowElement.querySelector(".dx-row > td:nth-child(3)");
			const fourthColumnCell = e.rowElement.querySelector(".dx-row > td:nth-child(4)");
			if (!e.node.hasChildren && e.data.CanEdit) {
				thirdColumnCell && thirdColumnCell.classList.add("editableColumns");
				fourthColumnCell && fourthColumnCell.classList.add("editableColumns");
			} else {
				thirdColumnCell && (thirdColumnCell.disabled = true);
				fourthColumnCell && (fourthColumnCell.disabled = true);
			}
		}
	}, []);

	const onOptionChanged = useCallback((e) => {
		if ((e.name === "editing" && e.fullName === "editing.changes") || e.name === "dataSource") {

			const changes = e.value;
			setExpandAll(false);
			var newDetails = null;

			// update parent cell values
			newDetails = [...treelistRef.current.props.dataSource];
			//console.log(newDetails);
			//console.log(listingDataSource);
			const parentIndexMap = new Map(newDetails.map((detail, index) => [detail.GLChartAccountID, index]));
			const rootParentTotalDebitAmounts = {};
			const rootParentTotalCreditAmounts = {};
			const recursiveUpdateParent = (parentId, amount, updateColumn, totalAmounts) => {
				const existingIndex = parentIndexMap.get(parentId);
				// console.log("existing index", existingIndex)
				if (existingIndex !== undefined) {
					const parentDetail = newDetails[existingIndex];
					if (parentDetail) {
						if (!totalAmounts[parentDetail.RootParentID]) {
							totalAmounts[parentDetail.RootParentID] = { debit: 0, credit: 0 };
						}

						const updateDetail = (index, field) => {
							if (parentDetail[field] !== undefined) {
								if (field === "OpeningDebit") {
									newDetails[index] = {
										...newDetails[index],
										["OpeningDebit"]: parseFloat(totalAmounts[parentDetail.RootParentID].debit)
									};
								}
								if (field === "OpeningCredit") {
									newDetails[index] = {
										...newDetails[index],
										["OpeningCredit"]: parseFloat(totalAmounts[parentDetail.RootParentID].credit)
									};
								}
							}
						};

						if (updateColumn === "OpeningDebit") {
							updateDetail(existingIndex, "OpeningDebit");
							totalAmounts[parentDetail.RootParentID].debit += parseFloat(amount);
						}

						if (updateColumn === "OpeningCredit") {
							updateDetail(existingIndex, "OpeningCredit");
							totalAmounts[parentDetail.RootParentID].credit += parseFloat(amount);
						}

						recursiveUpdateParent(parentDetail.ParentID, amount, updateColumn, totalAmounts);
					}
				}
			};

			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				const existingIndex2 = parentIndexMap.get(change.key);
				if (existingIndex2 !== undefined) {
					const matchingDetail = newDetails[existingIndex2];
					if (matchingDetail) {
						const updateDetail = (index, field) => {
							if (change.data[field] !== undefined) {
								if (field == "OpeningCredit") {
									if (newDetails[index]["OpeningDebit"]) {
										newDetails[index]["OpeningDebit"] = "";
										delete change.data["OpeningDebit"];
									}
								} else {
									if (newDetails[index]["OpeningCredit"]) {
										newDetails[index]["OpeningCredit"] = "";
										delete change.data["OpeningCredit"];
									}
								}
								newDetails[index][field] = parseFloat(change.data[field] ?? 0);
							}
						};

						updateDetail(existingIndex2, "OpeningDebit");
						updateDetail(existingIndex2, "OpeningCredit");
					}
				}
			}
			for (let i = 0; i < changes.length; i++) {
				const change = changes[i];
				const existingIndex2 = parentIndexMap.get(change.key);
				if (existingIndex2 !== undefined) {
					const matchingDetail = newDetails[existingIndex2];
					if (matchingDetail) {
						if (change.data["OpeningDebit"] !== undefined) {
							recursiveUpdateParent(matchingDetail.ParentID, parseFloat(change.data["OpeningDebit"] ?? 0), "OpeningDebit", rootParentTotalDebitAmounts);
						}
						if (change.data["OpeningCredit"] !== undefined) {
							recursiveUpdateParent(matchingDetail.ParentID, parseFloat(change.data["OpeningCredit"] ?? 0), "OpeningCredit", rootParentTotalCreditAmounts);
						}
					}
				}
			}
			// console.log("changes changed", changes);
			// console.log(newDetails);
			// newDetails = e.name === "dataSource" ? e.value : newDetails;
			recalculateTotal(
				newDetails
				.filter(c => 
					!c.HasChildren 
					&& !(c.BeforeFiscalYearChildren === 0 && (c.SpecialAccountType === "CC" || c.SpecialAccountType === "SC"))
				)
				.filter(c => (utils.isNumber(c.OpeningDebit) || utils.isNumber(c.OpeningCredit)))
			);

			setListingDataSource(newDetails);
		}
	}, []);

	const onNodesInitialized = useCallback((e) => {
		// console.log("onNodesInitialized", e);

		function sumDebitCredit(node) {
			let totalDebit = node.children.length ? 0 : parseFloat(node.data.OpeningDebit) || 0;
			let totalCredit = node.children.length ? 0 : parseFloat(node.data.OpeningCredit) || 0;

			node.children.forEach((child) => {
				const childSums = sumDebitCredit(child);
				totalDebit += childSums.totalDebit;
				totalCredit += childSums.totalCredit;
			});

			node.data.OpeningDebit = totalDebit || "";
			node.data.OpeningCredit = totalCredit || "";

			return { totalDebit, totalCredit };
		}
		e.root.children.forEach((child) => {
			sumDebitCredit(child);
		});
	}, []);

	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	const onCellClick = (e) => {
		const rowType = e.rowType;

		if(rowType === "data"){
			const row = e.row;
			const node = row.node;
			const data = row.data;
			const columnIndex = e.columnIndex;
			const rowIndex = e.rowIndex;
	
			currentFocusCell.current["columnIndex"] = columnIndex;
			currentFocusCell.current["rowIndex"] = rowIndex;
			currentFocusCell.current["key"] = row["key"];
			// console.log("data source", row)
	
			if(enableUpdate){
				if(!node["hasChildren"] && (columnIndex === 2 || columnIndex === 3)){
					if(data["SpecialAccountType"] === "CC" || data["SpecialAccountType"] === "SC"){
						if(data["SpecialAccountType"] === "CC"){
							setPopupTitle("Debtor Opening Balance");
							getDebtorInfo(data["GLChartAccountID"]);
							utils.popupFormOpen(formRef);
				
						}
						else if(data["SpecialAccountType"] === "SC"){
							setPopupTitle("Creditor Opening Balance");
							getCreditorInfo(data["GLChartAccountID"]);
							utils.popupFormOpen(formRef);		
						}
					}
				}
			}
		}
	};

	const onInitNew = () => {
		return {
			DocumentDate : currentFiscalYear,
			DueDate : currentFiscalYear,
			LocalRate: 1,
			OpeningDebit: 0,
			OpeningCredit: 0,
			ForexOpeningDebit: 0,
			ForexOpeningCredit: 0
		};
	};

	const gridOnRowUpdating = (e) => {
		const oldValue = e.oldData;
		const currentRow = Object.assign(oldValue, e.newData);
		var currentField = Object.keys(e.newData)[0];
		
		if(currentField === "LocalRate"){
			currentRow["OpeningDebit"] = utils.multiply(currentRow["ForexOpeningDebit"], currentRow["LocalRate"]);
			currentRow["OpeningCredit"] = utils.multiply(currentRow["ForexOpeningCredit"], currentRow["LocalRate"]);
		}
		else if (currentField === "ForexOpeningDebit") {
			currentRow["ForexOpeningCredit"] = 0;
			currentRow["OpeningCredit"] = 0;
			currentRow["OpeningDebit"] = utils.multiply(currentRow["ForexOpeningDebit"], currentRow["LocalRate"]);
		} else if (currentField === "ForexOpeningCredit") {
			currentRow["ForexOpeningDebit"] = 0;
			currentRow["OpeningDebit"] = 0;
			currentRow["OpeningCredit"] = utils.multiply(currentRow["ForexOpeningCredit"], currentRow["LocalRate"]);
		}

		e.newData = currentRow;
	};

	const popupSave = () => {
		const treelistDataSource = [...treelistRef.current.props.dataSource];
		const children = utils.childrenGridGetSource(childrenGridRef)
		.filter(c => (!utils.isNullOrEmpty(c.OpeningDebit) || c.OpeningDebit !== 0) && (!utils.isNullOrEmpty(c.OpeningCredit) || c.OpeningCredit !== 0));
		//Check if doc no is empty
		if(children.filter(c => utils.isNullOrEmpty(c.DocumentNo)).length > 0){
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: "Document No is required!",
				type: "Warning"
			});
		}
		else{
			const key = currentFocusCell.current["key"];
			const foundRecord = treelistDataSource.find(c => c.GLChartAccountID === key);
			const foundIndex = treelistDataSource.findIndex(c => c.GLChartAccountID === key);
			var totalDebit = 0;
			var totalCredit = 0;
			
			for(var i = 0; i < children.length; i++){
				children[i]["ProjectID"] = projectID;
				children[i]["GLChartAccountID"] = key;
				const row = children[i];
				totalDebit += row["OpeningDebit"];
				totalCredit += row["OpeningCredit"];
			}
	
			fiscalYearForm.current[key] = children;
			foundRecord["OpeningDebit"] = totalDebit;
			foundRecord["OpeningCredit"] = totalCredit;
			treelistDataSource[foundIndex] = foundRecord;
			
			if(treelistRef.current){
				treelistRef.current.instance.option("dataSource", treelistDataSource); //Update data source
				recalculateTotal(
					treelistDataSource
					.filter(c => 
						!c.HasChildren 
						&& !(c.BeforeFiscalYearChildren === 0 && (c.SpecialAccountType === "CC" || c.SpecialAccountType === "SC"))
					)
					.filter(c => (utils.isNumber(c.OpeningDebit) || utils.isNumber(c.OpeningCredit)))
				)
			}
			utils.popupFormSuccessfulSubmit(formRef, {status: true});
		}
		
	}

	const clearPopup = () => {
		setPopupParent({});
	}

	useEffect(() => {
		// if (projectID == null) return;
		// console.log("lockDataSource", lockDataSource);
		//console.log("projectID", projectID);
		async function fetchData() {
			await refreshTreeList();
		}
		fetchData();
	}, [projectID]);

	return (
		<Container>
			<div className='opening-balance-container'>
				<div className='listing-page-title-container'>
					<span className='datagrid-customized-title'>
						<PathIndicator />
					</span>
				</div>

				<div className='listing-datebox-container' style={{ display: "grid", gridTemplateColumns: "15% 85%", marginBottom: "10px" }}>
					<CustomizedLookup
						disabled={enableUpdate}
						value={projectID}
						displayText={"P0"}
						className={"ar-listing-datagrid"}
						dataSourceURL={"api/Utilities/GetProjects"}
						dropdownClassName={"listing-page-searching-lookup"}
						preread={true}
						onDataSourceChanged={setProjectList}
						onSelectionChanged={(e) => {
							setProjectID(e.value);
							// console.log(e.value);
						}}
						dataSource={projectList}
						displayExpr={"code"}
						valueExpr={"id"}
						height={"36px"}>
						<Column dataField='code'></Column>
						<Column dataField='name' caption={"Model"}></Column>
					</CustomizedLookup>

					<div style={{ textAlign: "right" }}>
						<Button visible={cancelVisible} text='Cancel' type='danger' style={{ marginRight: "10px" }} onClick={() => handleCancel()} />
						<Button visible={editVisible} text='Edit' type='success' style={{ marginRight: "10px", backgroundColor: "#F68627" }} onClick={() => handleEdit()} />
						<Button text='Save' type='success' disabled={disableSave} onClick={() => handleSubmit()} />
					</div>
				</div>
				<TreeList
					className='OpeningBalance'
					ref={treelistRef}
					//visible={projectID !== null}
					dataSource={listingDataSource}
					allowColumnReordering={false}
					allowColumnResizing={false}
					columnAutoWidth={false}
					sorting={{ mode: "none" }}
					columnResizingMode='widget'
					showRowLines={true}
					showBorders={false}
					autoExpandAll={expandAll}
					//repaintChangesOnly={true}
					height={"67vh"}
					width={"100%"}
					keyExpr='GLChartAccountID'
					parentIdExpr='ParentID'
					rootValue={"t2B0SsAZelE%3d"}
					loadPanel={{ enabled: true }}
					onEditorPreparing={onEditorPreparing}
					onRowPrepared={onRowPrepared}
					onOptionChanged={onOptionChanged}
					onNodesInitialized={onNodesInitialized}
					onCellClick={onCellClick}
					hoverStateEnabled={true}
				>
					<Column dataField={"ChartAccountName"} caption={"Account"} editorOptions={{ readOnly: true }}></Column>
					<Column dataField={"ChartAccountCode"} editorOptions={{ readOnly: true }}></Column>
					<Column dataField={"OpeningDebit"} dataType={"number"} format={{ type: "fixedPoint", precision: 2 }}></Column>
					<Column dataField={"OpeningCredit"} dataType={"number"} format={{ type: "fixedPoint", precision: 2 }}></Column>
					<Editing allowUpdating={enableUpdate} mode='batch' />
				</TreeList>

				<div className='opening-balance-sticky'>
					<div className='popup-form-item-container3 item-align-bottom' style={{ padding: "0px 20px", display: "grid", gridTemplateColumns: "38% 12% 26% 24%" }}>
						<div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Out Of Balance:</div>

								<div className='popup-group-form-input' style={{ width: "200px" }}>
									<NumberBox className='OutOfBalance' value={formValue["OutOfBalance"]} readOnly={true} format='#,##0.00' />
								</div>
								{/* <div className='popup-group-form-input'>
									<p hidden={formValue["OutOfBalance"] === 0} className='errorMessage'>
										Amount not balance by {formValue["OutOfBalance"]}
									</p>
								</div> */}
							</div>
						</div>
						<div>
							<div className='popup-group-form-item'>
								<div className='popup-group-form-label'>Total: </div>
							</div>
						</div>
						<div>
							<div className='popup-group-form-item' style={{ display: "grid", gridTemplateColumns: "29% 71%", margin: "2.5px 10px", alignItems: "flex-end" }}>
								<div className='popup-group-form-label'>DR:</div>

								<div style={{ maxWidth: "270px" }}>
									<NumberBox className='OutOfBalance' value={formValue["DebitTotal"]} readOnly={true} format='#,##0.00' />
								</div>
							</div>
						</div>
						<div>
							<div className='popup-group-form-item' style={{ display: "grid", gridTemplateColumns: "29% 71%", margin: "2.5px 10px", alignItems: "flex-end" }}>
								<div className='popup-group-form-label'>CR:</div>

								<div style={{ maxWidth: "270px" }}>
									<NumberBox className='OutOfBalance' value={formValue["CreditTotal"]} readOnly={true} format='#,##0.00' />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<ErrorPopupForm ref={popupMessageRef} />

			<PopupForm
				ref={formRef}
				width={"60%"}
				height={"60%"}
				shading={true}
				showTitle={false}
				fullScreen={false}
				title={popupTitle}
				actionDisabled={true}
				childrenGridRef={childrenGridRef}
				onSaving={popupSave}
				onClosing={clearPopup}
			>
				<div className="popup-form-item-container4">
					<div className='popup-group-form-item-block'>
						<div className='popup-group-form-label'>Account No: </div>

						<div>
							<TextBox
								value={popupParent["AccountNo"]}								
								alignment='left'
								width={"auto"}
								readOnly={true}>
							</TextBox>
						</div>
					</div>

					<div className='popup-group-form-item-block'>
						<div className='popup-group-form-label'>Currency Code: </div>

						<div>
							<TextBox
								value={popupParent["CurrencyCode"]}								
								alignment='left'
								width={"auto"}
								readOnly={true}>
							</TextBox>
						</div>
					</div>
				</div>

				<div className="popup-form-item-container1">
					<div className='popup-group-form-item-block'>
						<div className='popup-group-form-label'>Company Name: </div>

						<div>
							<TextBox
								value={popupParent["CompanyName"]}								
								alignment='left'
								width={"auto"}
								readOnly={true}>
							</TextBox>
						</div>
					</div>
				</div>

				<div className='children-datagrid-container' style={{ padding: "0 0" }}>
					<ChildrenDatagrid
						ref={childrenGridRef}
						enabledDescription={false}
						keyExpr='ID'
						showBorders={true}
						loadPanel={false}
						allowColumnReordering={true}
						allowColumnResizing={true}
						storageName={"GLOpeningBalanceChildrenGrid"}
						defaultColumnValues={onInitNew}
						onRowUpdating={gridOnRowUpdating}
						disabledCommonColumns={true}
						autoAddRow={false}
						scrolling={{mode: "virtual"}}
						height={"245px"}
					>
						<GridColumn 
							dataField={"DocumentDate"} 
							caption={"Date"} dataType={"date"} 
							editorOptions={{ 
								min : "2000-01-01", 
								displayFormat : 'dd/MM/yyyy',
								dateSerializationFormat : 'yyyy-MM-dd',
								useMaskBehavior : true
							}}
							format={'dd/MM/yyyy'}
						/>
						<GridColumn 
							dataField={"DueDate"}
							visible={false}
							dataType={"date"} 
							editorOptions={{ 
								min : "2000-01-01", 
								displayFormat : 'dd/MM/yyyy',
								dateSerializationFormat : 'yyyy-MM-dd',
								useMaskBehavior : true
							}}
							format={'dd/MM/yyyy'}
						/>
						<GridColumn dataField={"DocumentNo"} caption={"Doc No"}></GridColumn>
						<GridColumn dataField={"Description"}></GridColumn>
						<GridColumn 
							dataField={"LocalRate"} 
							format='#,##0.000000'
							dataType={"number"} 
							editorOptions={{ readOnly : popupParent["CurrencyCode"] === "RM"}} 
						/>
						<GridColumn 
							dataField={"ForexOpeningDebit"} 
							caption={"DR"} 
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true
							}}
						/>
						<GridColumn 
							dataField={"ForexOpeningCredit"} 
							caption={"CR"}
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true
							}}
						/>
						<GridColumn 
							dataField={"OpeningDebit"} 
							caption={"Local DR"} 
							allowEditing={false} 
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true
							}}
						/>

						<GridColumn 
							dataField={"OpeningCredit"} 
							caption={"Local CR"} 
							allowEditing={false} 
							format={'#,##0.00'}
							dataType={"number"}
							editorOptions={{
								min: 0,
								format: '#,##0.00',
								useMaskBehavior: true
							}}
						/>

						<Summary recalculateWhileEditing={true}>
							<TotalItem column='ForexOpeningDebit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							<TotalItem column='ForexOpeningCredit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							<TotalItem column='OpeningDebit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
							<TotalItem column='OpeningCredit' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
						</Summary>
					</ChildrenDatagrid>
				</div>
			</PopupForm>
		</Container>
	);
}
