import React, { useState, useEffect, useRef } from 'react';
import { Column, Lookup} from 'devextreme-react/data-grid';
import Container from '../../../components/container';
import WorkshopInvoiceForm from './form';
import Listing from '../../../components/listing/newListing';
import { chartAccountPayment, workshopRegistration } from '../../../utils/lookupstore';
import DropDownButton from 'devextreme-react/drop-down-button';

export default function WorkshopInvoiceListing() {

    const apiURL = `/api/WKInvoice`;
    const [currentEditID, setCurrentEditID] = useState(null);
    const [presetJobID, setPresetJobID] = useState(null);
    const [inputType, setInputType] = useState(null);
    const [lockForm, setLockForm] = useState(false);

    // Look up list
    const [registrationList, setRegistrationList] = useState([]);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [dropDownRead, setDropDownRead] = useState(false);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const addToInvoiceID = urlParams.get("addToInvoice");
    const InvoiceID = urlParams.get("ID");
    const readInvoiceIDBefore = useRef(null);

    useEffect(() => {
        // Promise.allSettled([forexOptions.store.load(), workshopRegistration.store.load(), workshopJobSheet.store.load(),
        //     chartAccountPayment.store.load()]).then((lists) => {
        //     setDropDownList({
        //         forexList: lists[0].value.data, registrationList: lists[1].value.data, jobList: lists[2].value.data,
        //         paymentMethodList: lists[3].value.data.items
        //     });
        // });

        if(dropDownRead !== true){
            workshopRegistration.store.load().then((list) => {
                setRegistrationList(list.data);
            });

            chartAccountPayment.store.load().then((list) => {
                setPaymentMethodList(list.data.items);
            });

            setDropDownRead(true);
        }
    }, [dropDownRead]);

    useEffect(() => {
        if(presetJobID === null){
            if(addToInvoiceID !== null){
                setPresetJobID(addToInvoiceID);
                setInputType("Add By Job Sheet");
                setCurrentEditID("new");
            }
        }

        //Check Invoice ID from vehicle service record
        if(InvoiceID !== null && readInvoiceIDBefore.current === null){
            setInputType("Add By Job Sheet");
            setCurrentEditID(InvoiceID);
            readInvoiceIDBefore.current = true;
        }
    }, [presetJobID]);

    function handleEdit(e){
        const id = e.row.key;
        const data = e.row.data;
        const InvoiceAmount = data.InvoiceAmount;
        const OutstandingAmount = data.OutstandingAmount;
        
        if(data.JobNo !== ""){
            setInputType("Add By Job Sheet");
        }

        if(InvoiceAmount !== OutstandingAmount){
            setLockForm(true);
        }
        else{
            setLockForm(false);
        }

        setCurrentEditID(id);
    }

    const onItemClick = (e) => {
        const data = e.itemData;

        if(data.name === "Add By Job Sheet"){
            setInputType("Add By Job Sheet");
            setCurrentEditID("new");
        }
        // console.log("item click", data);
    }

    return <Container>
        <div id='normal-listing'>
        <Listing
            apiURL={apiURL}
            listingURL="/api/WKInvoice/list"
            className={" "}
            sortColumn={"DocumentNo"}
            listingTitle={"Workshop Invoice"}
            storageName={"WorkshopInvoiceListing"}
            customizedAddButton={
                <DropDownButton
                    className="workshop-listing-page-add-btn customized-listing-add-btn"
                    splitButton={true}
                    useSelectMode={false}
                    icon='add'
                    text="Add"
                    items={[{ id: 1, name: 'Add By Job Sheet' }]}
                    displayExpr="name"
                    keyExpr="id"
                    width={"100px"}
                    dropDownOptions={
                        {width: "150px"}
                    }
                    onButtonClick={() => {setCurrentEditID("new"); setInputType(null); setLockForm(false);}}
                    onItemClick={onItemClick}
              />
            }
            handleEdit={handleEdit}
        >
            <Column dataField="DocumentNo" />
            <Column dataField="DocumentDate" format="dd/MM/yyyy" dataType={"date"} />
            <Column dataField="CustomerCode" />
            <Column dataField="CustomerName" />
            <Column dataField="RegID" caption={"Reg No"} >
                <Lookup dataSource={registrationList} valueExpr="id" displayExpr={"code"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="JobNoCode" caption={"Job No"}  />
         
            <Column dataField="PaymentMethod" >
                <Lookup dataSource={paymentMethodList} valueExpr="id" displayExpr={"name"} allowClearing={true} searchEnabled={true} />
            </Column>
            <Column dataField="InvoiceAmount" format={{ type: "fixedPoint", precision: 2}} />
            <Column dataField="OutstandingAmount" format={{ type: "fixedPoint", precision: 2}} />
        </Listing>
        </div>

        <WorkshopInvoiceForm 
            formID={currentEditID}
            closePopup={setCurrentEditID}
            apiURL={apiURL} 
            title={"Invoice Entry"} 
        />
       
    </Container>;
}