import { useEffect, useRef, useState, forwardRef } from 'react';
import baseapi from '../../api/baseapi';
import DataGrid, { Editing, Toolbar, Item as ToolbarItem, Popup} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from 'devextreme-react/button';
import { TextBox } from 'devextreme-react';
import DropDownBox from 'devextreme-react/drop-down-box';
import utils from '../../utils/common';
import ValidationGroup from 'devextreme-react/validation-group';
import {
    Validator,
    RequiredRule as ValidatorRequired,
} from 'devextreme-react/validator';

export default forwardRef(function CustomizedLookup(props, ref){
    
    const [selectedKey, setSelectedKey] = useState(null);
    const [tempSelection, setTempSelection] = useState(null);
    const [customTempSelection, setCustomTempSelection] = useState(null);
    const [dropdownOpened, setDropdownOpened] = useState(false);
    const [customDataSource, setCustomDataSource] = useState([]);
    const [searchFocusIndex, setSearchFocusIndex] = useState(-1);
    const [displayText, setDisplayText] = useState(null);
    const [valueExpr, setValueExpr] = useState("id");
    const gridRef = useRef(null);
    const searchBoxRef = useRef(null);
    const dropdownRef = useRef(null);
    const currentFocusIndex = useRef(null);
    const previousDataSource = useRef([]);
    const pagingIndex = useRef({
        loading: false,
        page: 1
    });

    useEffect(() => {
        if(!utils.isNullOrEmpty(props.valueExpr)){
            setValueExpr(props.valueExpr);
        }
    }, [props.valueExpr]);

    useEffect(() => {
        if(selectedKey === null){
            if(searchBoxRef.current !== null)
                searchBoxRef.current.instance.focus();
        }

        //Trigger on value changed
        onValueChanged();
    }, [selectedKey]);

    useEffect(() => {
        if(!utils.isNullOrEmpty(props.dataSource)){
            setCustomDataSource(props.dataSource);
        }
    }, [props.dataSource]);

    useEffect(() => {
        if(pagingIndex.current["loading"] === true && Array.isArray(customDataSource)){
            pagingIndex.current["page"] += 1;
            pagingIndex.current["loading"] = false;
        }

        if(Array.isArray(customDataSource)){
            previousDataSource.current = [...customDataSource];
        }
    }, [customDataSource]);

    useEffect(() => {
        //Value can be null to clear the lookup box
        if(props.hasOwnProperty("value")){
            setSelectedKey(props.value);
        }
    }, [props.value]);

    useEffect(() => {
        if(!utils.isNullOrEmpty(props.displayText)){
            setDisplayText(props.displayText);
        }
        else{
            setDisplayText(null);
        }
    }, [props.displayText]);

    useEffect(() => {
        if(props.preread === true){
            if(props.dataSourceURL !== undefined){
                baseapi.httpget(props.dataSourceURL, utils.mergeObject({ q: null, singleReturn: false }, props.mergeParams))
                .then(response => {
                    const data = response.data;
                    updateDataSource(data);
                })
                .catch(() => { throw 'Network error' });
            }
        }
    }, [props.preread])

    useEffect(() => {
        if(!dropdownOpened){
            //Clear the searching value
            if(searchBoxRef.current !== null){
                searchBoxRef.current.instance.option("value", "");
                setSearchFocusIndex(-1);
                setTimeout(() => {
                    if(dropdownRef.current !== null) dropdownRef.current.instance.focus();
                }, 200);
            }
        }
        else{
            //Set display text to null
            setDisplayText(null);

            if(utils.isNullOrEmpty(customDataSource) || utils.isEmptyArray(customDataSource) && !utils.isNullOrEmpty(props.dataSourceURL)){
                baseapi.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: false }, props.mergeParams))
                .then(response => {
                    const data = response.data;
                    updateDataSource(data);
                })
                .catch(() => { throw 'Network error' });
            }
            else if(!foundInDataSource(selectedKey) && !utils.isNullOrEmpty(props.dataSourceURL) && !utils.isNullOrEmpty(selectedKey)){
                //Keep updating the missing records
                baseapi.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: true }, props.mergeParams))
                .then(response => {
                    const data = response.data;
                    if(Array.isArray(data) && data.length > 0){
                        updateDataSource(data);
                    }
                })
                .catch(() => { throw 'Network error' });
            }

        }
    }, [dropdownOpened]);

    useEffect(() => {
        if(dropdownRef.current !== undefined){
            //Solve the datagrid disabled issues
            const instance = dropdownRef.current.instance;
            instance.option("dropDownOptions").disabled = false;
        } 
    }, []);

    const updateDataSource = (givenSource) => {
        const currentSource = previousDataSource.current;
        if(Array.isArray(givenSource) && Array.isArray(currentSource)){
            // Combine arrays
            const combinedArray = [...currentSource, ...givenSource];

            // Filter out duplicates based on the 'valueExpr' property
            const uniqueArray = combinedArray.filter((item, index, self) =>
                index === self.findIndex((t) => t[valueExpr] === item[valueExpr])
            );

            if(!utils.arrayEqual(currentSource, uniqueArray)){
                const sortedArray = utils.sortArrayByProp(uniqueArray, utils.isNullOrEmpty(props.sortByColumn) ? props.displayExpr : props.sortByColumn);
                setCustomDataSource(sortedArray);

                //Trigger onDataSourceChanged
                if(!utils.isNullOrEmpty(props.onDataSourceChanged)){
                    props.onDataSourceChanged(sortedArray);
                }
            }
        }
    };

    const foundInDataSource = (value) => {
        const copiedArr = [...customDataSource];
        const foundInOriginal = copiedArr.find(c => c[valueExpr] === value);

        if(foundInOriginal === undefined){
            return false;
        }
        else{
            return true;
        }
    }

    const onValueChanged = () => {
        if(props.onValueChanged !== undefined){
            props.onValueChanged({value: selectedKey})
        }
    } 

    const onSelectionChanged = (changedValue) => {
        var value = null;
        if(utils.isObject(changedValue)){
            value = changedValue[valueExpr];
        }
        else{
            value = changedValue;
        }

        if(!utils.isNullOrEmpty(props.onSelectionChanged)){
            props.onSelectionChanged({value: value})
        }
        setSelectedKey(value);
    }

    const OnCustomSelectionChanged = (changedValue, isCustomValue = false) => {
        if(props.OnCustomSelectionChanged !== undefined){
            props.OnCustomSelectionChanged({value: changedValue})
        }
    }

    const clearValue = () => {
        onSelectionChanged(null);
        OnCustomSelectionChanged(null);
        if(searchBoxRef.current !== null) searchBoxRef.current.instance.reset();
    }

    const DataGridRender = () =>{
        return <div className='customized-lookup-container'>
            <div className="customized-lookup-search-container">
                <div>
                    <TextBox 
                        ref={searchBoxRef}
                        placeholder="Search..." 
                        width={160}
                        valueChangeEvent="keyup"
                        onValueChanged={(e) => {
                            gridRef.current.instance.searchByText(e.value);
                            setSearchFocusIndex(0);

                            if(!utils.isNullOrEmpty(props.dataSourceURL)){
                                if(!utils.isNullOrEmpty(e.value)){
                                    baseapi.httpget(props.dataSourceURL, utils.mergeObject({ q: e.value, singleReturn: true }, props.mergeParams))
                                    .then(response => {
                                        const data = response.data;
                                        updateDataSource(data);
                                    })
                                    .catch(() => { throw 'Network error' });
                                }
                            }
                        }}
                        onKeyDown={(e) => {
                            // console.log("key down", e.event)
                            if(e.event.key === "ArrowDown"){
                                gridRef.current.instance.focus();
                            }
                        }}
                    />
                </div>
                
                {
                    props.allowAdd === true && <div className="customized-lookup-btn-section">
                        <Button icon="add" onClick={(e) => {gridRef.current.instance.addRow();}}/>
                    </div>
                }
                
                <div className="customized-lookup-btn-section">
                    <Button text="Clear" onClick={clearValue}/>
                </div>
            </div>
            <DataGrid
                ref={gridRef}
                className={"lookup-datagrid ".concat(!utils.isNullOrEmpty(props.className) ? props.className : "")}
                disabled={false}
                height={!utils.isNullOrEmpty(props.gridHeight) ? props.height : "200px"}
                showBorders={true}
                dataSource={customDataSource}
                columnChooser={{enabled: false}}
                allowColumnResizing={true}
                allowColumnReordering={true}
                hoverStateEnabled={true}
                columnAutoWidth={!utils.isNullOrEmpty(props.gridColumnAutoWidth) ? props.gridColumnAutoWidth : true}
                noDataText={!utils.isNullOrEmpty(props.noDataText) ? props.noDataText : "No data"}
                paging={{enabled: false}}
                keyExpr={!utils.isNullOrEmpty(props.valueExpr) ? props.valueExpr : "id"}
                scrolling={{columnRenderingMode: "standard", showScrollbar: "onHover"}}
                focusedRowEnabled={true}
                focusedRowIndex={searchFocusIndex}
                onKeyDown={(e) => {
                    if(e.event.key === "Enter"){
                        onSelectionChanged(tempSelection);
                        setDropdownOpened(false);
                        OnCustomSelectionChanged(customTempSelection);
                    }
                    if(e.event.key === "ArrowUp"){
                        // If focus is one the first row then brings focus back to the search box
                        if(currentFocusIndex.current === 0){
                            setTimeout(() => {
                                searchBoxRef.current.instance.focus();
                            }, 50);
                        }
                    }
                }}
                onFocusedRowChanging={(e) => {
                    if(e.event === null){
                        e.cancel = true;
                    }
                    else if(e.event.key === undefined){
                        e.cancel = true;
                    }
                }}
                onFocusedRowChanged={(e) => {
                    const data = e.row.data;
                    currentFocusIndex.current = e.rowIndex;
                    setSearchFocusIndex(e.rowIndex);
                    // onSelectionChanged(data[valueExpr]);
                    setTempSelection(data);
                    setCustomTempSelection(data);
                }}
                onCellClick={(e) =>{
                    if(e.rowType === "data"){
                        const data = e.data;
                        onSelectionChanged(data);
                        setDropdownOpened(false);
                        OnCustomSelectionChanged(data);
                    }
                }}
                onRowPrepared={(e) => {
                    const key = e.key;

                    if(key === selectedKey){
                        e.rowElement.classList.add("lookup-selected-highlight");
                    }
                }}
                onContentReady={(element) => {
                    const scrollable = element.component.getScrollable();
                    scrollable.on("scroll", function(e) {
                        if(e.reachedBottom){
                            if(pagingIndex.current["loading"] === false){
                                pagingIndex.current["loading"] = true;
                                baseapi.httpget(props.dataSourceURL, utils.mergeObject({ q: selectedKey, singleReturn: false, page: pagingIndex.current["page"] }, props.mergeParams))
                                .then(response => {
                                    const data = response.data;
                                    updateDataSource(data);
                                })
                                .catch(() => { throw 'Network error' });
                            }              
                        }
                    })
                }}
            >
                <Toolbar visible={false}/>
                {props.children}

                {
                    props.allowAdd === true && <Editing mode={"popup"} allowAdding={true} >
                        <Popup 
                            title={props.lookupTitle !== undefined ? props.lookupTitle : "Title Not Given"} 
                            showTitle={true} 
                            width="auto" 
                            height="auto" 
                            dragEnabled={false} 
                            onShown={(e) => {
                                if(props.popupClassName !== undefined) e.component.content().parentElement.parentElement.classList.add(props.popupClassName);
                            }}
                        />
                    </Editing>
                }
                
            </DataGrid>
        </div>
    };

    const FieldRender = () => {
        return <TextBox
            value={displayText}
            readOnly={true}
        />
    };

    return <ValidationGroup ref={ref}>
            <DropDownBox
            ref={dropdownRef}
            onEnterKey={(e) => {
                setDropdownOpened(true);
            }}
            onOptionChanged={(e) => {
                if(e.name === "opened"){
                    setDropdownOpened(e.value);
                }
            }}
            className={props.dropdownClassName}
            height={props.height}
            opened={dropdownOpened}
            dropDownOptions={{width: "auto"}}
            dataSource={customDataSource}
            value={selectedKey}
            displayExpr={!utils.isNullOrEmpty(props.displayExpr) ? props.displayExpr : "id"}
            valueExpr={valueExpr}
            contentRender={DataGridRender}
            disabled={props.disabled}
            acceptCustomValue={props.acceptCustomValue}
            onValueChanged={(e) => {
                if(props.acceptCustomValue === true){
                    onSelectionChanged(e.value, true);
                }
            }}
            fieldRender={displayText === null ? null : FieldRender}
            readOnly={props.readOnly}
        >
            <Validator>
                { 
                    (props.required && props.startValidation) && <ValidatorRequired message={props.requiredMessage !== undefined ? props.requiredMessage : "This field is required!"} />
                }
            </Validator>
        </DropDownBox>
    </ValidationGroup>
});