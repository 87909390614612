import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signIn as sendSignInRequest } from '../api/auth';
import baseapi from '../api/baseapi';
import { removeFromLS } from '../utils/localstorage';


function AuthProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [companySelection, showCompanySelection] = useState(false);
  useEffect(() => {
    (async function () {
      const result = await getUser();
      if (result.isOk) {
        setUser(result.data);
      }

      setLoading(false);
    })();
  }, []);

  const signIn = useCallback(async (companyCode, email, password) => {
    const result = await sendSignInRequest(companyCode, email, password);
  
    if (result.isOk) {
      setUser(result.data);
    }
    return result;
  }, []);

  const signOut = useCallback(async () => {
    try {
        const user = JSON.parse(localStorage.getItem('data'));

        if (user?.LoginID && user?.CompanyID) {
            const { LoginID, CompanyID } = user;
            await baseapi.httppost('/oauth/Logout', { username: LoginID, companyID: CompanyID });
        }
    } catch (error) {
        console.error("Error during sign out:", error);
    } finally {
        baseapi.setAuthorization(null);
        removeFromLS('Displayname');
        removeFromLS('Role');
        setUser(undefined);
        showCompanySelection(false);
    }
}, []);

  return (
    <AuthContext.Provider value={{ user, signIn, signOut, loading, companySelection, showCompanySelection, setUser}} {...props} />
  );
}

const AuthContext = createContext({ loading: false });
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }
