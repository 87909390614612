import { useEffect, useState, useRef } from "react";
import { TextBox } from "devextreme-react";
import utils from "../../utils/common";
import {
    Validator,
    RequiredRule as ValidatorRequired,
    AsyncRule,
} from 'devextreme-react/validator';

export default function AsyncTextBox(props){

    const previousValue = useRef(null);
    const isValueValid = useRef(true);
    const textboxRef = useRef(null);
    const [startValidation, setStartValidation] = useState(props.startValidation);
    const asyncArgs = props.asyncArgs;
    const requiredMessage = props.requiredMessage === undefined ? "This field is required!" : props.requiredMessage;
    const asyncMessage = props.asyncMessage === undefined ? "This Document No is already taken!" : props.asyncMessage;
    const asyncProperty = props.asyncProperty === undefined ? "docNo" : props.asyncProperty;

    const onValueChanged = (e) =>{
        const value = e.value;
        previousValue.current = value;
        if(props.onValueChanged !== undefined){
            props.onValueChanged(e);
        }
    };

    useEffect(() => {
        if(textboxRef.current){
            // const currentValue = textboxRef.current.instance.option("value");
            var insertValue = props.value;
            if(!utils.isNullOrEmpty(insertValue) && props.toUpperCase !== false){
                insertValue = insertValue.toUpperCase();
            }
            textboxRef.current.instance.option("value", insertValue);
            // if(utils.isNullOrEmpty(currentValue)){
            //     textboxRef.current.instance.option("value", props.value);
            // }
            // else if(!utils.isNullOrEmpty(currentValue) && utils.isNullOrEmpty(props.value)){
            //     textboxRef.current.instance.option("value", props.value);
            // }
        }
        
    }, [props.value]);

    useEffect(() => {
        if(props.startValidation !== undefined){
            setStartValidation(props.startValidation);
        }
    }, [props.startValidation]);

    return(
        <TextBox
            ref={textboxRef}
            elementAttr={props.elementAttr}
            onValueChanged={(e) => {onValueChanged(e)}}
            alignment={props.alignment}
            width={props.width}
            disabled={props.disabled}
            placeholder={props.placeholder}
        >
            <Validator>
                { 
                    startValidation && <ValidatorRequired message={requiredMessage} />
                }

                {
                    startValidation && props.children
                }

                {
                    props.asyncURL && <AsyncRule message={asyncMessage}
                        validationCallback={async (e) => {
                            const inputValue = e.value;
                            if(previousValue.current !== inputValue && !utils.isNullOrEmpty(inputValue)){
                                if(asyncArgs !== undefined){
                                    asyncArgs[asyncProperty] = inputValue; // To prevent value delay
                                    await utils.asyncArgsValidateCallBack(asyncArgs, props.asyncURL).then((value) => {
                                        isValueValid.current = value;
                                    });
    
                                    return new Promise((resolve) => {
                                        resolve(isValueValid.current);
                                    });
                                }
                                else{
                                    await utils.asyncValidateCallBack(asyncProperty, inputValue, props.asyncURL).then((value) => {
                                        isValueValid.current = value;
                                    });
    
                                    return new Promise((resolve) => {
                                        resolve(isValueValid.current);
                                    });
                                }
                                

                                // return utils.asyncValidateCallBack("docNo", e.value, props.asyncURL);
                            }
                            else{
                                return new Promise((resolve) => {
                                    resolve(isValueValid.current);
                                });
                            }
                        
                        }}
                        reevaluate={false}
                    />
                }
                
                
            </Validator>
        </TextBox>
    )
}