import { Popup, Button } from "devextreme-react";
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import { useEffect, useState, useRef, useMemo } from "react";
import "./CreditTermForm.scss";
import baseapi from "../../api/baseapi";
import ErrorPopUpForm from "../../components/popup-form/ErrorPopupForm";
import utils from "../../utils/common";

export default function CreditTermForm(props) {
	const [visible, setVisible] = useState(props.errorVisible);
	const userType = props.userType;
	const isCustomer = props.isCustomer;
	const creditTerm = props.creditTerm;
	const totalOutAmt = props.totalOutAmt;
	const overdueDays = props.overdueDays;
	const [password, setPassword] = useState("");
	const popupMessageRef = useRef(null);
	const [closedOnly, setClosedOnly] = useState(false);
	const [passwordMode, setPasswordMode] = useState("password");

	useEffect(() => {
		if (props.onValueChanged !== undefined) {
			props.onValueChanged({ value: visible });
		}
	}, [visible]);

	useEffect(() => {
		if (props.closedOnly !== undefined) {
			if (closedOnly) {
				props.closedOnly({ value: closedOnly });
			}
		}
	}, [closedOnly]);

	function onClose() {
		setPassword("");
		setVisible(false);
	}

	const passwordButton = useMemo(
		() => ({
			icon: "images/eye-regular.svg",
			onClick: () => {
				setPasswordMode((prevPasswordMode) => (prevPasswordMode === "text" ? "password" : "text"));
			}
		}),
		[setPasswordMode]
	);

	function handleSubmit() {
		if (password.length > 0) {
			const email = JSON.parse(localStorage.getItem("data")).LoginID;
			const companyID = JSON.parse(localStorage.getItem("data")).CompanyID;
			baseapi.httppost(`api/UserProfile/CheckPassword?companyID=${companyID}&email=${email}&password=${password}`).then((res) => {
				const { data } = res;
				// console.log(data);
				if (data.status) {
					props.ReturnSaveResult({ canSave: data.status });
					onClose();
				} else {
					utils.displayPopupMessage(popupMessageRef, {
						visible: true,
						message: data.message,
						type: "Danger"
					});
				}
			});
		} else {
			utils.displayPopupMessage(popupMessageRef, {
				visible: true,
				message: `Please enter password to proceed`,
				type: "Danger"
			});
		}
	}

	function valueOnChange(e, receivedDataField = null) {
		try {
			if (e.value) {
				// console.log(e.value);
				const dataField = receivedDataField !== null ? receivedDataField : e.element.getAttribute("dataField");
				if (dataField === "Password") {
					setPassword(e.value);
				}
			}
		} catch (err) {
			console.log(err);
		}
	}

	return (
		<div>
			<Popup id='OutstandingMessagePopUp' visible={visible} width={450} showTitle={false} hideOnOutsideClick={true} height={"auto"} shading={false} onHiding={() => onClose()}>
				<div className='outstandingMessage-popup-items'>
					<div className='outstandingMessage-popup-header'>
						<div className='outstandingMessage-popup-cross'>
							<Button
								icon='close'
								stylingMode='text'
								onClick={() => {
									onClose();
									setClosedOnly(true);
								}}
							/>
						</div>
					</div>
				</div>
				<div className='outstandingMessage-popup-body'>
					<div className='outstandingMessage-popup-content'>
						<div>
							{isCustomer ? `Customer ${userType} ` : `Supplier ${userType} `}
							<b>has exceeded the credit term limit</b>
						</div>
						<div className='outstandingMessage-popup-limits' style={{ marginTop: 10 }}>
							<div className='outstandingMessage-popup-limit-item'>
								<div>
									<div>Credit Term Limit: </div>
								</div>
							</div>

							<div className='outstandingMessage-popup-limit-item'>
								<div>
									<div>{creditTerm} Day(s)</div>
								</div>
							</div>
							<div className='outstandingMessage-popup-limit-item'>
								<div>
									<div>Overdue Days: </div>
								</div>
							</div>

							<div className='outstandingMessage-popup-limit-item'>
								<div>
									<div style={{ color: "red" }}>{overdueDays}</div>
								</div>
							</div>
							<div className='outstandingMessage-popup-limit-item'>
								<div>
									<div>Overdue Credit: </div>
								</div>
							</div>

							<div className='outstandingMessage-popup-limit-item'>
								<div>
									<div style={{ color: "red" }}>{utils.numberToCurrency(totalOutAmt)}</div>
								</div>
							</div>
						</div>
						<div style={{ marginBottom: 10, marginTop: 10 }}>Please refer to authorize personal.</div>
						<div style={{ marginBottom: 5 }}>Enter the password to continue: </div>
						<TextBox
							mode={passwordMode}
							showClearButton={true}
							elementAttr={{ dataField: "Password" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}>
							<TextBoxButton name='password' location='after' options={passwordButton} />
						</TextBox>

						<div className='outstandingMessage-popup-footer'>
							<Button type='success' stylingMode='contained' text='Confirm' onClick={() => handleSubmit()} />
							<Button
								type='success'
								stylingMode='outlined'
								text='Cancel'
								onClick={() => {
									onClose();
									setClosedOnly(true);
								}}
							/>
						</div>
					</div>
				</div>
			</Popup>

			<ErrorPopUpForm ref={popupMessageRef} />
		</div>
	);
}
