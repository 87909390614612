
import { useEffect, useRef, useState, useCallback, useMemo } from "react";

import StockReceivedForm from './form';
import Container from '../../../components/container';
import Listing from '../../../components/listing/newListing';
import utils from '../../../utils/common';
import PathIndicator from "../../../components/path-indicator/PathIndicator";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea } from "devextreme-react";
import { Button } from "devextreme-react/button";
import CustomizedLookup from "../../../components/lookup/Lookup";
import DataGrid, { Column, Editing, Selection, Scrolling, Lookup, KeyboardNavigation, ColumnChooser, Summary, TotalItem, Toolbar, Item as ToolbarItem } from "devextreme-react/data-grid";
import MoreInfor from "./moreInfor";
import AdvancedFilter from './advancedFilter';
import StockAdjustmentForm from "../../../pages/Stock-Module/adjustment/form";
import StockIssueForm from "../../../pages/Stock-Module/issue/form";
import StockReceiveForm from "../../../pages/Stock-Module/received/form";
import StockTakeForm from "../../../pages/Stock-Module/take/form";
import StockTransferForm from "../../../pages/Stock-Module/transfer/form";
import StockWriteOff from "../../../pages/Stock-Module/write-off/form";
import StockTransferFrom from "../../../pages/Stock-Module/transfer/form";
import StockWriteOffForm from "../../../pages/Stock-Module/write-off/form";

import DOForm from "../../../pages/Sales-Module/delivery-order/form";
import INVForm from "../../../pages/Sales-Module/invoice-main/form";
import CashSales from "../../../pages/Sales-Module/cash-sales/form";
import SalesDebitNote from "../../../pages/Sales-Module/debit-note/form";
import SalesCreditNote from "../../../pages/Sales-Module/credit-note/form";


import PurchaseReceive from "../../../pages/Purchase-Module/received/form";
import PurchaseInvoice from "../../../pages/Purchase-Module/invoice/form";
import CashPurchase from "../../../pages/Purchase-Module/cash-purchase/form";
import PurchaseDebitNote from "../../../pages/Purchase-Module/debit-note/form";
import ReturnPurchase from "../../../pages/Purchase-Module/purchase-return/form";

export default function StockAnalysisListing() {


    const apiURL = `/api/StockReceive`;
    const [formValue, setFormValue] = useState({});
    const [lockForm, setLockForm] = useState(false);
    const [currentEditID, setCurrentEditID] = useState(null);
    const [currentPrefix, setCurrentEditPrefix] = useState(null);
    const [stockList, setStockList] = useState([]);
    const stockLookupRef = useRef(null);
    const formRead = useRef(false);
    const formID = useRef(null);
    const tempForm = useRef(null);
    const searchBoxRef = useRef(null);
    const [displayMoreInfor, setDisplayMoreInfor] = useState(false);
    const [listingTransactionSource, setListingTransactionSource] = useState([]);
    const [listingFirstDynamicPriceSource, setListingFirstDynamicPriceSource] = useState([]);
    const [listingLastDynamicPriceSource, setListingLastDynamicPriceSource] = useState([]);
    const [listingStockShelfSource, setListingStockShelfSource] = useState([]);
    const [listingStockAlternateSource, setListingStockAlternateSource] = useState([]);
    const [originalListingTransactionSource, setOriginalListingTransactionSource] = useState([]);
    const [transferData, setTransferData] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const fromDate = useRef(null);
    const toDate = useRef(null);
    const query = useRef(null);
    const advancedFilterRef = useRef(null);
    let filterQuery = null;
    const advanceFilter = useRef(null);
    function handleEdit(e) {
        const id = e.row.key;
        setLockForm(utils.isLockedForm(e));
        setCurrentEditID(id);
    }



    const refresh = () => {
        // Convert fromDate and toDate to the start of the respective days
        let from = fromDate.current ? new Date(fromDate.current) : null;
        let to = toDate.current ? new Date(toDate.current) : null;

        if (from) {
            from.setHours(0, 0, 0, 0); // Set 'from' to the start of the day
        }
        if (to) {
            to.setHours(23, 59, 59, 999); // Set 'to' to the end of the day
        }

        const searchQuery = query.current ? query.current.toLowerCase() : '';
        console.log(filterQuery, "filterQuery")
        if (filterQuery !== null) {
            advanceFilter.current = filterQuery;
        }
        const filterQueryLowercase = advanceFilter.current ? advanceFilter.current.toLowerCase() : '';

        const searchTerms = searchQuery.split(',').filter(term => term);
        const filterTerms = filterQueryLowercase.split(',').filter(term => term);
        
        // Filter function
        const filterTransactions = (transaction) => {
            const transactionDate = new Date(transaction.DocumentDate.split('T')[0]);

            // Lowercase the combined column for case-insensitive comparison
            const combinedColumn = transaction.CombinedColumn ? transaction.CombinedColumn.toLowerCase() : '';

            const dateInRange = (!from || transactionDate >= from) && (!to || transactionDate <= to);
            const searchQueryMatch = searchTerms.every(term => combinedColumn.includes(term));
            const filterQueryMatch = filterTerms.every(term => combinedColumn.includes(term));

            return dateInRange && searchQueryMatch && filterQueryMatch;
        };

        // Apply filters
        const filteredData = originalListingTransactionSource.filter(filterTransactions);

        // Update your DataGrid's dataSource with the filtered data
        setListingTransactionSource(filteredData); // Replace with your state update function
    };
    //using backend
    // const refresh = () => {
    //     // Format the dates to ISO string without time for backend processing
    //     const formattedFromDate = fromDate.current ? new Date(fromDate.current).toISOString().split('T')[0] : null;
    //     const formattedToDate = toDate.current ? new Date(toDate.current).toISOString().split('T')[0] : null;

    //     return baseapi
    //         .httpget("/api/StockDetailAnalysis/list", {
    //             q: query.current,
    //             fromDate: formattedFromDate,
    //             toDate: formattedToDate,
    //             // Additional parameters if needed
    //         })
    //         .then((response) => {
    //             setListingTransactionSource(response); // Update the DataGrid's dataSource
    //         })
    //         .catch(() => {
    //             throw "Network error";
    //         });
    // };

    const handleFormSubmitSuccess = () => {
        loading("Loading Latest Stock Details...");

        baseapi.httpget("/api/StockDetailAnalysis/GetAnalysisTransaction?id=" + formID.current)
            .then((response) => {
                const { data } = response;

                setListingTransactionSource(data.transactions);
                setOriginalListingTransactionSource(data.transactions);

            })
            .catch((error) => {
                console.error('Error fetching stock details:', error);
            })
            .finally(() => {

                setTimeout(() => {
                    closeLoading();
                }, 300);

            });
    };

    function valueOnChange(e, TakeDataField = null) {
        try {
            if (!utils.isNullOrEmpty(e.value)) {

                const dataField = TakeDataField !== null ? TakeDataField : e.element.getAttribute("dataField");
                console.log(e.value, "e.value")
                if (dataField === "StockID") {

                    loading("Loading Stock Details...");
                    console.log(e.value, "e.value")
                    formID.current = e.value;
                    baseapi.httpget("/api/StockDetailAnalysis/Get?id=" + (e.value)).then((response) => {
                        const { data } = response;
                        setFormValue(data.model.Parent);
                        setListingStockShelfSource(data.model.StockShelfChildren);
                        setListingStockAlternateSource(data.model.StockAlternateChildren);

                        closeLoading();
                    });
                    baseapi
                        .httpget("/api/StockDetailAnalysis/GetFirstLastDynamicFields?id=" + (e.value))
                        .then((response) => {
                            const { data } = response;
                            setListingFirstDynamicPriceSource(data.FirstDynamicFields)
                            setListingLastDynamicPriceSource(data.LastDynamicFields)
                        })
                        .catch((e) => {
                            throw e;
                        });
                    baseapi
                        .httpget("/api/StockDetailAnalysis/GetAnalysisTransaction?id=" + (e.value))
                        .then((response) => {
                            const { data } = response;
                            setListingTransactionSource(data.transactions);
                            setOriginalListingTransactionSource(data.transactions);
                        })
                        .catch((e) => {
                            throw e;
                        });


                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleShowFilter = () => {

        if (advancedFilterRef.current) {
            advancedFilterRef.current.show();
        }
    };
    const [currentForm, setCurrentForm] = useState(null);
    const handleRowClick = (data) => {
        // const id = data ? data.DocumentID : 0;
        // setLockForm(utils.isLockedForm(data));
        // setCurrentEditID(id);
        console.log(data,"datasass")
        setCurrentEditID(null);
        setCurrentEditPrefix(null);
        loading();
        setCurrentForm(null);
        setTimeout(() => {

            closeLoading();
        }, 300);
        setTimeout(() => {
            setCurrentEditID(data.DocumentID);
            setCurrentEditPrefix(data.TransactionTypeID);
        }, 0);
        

    };

    return <Container>

        <div>
            <div className='listing-page-title-container'>
                <span className='datagrid-customized-title'>
                    <PathIndicator />
                </span>
            </div>
            <div className='popup-form-main-content'>
                <div className='custom-form-grid-section-50-50'>
                    <div className='popup-form-item-container2' style={{ marginTop: '5px' }}>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>Stock Code: </div>
                            <div className='popup-group-form-input'>
                                <CustomizedLookup
                                    ref={stockLookupRef}
                                    className={"ar-listing-datagrid"}
                                    dataSource={stockList}
                                    displayExpr={"code"}
                                    valueExpr={"id"}
                                    value={formValue["StockID"]}
                                    onSelectionChanged={(e) => {
                                        valueOnChange(e, "StockID");
                                    }}
                                    required={true}
                                    requiredMessage={"Stock is required!"}

                                    dataSourceURL={"api/Utilities/GetStock"}
                                    onDataSourceChanged={setStockList}
                                    preread={true}>
                                    <Column dataField='code'></Column>
                                    <Column dataField='name'></Column>
                                </CustomizedLookup>
                            </div>
                        </div>

                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>Stock Group: </div>
                            <div className='popup-group-form-input'>
                                <TextBox
                                    value={formValue["StockGroupCode"]}
                                    elementAttr={{ dataField: "StockGroupCode" }}
                                    onValueChanged={(e) => valueOnChange(e)}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={true}
                                >
                                </TextBox>
                            </div>
                        </div>


                    </div>
                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>UOM: </div>
                            <div className='popup-group-form-input'>
                                <TextBox
                                    value={formValue["UOMCode"]}
                                    elementAttr={{ dataField: "UOMCode" }}
                                    onValueChanged={(e) => valueOnChange(e)}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={true}
                                >
                                </TextBox>
                            </div>
                        </div>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>Tot. Net Qty:</div>

                            <div className='popup-group-form-input' id='customized-lookup1'>
                                <NumberBox
                                    value={formValue["NetQuantity"]}
                                    elementAttr={{ dataField: "NetQuantity" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    format="#,##0.##"
                                    readOnly={true}
                                >

                                </NumberBox>
                            </div>
                        </div>

                    </div>

                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item-stock-detail-description'>
                            <div className='popup-group-form-label'>Description: </div>
                            <div className='popup-group-form-input'>
                                <TextBox
                                    value={formValue["Description"]}
                                    elementAttr={{ dataField: "Description" }}
                                    onValueChanged={(e) => valueOnChange(e)}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={true}
                                >
                                </TextBox>
                            </div>
                        </div>


                    </div>
                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>Standard Cost: </div>

                            <div className='popup-group-form-input' id='customized-lookup1'>
                                <NumberBox
                                    value={formValue["StandardCost"]}
                                    elementAttr={{ dataField: "StandardCost" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    format="#,##0.##"
                                    readOnly={true}
                                >

                                </NumberBox>
                            </div>
                        </div>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>Average Cost: </div>

                            <div className='popup-group-form-input' id='customized-lookup1'>
                                <NumberBox
                                    value={formValue["AverageCost"]}
                                    elementAttr={{ dataField: "AverageCost" }}
                                    alignment='left'
                                    width={"auto"}
                                    format="#,##0.##"
                                    readOnly={true}
                                >

                                </NumberBox>
                            </div>
                        </div>


                    </div>

                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item-stock-detail-description'>
                            <div className='popup-group-form-label'>Last Purchase: </div>
                            <div className='popup-group-form-input'>
                                <TextBox
                                    value={formValue["LastPurchase"]}
                                    elementAttr={{ dataField: "LastPurchase" }}
                                    onValueChanged={(e) => valueOnChange(e)}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={true}
                                >
                                </TextBox>
                            </div>
                        </div>


                    </div>

                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>P/O Qty </div>

                            <div className='popup-group-form-input' id='customized-lookup1'>
                                <NumberBox
                                    value={formValue["POQty"]}
                                    elementAttr={{ dataField: "POQty" }}

                                    alignment='left'
                                    width={"auto"}
                                    format="#,##0.##"
                                    readOnly={true}
                                >

                                </NumberBox>
                            </div>
                        </div>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>S/O Qty:</div>

                            <div className='popup-group-form-input' id='customized-lookup1'>
                                <NumberBox
                                    value={formValue["SOQty"]}
                                    elementAttr={{ dataField: "SOQty" }}
                                    alignment='left'
                                    width={"auto"}
                                    format="#,##0.##"
                                    readOnly={true}
                                >

                                </NumberBox>
                            </div>
                        </div>


                    </div>

                    <div className='popup-form-item-container'>
                        <div className='popup-group-form-item-stock-detail-description'>
                            <div className='popup-group-form-label'>Last Sales: </div>
                            <div className='popup-group-form-input'>
                                <TextBox
                                    value={formValue["LastSales"]}
                                    elementAttr={{ dataField: "LastSales" }}
                                    onValueChanged={(e) => valueOnChange(e)}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={true}
                                >
                                </TextBox>
                            </div>
                        </div>


                    </div>


                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>Min Level:</div>

                            <div className='popup-group-form-input' id='customized-lookup1'>
                                <NumberBox
                                    value={formValue["MinLevel"]}
                                    elementAttr={{ dataField: "MinLevel" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={true}
                                >

                                </NumberBox>
                            </div>
                        </div>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>Max Level:</div>

                            <div className='popup-group-form-input' id='customized-lookup1'>
                                <NumberBox
                                    value={formValue["MaxLevel"]}
                                    elementAttr={{ dataField: "MaxLevel" }}
                                    onValueChanged={(e) => {
                                        valueOnChange(e);
                                    }}
                                    alignment='left'
                                    width={"auto"}
                                    readOnly={true}
                                >

                                </NumberBox>
                            </div>
                        </div>


                    </div>

                </div>

                <div
                    style={{ margin: '5px 5px 0 0px', textAlign: 'right', color: 'green', textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => setDisplayMoreInfor(true)}
                >
                    More Info
                </div>
                <div className='inventory-module-parent-without-margin-bottom-section custom-form-grid-section-50-50' style={{ margin: '10px 0 0 0' }}>

                    <div className='popup-group-form-item'>
                        <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
                            <DataGrid
                                dataSource={listingFirstDynamicPriceSource}
                                sorting={false}
                            >
                                <Column dataField='DisplayName' caption={""} editorOptions={{ readOnly: true }} />
                                <Column dataField='Price' caption={"Amount"} alignment={"center"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} editorOptions={{ readOnly: true }} />
                                <Editing allowUpdating={true} allowDeleting={false} mode='cell' />
                            </DataGrid>

                        </div>
                        <hr style={{ margin: "0px 0", border: "none", borderRight: "1px solid #ccc" }} />
                        <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
                            <DataGrid
                                dataSource={listingLastDynamicPriceSource}
                                sorting={false}
                            >
                                <Column dataField='DisplayName' caption={""} editorOptions={{ readOnly: true }} />
                                <Column dataField='Price' caption={"Amount"} alignment={"center"} format={{ type: "fixedPoint", precision: 2 }} dataType={"number"} editorOptions={{ readOnly: true }} />
                                <Editing allowUpdating={true} allowDeleting={false} mode='cell' />
                            </DataGrid>

                        </div>
                    </div>
                    <div className='popup-group-form-item'>
                        <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
                            <DataGrid
                                dataSource={listingStockShelfSource}
                                sorting={false}
                                scrolling={{ mode: "Standard" }}
                                height={148}
                            >
                                <Column dataField='NO' caption="No" dataType='number' editorOptions={{ disabled: true }} />
                                <Column dataField='ShelfCode' caption={"Shelf No"} editorOptions={{ readOnly: true }} />
                                <Column dataField='LocationCode' caption={"Location"} editorOptions={{ readOnly: true }} />
                                <Column dataField='NetQuantity' caption="Net Qty" dataType='number' editorOptions={{ disabled: true }} />
                                <Editing allowUpdating={true} allowDeleting={false} mode='cell' />
                            </DataGrid>
                        </div>
                    </div>
                </div>

                <div className='custom-form-grid-section-50-50' style={{ marginTop: '10px' }}>
                    <div className='popup-form-item-container2'>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>From Date:</div>

                            <div className='popup-group-form-input'>
                                <DateBox
                                    displayFormat='dd/MM/yyyy'
                                    dateSerializationFormat='yyyy-MM-dd'
                                    useMaskBehavior={true}
                                    type='date'
                                    showClearButton={true}
                                    onValueChanged={(e) => {
                                        fromDate.current = e.value;
                                        refresh();

                                    }}
                                />
                            </div>

                        </div>
                        <div className='popup-group-form-item-stock-detail'>
                            <div className='popup-group-form-label'>To Date:</div>

                            <div className='popup-group-form-input'>
                                <DateBox
                                    displayFormat='dd/MM/yyyy'
                                    dateSerializationFormat='yyyy-MM-dd'
                                    useMaskBehavior={true}
                                    type='date'
                                    showClearButton={true}
                                    onValueChanged={(e) => {
                                        toDate.current = e.value;
                                        refresh();

                                    }}
                                />
                            </div>

                        </div>



                    </div>
                    <div className='custom-form-grid-section-50-50 '>

                        <div style={{ margin: '2.5px 10px' }}>
                            <TextBox
                                width={"100%"}
                                height={"36px"}
                                ref={searchBoxRef}
                                className='listing-page-search-box'
                                placeholder='Search...'
                                value={query.current}
                                valueChangeEvent='keyup'
                                onValueChanged={(e) => {
                                    query.current = e.value;
                                    refresh();
                                }}
                            />

                        </div>




                        <div className='popup-group-form-item-stock-detail'>

                            <Button
                                width={"auto"}
                                id='submit-link'
                                splitButton={true}
                                useSelectMode={true}
                                text='Clear Filter'
                                displayExpr='text'
                                keyExpr='id'
                                elementAttr={{ class: "popup-form-submit-btn" }}
                                style={{ backgroundColor: "#5cb85c", borderRadius: "4px", marginRight: "5px" }}
                                stylingMode='text'
                                onClick={() => {
                                    query.current = null;
                                    fromDate.current = null;
                                    toDate.current = null;
                                    advanceFilter.current && (advanceFilter.current = null);
                                    refresh();
                                }}

                            />

                            <Button
                                width={"auto"}
                                id='submit-link'
                                splitButton={true}
                                useSelectMode={true}
                                text='Filter By '
                                displayExpr='text'
                                keyExpr='id'
                                style={{ backgroundColor: "#EFEFEF", borderRadius: "4px", marginRight: "5px", color: "black" }}// Set the font color to black }}
                                stylingMode='text'
                                onClick={() => handleShowFilter()}
                            />
                        </div>


                    </div>

                </div>

                <div style={{ marginTop: '20px' }}>
                    <div className='popup-group-form-item'>
                        <div className='inventory-module-border-bottom' style={{ padding: "0 0" }}>
                            <DataGrid
                                dataSource={listingTransactionSource}
                                showBorders={true}
                                height={300}
                                // columnAutoWidth={true}
                                sorting={{ mode: "multiple" }} // Enable sorting
                                allowColumnReordering={true} // Enable column reordering
                                allowColumnResizing={true} // Enable column resizing
                                paging={{ enabled: false }}
                                width={"167vh"}
                                scrolling={{ columnRenderingMode: "virtual", showScrollbar: true }}
                            >
                                <Column dataField='DocumentNo' width="120px" caption="Document No" editorOptions={{ disabled: true }} cellRender={(e) => {
                                    const data = e.data;
                                    const id = parseInt(data.DocumentID);

                                    return (
                                        <span
                                            className='clickable-span-tag'
                                            onClick={() => handleRowClick(data)}>
                                            {e.displayValue}
                                        </span>
                                    )

                                }} />
                                <Column dataField='DocumentDate' caption={"Date"} editorOptions={{ readOnly: true }} format="dd/MM/yyyy" dataType={"date"} width="100px" />
                                <Column dataField='TransactionTypeCode' caption={"Type"} editorOptions={{ readOnly: true }}   width="70px" />
                                <Column dataField='Remarks' caption="Customer/Supplier/Remarks" editorOptions={{ disabled: true }} width="280px" />
                                <Column dataField='InQuantity' caption="In" dataType='number' editorOptions={{ disabled: true }} width="50px" />
                                <Column dataField='OutQuantity' caption="Out" dataType='number' editorOptions={{ disabled: true }} width="50px" />
                                <Column dataField='BalanceQuantity' caption="Balance Quantity" dataType='number' editorOptions={{ disabled: true }} width="120px" />
                                <Column dataField='CostPrice' caption="Cost/Price" dataType='number' editorOptions={{ disabled: true }} width="90px" />
                                <Column dataField='LocationCode' caption="Location" width="90px" />
                                <Column dataField='FromLocationCode' caption="From Location" width="90px" />
                                <Column dataField='ToLocationCode' caption="To Location" width="90px" />
                                <Column dataField='Description' caption="Stock Description" editorOptions={{ disabled: true }} width="280px"/>
                                <Column dataField='CombinedColumn' caption="Stock Description" editorOptions={{ disabled: true }} visible={false}  />

                            </DataGrid>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {displayMoreInfor && (
            <MoreInfor
                visible={displayMoreInfor}
                formValue={formValue} // Pass formValue as a prop
                listingStockAlternateSource={listingStockAlternateSource}
                onValueChanged={(e) => {
                    setDisplayMoreInfor(e.value);
                }}

            />
        )}

        <AdvancedFilter
            ref={advancedFilterRef}
            ReturnResult={(e) => {
                
                filterQuery = e.data;
                refresh();
            }}
        />


        {currentEditID && currentPrefix && (
            <div>

                <div>
                    {(currentPrefix === "89") && <StockReceiveForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "94") && <StockIssueForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "96") && <StockAdjustmentForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "108") && <StockTransferForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "107") && <StockWriteOffForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} />}
                    {(currentPrefix === "101") && <StockTakeForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={true} onSubmitSuccess={handleFormSubmitSuccess} />}
                    
                    
                    {(currentPrefix === "27") && <INVForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Invoice Entry"} apiURL="/api/SalesInvoiceMain" transferData={transferData} clearTransfer={setTransferData}/>}
                    {(currentPrefix === "23") && <DOForm formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Delivery Order"}  apiURL="/api/SalesDeliveryOrder"/>}
                    {(currentPrefix === "113") && <CashSales formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} title={"Cash Sales Entry"} apiURL="/api/SalesCashSales" />}
                    {(currentPrefix === "97") && <SalesDebitNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess}  title={"Debit Note"} apiURL="/api/SalesDebitNote" />}
                    {(currentPrefix === "98") && <SalesCreditNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess}   title={"Credit Note"} apiURL="/api/SalesCreditNote"/>}
                 
                    {(currentPrefix === "109") && <PurchaseReceive formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess}   title={"Purchase Received"} apiURL="/api/PurchaseReceived" />}
                    {(currentPrefix === "110") && <PurchaseInvoice formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess}  apiURL="/api/PurchaseInvoice"  title={"Purchase Invoice"}/>}
                    {(currentPrefix === "111") && <CashPurchase formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseCashPurchase"  title={"Cash Purcahse"} />}
                    {(currentPrefix === "103") && <PurchaseDebitNote formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess}  apiURL="/api/PurchaseDebitNote"  title={"Debit Note"} />}
                    {(currentPrefix === "47") &&  <ReturnPurchase formID={currentEditID} closePopup={setCurrentEditID} lockedForm={lockForm} onSubmitSuccess={handleFormSubmitSuccess} apiURL="/api/PurchaseReturn"  title={"Purchase Return"} />}
                </div>

            </div>
        )}

    </Container>



}