import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  EmailRule
} from 'devextreme-react/form';
import { ScrollView } from "devextreme-react";
import baseapi from '../../api/baseapi';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';
import { useAuth } from '../../contexts/auth';
import { TextBox, Button as TextBoxButton } from "devextreme-react/text-box";
import NumberBox from 'devextreme-react/number-box';
import Validator, { RequiredRule, PatternRule, CompareRule, CustomRule, AsyncRule } from 'devextreme-react/validator';
import CheckBox from 'devextreme-react/check-box';
import './RegisterForm.scss';
import Swal from 'sweetalert2';
import ValidationGroup from 'devextreme-react/validation-group';
import { Popup } from 'devextreme-react/popup';
import { SelectBox } from 'devextreme-react/select-box';
import { DateBox } from 'devextreme-react/date-box';
import { useLocation } from 'react-router-dom';
import utils from "../../utils/common";
import { handleUserProfileAndAuthorization } from '../../api/auth';
export default function RegisterForm() {
  const navigate = useNavigate();
  const { signIn, user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const popupWidth = useRef('45%'); // Default width
  const location = useLocation();
  const [scrollViewHeight, setScrollViewHeight] = useState(utils.GetScrollViewHeightV2());
  const [focus, setFocus] = useState({});
  const firstFormValidatorRef = useRef(null);
  const secondFormValidatorRef = useRef(null);
  const thirdFormValidatorRef = useRef(null);
  const [passwordMode, setPasswordMode] = useState('password');
  const [confirmPasswordMode, setConfirmPasswordMode] = useState('password');
  const [currentStep, setCurrentStep] = useState(1); // Track current step
  const [termsDialogVisible, setTermsVisible] = useState(false);
  const [privacyDialogVisible, setPrivacyVisible] = useState(false);
  const [plansVisible, setPlansVisible] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState(null);
  const [dropDownList, setDropDownList] = useState({
    countryList: [], planList: [], planDurationList: []
  });

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [additionalUserCost, setAdditionalUserCost] = useState(0);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const { setUser } = useAuth();
  const formData = useRef({
    email: '',
    userName: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
    companyName: '',
    countryID: '',
    fiscalStartYear: '',
    fiscalYearEnd: '',
    planID: '',
    licensedUser: 0,
    subscriptionEndDate: '',
  });

  const fetchPlansAndCountries = async () => {
    try {
      const [plansResponse, countriesResponse, planDurationResponse] = await Promise.all([
        baseapi.httpget('/api/register/GetGreenPlusPlans'),
        baseapi.httpget('/api/register/GetGreenPlusCountries'),
        baseapi.httpget('/api/register/GetGreenPlusPlansDuration')
      ]);

      setDropDownList({
        planList: plansResponse.data,
        countryList: countriesResponse.data,
        planDurationList: planDurationResponse.data
      });

      // Set default country value to Malaysia
      const defaultCountry = countriesResponse.data.find(country => country.Name === 'Malaysia');
      setDefaultCountry(defaultCountry ? defaultCountry.id : null);

      // Fetch the plan from URL parameters
      const params = new URLSearchParams(location.search);
      const planId = params.get('plan');
      if (planId) {
        const selectedPlan = plansResponse.data.find(plan => plan.Name === planId);
        if (selectedPlan) {
          formData.current.plan = selectedPlan;
        }
      }
      setIsLoaded(true);
    } catch (error) {
      console.error('Error fetching plans or countries:', error);
    }
  };

  // Calculate selectedDurationPrice whenever dropDownList.planDurationList or formData.current.subscriptionEndDate changes
  useEffect(() => {
    const duration = dropDownList.planDurationList.find(
      duration => duration.Duration === formData.current.subscriptionEndDate
    ) || null;
    setSelectedDuration(duration);
  }, [dropDownList.planDurationList, formData.current.subscriptionEndDate]);

  // Calculate additionalUserCost whenever formData.current.licensedUser or selectedPlan?.LicensedUserPrice changes
  useEffect(() => {
    const cost = (formData.current.licensedUser || 0) * (selectedPlan?.LicensedUserPrice || 0);
    setAdditionalUserCost(cost);
  }, [formData.current.licensedUser, selectedPlan?.LicensedUserPrice]);


  //this method is used to calculate the total fee
  const calculateTotalFee = () => {
    const duration = selectedDuration?.Duration || 0;
    return (selectedPlan?.Fee || 0) * duration + additionalUserCost;
  };



  useEffect(() => {
    fetchPlansAndCountries();
  }, [location.search]);

  const checkWindowSize = useCallback(() => {
    if (window.innerWidth < 870) {
      popupWidth.current = '100%';
    } else {
      popupWidth.current = '45%';
    }
  }, []);

  useEffect(() => {
    // Check window size initially
    checkWindowSize();

    // Add resize listener to check window size dynamically
    window.addEventListener('resize', checkWindowSize);

    return () => {
      // Clean up resize listener on component unmount
      window.removeEventListener('resize', checkWindowSize);
    };
  }, [checkWindowSize]);

  const handlePlanChange = (e) => {
    const selectedId = e.value;
    const selectedPlan = dropDownList.planList.find(plan => plan.id === selectedId);
    if (selectedPlan) {
      setSelectedPlan(selectedPlan)
      formData.current.planID = selectedPlan.id;
      setFocus(prevFocus => ({ ...prevFocus, planID: !!e.value }));
    } else {
      console.error('Selected plan not found');
    }
  };
  useEffect(() => {
    if (isLoaded) { // Run this effect only if the dropdown list has been loaded
      const params = new URLSearchParams(location.search);
      const planId = params.get('plan');
      if (planId) {
        const selectedPlan = dropDownList.planList.find(plan => plan.Name === planId);
        if (selectedPlan) {
          setSelectedPlan(selectedPlan)
          formData.current.plan = selectedPlan.id;
        }
      }
    }
  }, [location.search, dropDownList.planList, isLoaded]);


  const onFocus = useCallback((field) => {
    setFocus(prevFocus => ({ ...prevFocus, [field]: true }));
  }, []);

  const onBlur = useCallback((field) => {
    if (!formData.current[field]) {
      setFocus(prevFocus => ({ ...prevFocus, [field]: false }));
    }
  }, []);

  const onSubmitFirstForm = useCallback((e) => {
    e.preventDefault();
    const validation = firstFormValidatorRef.current.instance.validate();


    // Ensure email validation status is checked
    if (!validation.isValid || !isEmailValid) {
      return;
    }

    setCurrentStep(2); // Move to the second step
  }, [isEmailValid]);

  const onSubmitSecondForm = useCallback(async (e) => {
    e.preventDefault();
    const validation = secondFormValidatorRef.current.instance.validate();
    if (!validation.isValid) {
      return;
    }
    setCurrentStep(3); // Move to third step

  }, []);
  const onSubmitThirdForm = useCallback(async (e) => {
    e.preventDefault();
    const validation = thirdFormValidatorRef.current.instance.validate();
    if (!validation.isValid) {
      return;
    }
    setCurrentStep(4); // Move to third step

  }, []);

  useEffect(() => {
    const price = calculateTotalFee();
    console.log("price!!!", price)
    setTotalPrice(price);
  }, [selectedPlan, selectedDuration, additionalUserCost]);
  useEffect(() => {
    console.log("totalpricecganfge", totalPrice);
  }, [totalPrice]);

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();

    const price = totalPrice;

    if (user) {
      const userData = JSON.parse(localStorage.getItem('data'));
      const userID = userData.userID;
      const {
        companyName,
        countryID,
        fiscalStartYear,
        fiscalYearEnd,
        planID,
        licensedUser,
        subscriptionEndDate,
      } = formData.current;
      setLoading(true);
      try {
        const response = await baseapi.httppost('/api/UserCompany/index', {
          userID,
          companyName,
          countryID,
          fiscalStartYear,
          fiscalYearEnd,
          planID,
          licensedUser,
          subscriptionEndDate,
          totalPrice: price,
        });

        if (response.data.status) {
          const { companyID } = response.data;
          // Handle successful response
          Swal.fire({
            title: 'Success',
            text: 'Your company has been registered. You will be redirected to the new company page!',
            icon: 'success',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
          }).then(async (result) => {
            if (result.isConfirmed) {
              // Call the switch API with the captured companyID
              try {
                const switchResponse = await baseapi.httppost('/api/UserCompany/switch', { id: companyID });
                const { data } = switchResponse;
                if (data.status) {
                  const userProfile = await handleUserProfileAndAuthorization(data.access_token);
                  // Update user context with the fetched profile data
                  setUser({ ...userProfile, email: userProfile.LoginID });
                  navigate('/home');
                } else {
                  notify(switchResponse.data.message, 'error', 2000);
                }
              } catch (switchError) {
                console.error('Error occurred while switching company:', switchError.message);
                notify('An error occurred while switching company. Please try again.', 'error', 2000);
              }
            }
          });
        } else {
          notify(response.data.message, 'error', 2000);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error occurred while submitting:', error.message);
        notify('An error occurred. Please try again.', 'error', 2000);
        setLoading(false);
      }

    } else {
      const {
        email,
        userName,
        phoneNumber,
        password,
        companyName,
        countryID,
        fiscalStartYear,
        fiscalYearEnd,
        planID,
        licensedUser,
        subscriptionEndDate,

      } = formData.current;
      setLoading(true);
      try {
        const response = await baseapi.httppost('/api/register/index', {
          email,
          userName,
          phoneNumber,
          password,
          companyName,
          countryID,
          fiscalStartYear,
          fiscalYearEnd,
          planID,
          licensedUser,
          subscriptionEndDate,
          totalPrice: price,
        });

        if (response.data.status) {
          // Handle successful response
          Swal.fire({
            title: 'Success',
            text: 'Registration successful! Please check your email for verification.',
            icon: 'success',
            confirmButtonText: 'Yes',
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/login');
            }
          });
        } else {
          notify(response.data.message, 'error', 2000);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error occurred while submitting:', error.message);
        notify('An error occurred. Please try again.', 'error', 2000);
        setLoading(false);
      }
    }
  }, [totalPrice, signIn]);

  const passwordButton = useMemo(() => ({
    icon: 'images/eye-regular.svg',
    onClick: () => {
      setPasswordMode(prevPasswordMode => prevPasswordMode === 'text' ? 'password' : 'text');
    },
  }), []);

  const confirmPasswordButton = useMemo(() => ({
    icon: 'images/eye-regular.svg',
    onClick: () => {
      setConfirmPasswordMode(prevPasswordMode => prevPasswordMode === 'text' ? 'password' : 'text');
    },
  }), []);

  const passwordComparison = useCallback(() => formData.current.password, []);
  const handleTermsClick = () => {
    setTermsVisible(true);
  };

  const handlePrivacyClick = () => {
    setPrivacyVisible(true);
  };

  const handleTermsClose = () => {
    setTermsVisible(false);
  };

  const handlePrivacyClose = () => {
    setPrivacyVisible(false);
  };
  const handlePlanClose = () => {
    setPlansVisible(false);
  };
  const asyncValidation = (params) => {
    return sendEmailRequest(params.value);
  };

  const sendEmailRequest = (email) => {
    return baseapi.httppost('/oauth/verifyEmail', { email })
      .then(response => {
        const isValid = response.data;
        setIsEmailValid(isValid);
        return isValid;
      })
      .catch(error => {
        console.error('Error verifying email:', error);
        setIsEmailValid(false);
        return false;
      });
  };

  useEffect(() => {
    if (user) {
      setCurrentStep(2);
    }
  }, [user]);
  return (
    <>
      {!user && (
        <img
          src={"/images/nav-logo.png"}
          alt={"Greenplus Accounting"}
          width={110}
          id='nav-logo'
          style={{ paddingTop: '15px' }}
        />
      )}

      <div className="auth-register-container">
        <div className="register-description">
          <div>
            <h1 className='title'>Register</h1>
            <p className='subtitle'>and start using Greenplus Accounting to boost your business!</p>
            <ul className="register-steps">
              <li className={currentStep === 1 ? 'active' : ''}>
                <div className="icon"><i className="fas fa-user"></i></div>
                <div className="text">User Profile</div>
              </li>
              <li className={currentStep === 2 ? 'active' : ''}>
                <div className="icon"><i className="fas fa-users"></i></div>
                <div className="text">Basic Information Set Up</div>
              </li>

              <li className={currentStep === 3 ? 'active' : ''}>
                <div className="icon"><i className="fas fa-file"></i></div>
                <div className="text">Subscription Plan Details</div>
              </li>
              <li className={currentStep === 4 ? 'active' : ''}>
                <div className="icon"><i className="fas fa-calculator"></i></div>
                <div className="text">Review before payment</div>
              </li>
            </ul>
          </div>
        </div>

        <div className='auth-section-container'>
          <div className="auth-content">
            <div className="auth-form" style={{ display: currentStep === 1 ? 'block' : 'none' }}>
              <ValidationGroup ref={firstFormValidatorRef}>
                <form onSubmit={onSubmitFirstForm}>
                  <div className={`auth-input-div ${focus.email ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-envelope"></i></div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.email}
                        onValueChanged={e => {
                          formData.current.email = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, email: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('email')}
                        onFocusOut={() => onBlur('email')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Email"
                        labelMode="outside"
                      >
                        <Validator>
                          <RequiredRule message="Email is required" />
                          <EmailRule message="Email is invalid" />
                          <AsyncRule
                            message="Email is already registered"
                            validationCallback={asyncValidation}
                          />
                        </Validator>
                      </TextBox>
                      <p style={{ marginTop: '10px', marginBlockStart: '5px' }}>
                        *This email will serve as the primary account for handling this subscription.
                      </p>
                    </div>
                  </div>

                  <div className={`auth-input-div ${focus.userName ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-user"></i></div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.userName}
                        onValueChanged={e => {
                          formData.current.userName = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, userName: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('userName')}
                        onFocusOut={() => onBlur('userName')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="User Name"
                        labelMode="outside"
                      >
                        <Validator>
                          <RequiredRule message="User Name is required" />
                          <PatternRule
                            pattern="^[^\d]+$"
                            message="User Name must not contain any digits"
                          />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div ${focus.phoneNumber ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-phone"></i></div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.phoneNumber}
                        onValueChanged={e => {
                          formData.current.phoneNumber = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, phoneNumber: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('phoneNumber')}
                        onFocusOut={() => onBlur('phoneNumber')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Phone Number (without -)"
                        labelMode="outside"
                      >
                        <Validator>
                          <RequiredRule message="Phone Number is required" />
                          <PatternRule
                            pattern="^\d{10,11}$"
                            message="Phone Number must be contains only contain only numbers and 10 or 11 digits"
                          />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div ${focus.password ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-lock"></i></div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.password}
                        onValueChanged={e => {
                          formData.current.password = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, password: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('password')}
                        onFocusOut={() => onBlur('password')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Password"
                        labelMode="outside"
                        mode={passwordMode}
                      >
                        <TextBoxButton
                          name="password"
                          location="after"
                          options={passwordButton}
                        />
                        <Validator>
                          <RequiredRule message="Password is required" />
                          <PatternRule
                            message="Must contain at least 8 characters, one number, and one letter"
                            pattern={/^(?=.*[a-zA-Z])(?=.*\d).{8,}$/}
                          />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div ${focus.confirmPassword ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-lock"></i></div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.confirmPassword}
                        onValueChanged={e => {
                          formData.current.confirmPassword = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, confirmPassword: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('confirmPassword')}
                        onFocusOut={() => onBlur('confirmPassword')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Confirm Password"
                        labelMode="outside"
                        mode={confirmPasswordMode}
                      >
                        <TextBoxButton
                          name="password"
                          location="after"
                          options={confirmPasswordButton}
                        />
                        <Validator>
                          <RequiredRule message="Confirm Password is required" />
                          <CompareRule
                            message="Password and Confirm Password do not match"
                            comparisonTarget={passwordComparison}
                          />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <input type="submit" className="auth-button" value="Next" />

                </form>
              </ValidationGroup>
            </div>

            <div className="auth-form" style={{ display: currentStep === 2 ? 'block' : 'none' }}>
              <ValidationGroup ref={secondFormValidatorRef}>
                <form onSubmit={onSubmitSecondForm}>
                  <div className={`auth-input-div custom-register ${focus.companyName ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-building"></i></div>
                    <div className="auth-div">
                      <TextBox
                        value={formData.current.companyName}
                        onValueChanged={e => {
                          formData.current.companyName = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, companyName: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('companyName')}
                        onFocusOut={() => onBlur('companyName')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Company Name"
                        labelMode="outside"
                      >
                        <Validator>
                          <RequiredRule message="Company Name is required" />
                        </Validator>
                      </TextBox>
                    </div>
                  </div>

                  <div className={`auth-input-div custom-register  ${focus.countryID ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-flag"></i></div>
                    <div className="auth-div">
                      <div className="dropdown">
                        <SelectBox
                          dataSource={dropDownList.countryList}
                          value={defaultCountry}
                          onValueChanged={e => {
                            formData.current.countryID = e.value;
                            setFocus(prevFocus => ({ ...prevFocus, countryID: !!e.value }));
                          }}
                          alignment="left"
                          width={"auto"}
                          displayExpr={"Name"}
                          valueExpr={"id"}
                          label="Country"
                          labelMode="outside"
                          readOnly="true"
                        >
                          <Validator>
                            <RequiredRule message="Country is required" />
                          </Validator>
                        </SelectBox>


                      </div>
                    </div>
                  </div>
                  <div className={`auth-input-div custom-register  ${focus.fiscalStartYear ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-calendar-alt"></i></div>
                    <div className="auth-div">
                      <DateBox
                        value={formData.current.fiscalStartYear}
                        onValueChanged={e => {
                          formData.current.fiscalStartYear = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, fiscalStartYear: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('fiscalStartYear')}
                        onFocusOut={() => onBlur('fiscalStartYear')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Fiscal Start Year"
                        labelMode="outside"
                        type="date"
                        displayFormat="dd/MM/yyyy"
                      >
                        <Validator>
                          <RequiredRule message="Fiscal Start Year is required" />
                          <CustomRule
                            message="Fiscal Start Year cannot be in the future"
                            validationCallback={e => {
                              // Ensure the value is a Date object before comparison
                              const selectedDate = new Date(e.value);
                              const today = new Date();
                              // Set the time of 'today' to 00:00:00 to only compare the date part
                              today.setHours(0, 0, 0, 0);
                              return selectedDate <= today;
                            }}
                          />  </Validator>
                      </DateBox>
                    </div>
                  </div>

                  <div className={`auth-input-div custom-register  ${focus.fiscalYearEnd ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-calendar-alt"></i></div>
                    <div className="auth-div">
                      <DateBox
                        value={formData.current.fiscalYearEnd}
                        onValueChanged={e => {
                          formData.current.fiscalYearEnd = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, fiscalYearEnd: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('fiscalYearEnd')}
                        onFocusOut={() => onBlur('fiscalYearEnd')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Fiscal End Year"
                        labelMode="outside"
                        type="date"
                        displayFormat="dd/MM/yyyy"
                      >
                        <Validator>
                          <RequiredRule message="Fiscal End Year is required" />
                          <CustomRule
                            message="Fiscal End Year must be greater than Fiscal Start Year"
                            validationCallback={e => {
                              // Ensure the value is a Date object before comparison
                              const startYear = new Date(formData.current.fiscalStartYear);
                              const endYear = new Date(e.value);
                              return endYear > startYear;
                            }}
                          />
                        </Validator>
                      </DateBox>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div className="auth-buttons-container">
                    <button type="button" className="auth-background-white-button" onClick={() => user ? navigate(-1) : setCurrentStep(1)}>Back</button>
                    <input type="submit" className="auth-background-green-button" value="Next" />
                  </div>
                </form>
              </ValidationGroup>
            </div>
            <div className="auth-form" style={{ display: currentStep === 3 ? 'block' : 'none' }}>
              <ValidationGroup ref={thirdFormValidatorRef}>
                <form onSubmit={onSubmitThirdForm}>
                  <div className={`auth-input-div custom-register  ${focus.planID ? 'focus' : ''}`} >
                    <div className="auth-i"><i class="fas fa-clipboard-list"></i></div>
                    <div className="auth-div">
                      <div className="dropdown">
                        <SelectBox
                          dataSource={dropDownList.planList}
                          onValueChanged={handlePlanChange}
                          alignment="left"
                          width={"auto"}
                          displayExpr={"Name"}
                          valueExpr={"id"}
                          label="Plan"
                          labelMode="outside"
                        >
                          <Validator>
                            <RequiredRule message="Plan is required" />
                          </Validator>
                        </SelectBox>
                      </div>

                    </div>
                    <i
                      className="fas fa-question-circle"
                      style={{ marginLeft: '37px', marginTop: '10px', color: '#686868', cursor: 'pointer' }}
                      onClick={() => setPlansVisible(true)}
                    ></i>
                  </div>

                  <div className={`auth-input-div custom-register focus`}>
                    <div className="auth-i"><i className="fas fa-user"></i></div>
                    <div className="auth-div">

                      <TextBox
                        value={selectedPlan?.DefaultLicensedUser || 0}
                        onFocusIn={() => onFocus('licensedUser')}
                        onFocusOut={() => onBlur('licensedUser')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="No of Users(s)"
                        labelMode="outside"
                        readOnly={true}
                      >
                      </TextBox>
                    </div>

                  </div>

                  <div className={`auth-input-div custom-register ${focus.licensedUser ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-user"></i></div>
                    <div className="auth-div">

                      <NumberBox
                        value={formData.current.licensedUser || 0}
                        min={0}
                        showSpinButtons={true}
                        onValueChanged={e => {
                          formData.current.licensedUser = e.value;
                          setFocus(prevFocus => ({ ...prevFocus, licensedUser: !!e.value }));
                        }}
                        onFocusIn={() => onFocus('licensedUser')}
                        onFocusOut={() => onBlur('licensedUser')}
                        className="auth-input"
                        stylingMode="underlined"
                        label="Add-On User"
                        labelMode="outside"
                      />
                    </div>
                  </div>


                  <div className={`auth-input-div  ${focus.subscriptionEndDate ? 'focus' : ''}`}>
                    <div className="auth-i"><i className="fas fa-calendar-alt"></i></div>
                    <div className="auth-div">
                      <div className="dropdown">
                        <SelectBox
                          dataSource={dropDownList.planDurationList}
                          onValueChanged={e => {
                            formData.current.subscriptionEndDate = e.value;
                            setFocus(prevFocus => ({ ...prevFocus, subscriptionEndDate: !!e.value }));
                          }}
                          alignment="left"
                          width={"auto"}
                          displayExpr={"Name"}
                          valueExpr={"Duration"}
                          label="Duration"
                          labelMode="outside"
                        >
                          <Validator>
                            <RequiredRule message="Duration is required" />
                          </Validator>
                        </SelectBox>
                        <p style={{ marginTop: '10px' }}>
                          *Please be aware that the subscription plan duration begins after the free trial period ends.
                        </p>
                      </div>
                    </div>
                  </div>

                  <br /> <br />
                  <div className="auth-buttons-container">
                    <button type="button" className="auth-background-white-button" onClick={() => setCurrentStep(2)}>Back</button>
                    <input type="submit" className="auth-background-green-button" value="Next" />
                  </div>
                </form>
              </ValidationGroup>
            </div>
            <div className="auth-form" style={{ display: currentStep === 4 ? 'block' : 'none' }} >
              <form onSubmit={onSubmit}>

                <p className='review-subtitle'>
                  Please review details below before proceed to payment.
                </p>

                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    Company Name
                  </div>
                  <div className="auth-input-value">
                    {formData.current?.companyName}
                  </div>
                </div>

                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    User Provided by Plan
                  </div>
                  <div className="auth-input-value">
                    {Number(selectedPlan?.DefaultLicensedUser)}
                  </div>
                </div>
                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    Duration
                  </div>
                  <div className="auth-input-value">
                    {selectedDuration?.Name}
                  </div>
                </div>
                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    Add On User
                  </div>
                  <div className="auth-input-value">
                    {formData.current?.licensedUser} - RM {additionalUserCost}
                  </div>
                </div>



                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    Subscription Plan
                  </div>
                  <div className="auth-input-value">
                    {selectedPlan?.Name} - RM {selectedPlan?.Fee}
                  </div>
                </div>

                <div className="auth-flex" style={{ marginTop: '20px' }}>
                  <div className="auth-label">
                    Total Fee
                  </div>
                  <div className="auth-input-value">
                    RM {totalPrice}
                  </div>
                </div>
                <p className='label-sign-up' style={{ marginTop: '60px', marginBottom: '50px' }}>
                  *By signing up, you agree to our&nbsp;
                  <span style={{ color: '#46CF64', textDecoration: 'underline', cursor: 'pointer' }} onClick={handleTermsClick}>Terms of Use</span>
                  &nbsp;and acknowledge you've read our&nbsp;
                  <span style={{ color: '#46CF64', textDecoration: 'underline', cursor: 'pointer' }} onClick={handlePrivacyClick}>Privacy Policy</span>.
                </p>

                {loading && (
                  <div className="loading-indicator-container">
                    <div className="loader"></div>
                    <br />
                  </div>
                )}
                {!loading &&
                  <div className="auth-buttons-container">
                    <button type="button" className="auth-background-white-button" onClick={() => {
                      setCurrentStep(3);

                    }}>Back</button>

                    <input type="submit" className="auth-background-green-button" value="Make Payment" />

                  </div>
                }
              </form>
            </div>
          </div>

          {!user && (
            <div className="auth-footer">
              <label htmlFor="rememberMe" className="auth-footer-label">Already have an account?</label>
              <Link to="/login">Login</Link>
            </div>
          )}
        </div>
      </div>

      <img className="wave" src="images/wave.svg" alt="wave" />

      <Popup
        width={popupWidth.current}
        height={'700px'}
        visible={termsDialogVisible}
        onHiding={handleTermsClose}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title='Terms of Use'
      >
        <div style={{ padding: '10px', overflowY: 'auto' }}>

          <p>
            Welcome to Greenplus Accounting. By using our services, you agree to the following terms and conditions:
          </p>
          <h3 style={{ paddingTop: '10px' }}>1. Acceptance of Terms</h3>
          <p>
            By accessing and using our services, you accept and agree to be bound by the terms and provisions of this agreement.
            In addition, when using these particular services, you shall be subject to any posted guidelines or rules applicable
            to such services.
          </p>
          <h3 style={{ paddingTop: '10px' }}>2. Services</h3>
          <p>
            Our services include, but are not limited to, accounting and bookkeeping services, financial management tools, and
            other related services. We reserve the right to modify or discontinue any part of our services at any time.
          </p>
          <h3 style={{ paddingTop: '10px' }}>3. User Responsibilities</h3>
          <p>
            You are responsible for maintaining the confidentiality of your account and password and for restricting access to
            your computer. You agree to accept responsibility for all activities that occur under your account or password.
          </p>
          <h3 style={{ paddingTop: '10px' }}>4. Privacy Policy</h3>
          <p>
            Your use of our services is also subject to our Privacy Policy. Please review our Privacy Policy to understand our
            practices.
          </p>
          <h3 style={{ paddingTop: '10px' }}>5. Intellectual Property</h3>
          <p>
            All content included on this site, such as text, graphics, logos, icons, images, and software, is the property of
            Greenplus Accounting or its content suppliers and is protected by international copyright laws.
          </p>

          <h3 style={{ paddingTop: '10px' }}>6. Contact Information</h3>
          <p>
            If you have any questions about these Terms of Use, please contact us at
            admin@greenstem.com.my.
          </p>
        </div>
      </Popup>


      <Popup
        width={popupWidth.current}
        height={'52%'}
        visible={privacyDialogVisible}
        onHiding={handlePrivacyClose}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title='Privacy Policy'
      >
        <div style={{ padding: '10px', overflowY: 'auto' }}>

          <p>
            At Greenplus Accounting, we are committed to protecting your privacy. This Privacy Policy explains how we collect,
            use, disclose, and safeguard your information when you use our services.
          </p>
          <h3 style={{ paddingTop: '10px' }}>1. Information We Collect</h3>
          <p>
            We may collect personal identification information from Users in a variety of ways, including, but not limited to,
            when Users visit our site, register on the site, and in connection with other activities, services, features, or
            resources we make available on our site.
          </p>
          <h3 style={{ paddingTop: '10px' }}>2. How We Use Collected Information</h3>
          <p>
            Greenplus Accounting may collect and use Users' personal information for the following purposes:
          </p>
          <ul>

          </ul>
        </div>
      </Popup>
      <Popup
        width={'90%'}
        height={'80%'}
        visible={plansVisible}
        onHiding={handlePlanClose}
        hideOnOutsideClick={true}
        showCloseButton={true}
        showTitle={true}
        title='Plans Offered'
      >
        <ScrollView

          width='100%'
          height={'100%'}
          onUpdated={(e) => {

            setScrollViewHeight(utils.GetScrollViewHeightV2());
            // console.log("on updated trigger", utils.GetScrollViewHeightV2())

          }}>
          <div className="pricing-section">

            <div className="pricing-plans">
              <div className="plan selected">
                <h3>Basic</h3>
                <p className="price">
                  <span className="price-amount">RM50</span> /month
                </p>
                <p>Essential features for startups and small businesses.</p>
                <hr className="hr-custom" />
                <ul className="tick-list">
                  <li>Free Trial</li>
                  <li>All in Essential & Plus</li>
                  <li>Lifetime access for one connection</li>
                  <li>Early access to the product</li>
                  <li>Fully refundable</li>
                </ul>

              </div>
              <div className="plan selected">
                <h3>Essential</h3>
                <p className="price">
                  <span className="price-amount">RM100</span> /month
                </p>
                <p>Advanced features for growing businesses.</p>
                <hr className="hr-custom" />
                <ul className="tick-list">
                  <li>Free Trial</li>
                  <li>Field mapping customization</li>
                  <li>Eventbrite embedded checkout</li>
                  <li>Automated event updates</li>
                  <li>Real-time data synchronization</li>
                </ul>

              </div>
              <div className="plan selected">
                <h3>Plus</h3>
                <p className="price">
                  <span className="price-amount">RM200</span> /month
                </p>
                <p>Comprehensive features for large businesses.</p>
                <hr className="hr-custom" />
                <ul className="tick-list">
                  <li>Free Trial</li>
                  <li>All in Essential</li>
                  <li>Recurring events referencing</li>
                  <li>Multiple collection capability</li>
                  <li>Event series functionality</li>
                </ul>

              </div>
            </div>
          </div>
        </ScrollView>
      </Popup>
    </>


  );
}
