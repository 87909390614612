import React from "react";
import baseapi from "../api/baseapi";
import CustomStore from "devextreme/data/custom_store";
import util from "../utils/common";

const userID = JSON.parse(localStorage.getItem("data"));

function getDataStore(url) {
	// console.log(userID.userID)
	const data = {
		store: new CustomStore({
			key: "id",
			load: () => {
				return baseapi
					.httpget(url)
					.then((response) => {
						return {
							data: response.data
						};
					})
					.catch(() => {
						throw "Network error";
					});
			},
			byKey: () => {}
		}),
		sort: "label",
		url: url,
		search: (query) => {
			return baseapi
				.httpget(url, { q: query, getAll: false, singleReturn: true })
				.then((response) => {
					return {
						data: response.data
					};
				})
				.catch(() => {
					throw "Network error";
				});
		},
		loadEdit: (query) => {
			return baseapi
				.httpget(url, { q: query, getAll: false, singleReturn: false })
				.then((response) => {
					return {
						data: response.data
					};
				})
				.catch(() => {
					throw "Network error";
				});
		},
		getAll: () => {
			return baseapi
				.httpget(url, { getAll: true })
				.then((response) => {
					return {
						data: response.data
					};
				})
				.catch(() => {
					throw "Network error";
				});
		}
	};
	return data;
}

const departmentOptions = getDataStore("api/Utilities/GetDepartments");
const projectOptions = getDataStore("api/Utilities/GetProjects");
const controlTypeOptions = getDataStore("api/Utilities/GetControlTypes");
const accountTypeOptions = getDataStore("api/Utilities/GetAccountTypes");
const chartTypeOptions = getDataStore("api/Utilities/GetChartTypes");
const statementTypeOptions = getDataStore("api/Utilities/GetStatementTypes");
const statementTypeDebtorOptions = getDataStore("api/Utilities/GetStatementTypesDebtor");
const taxCodeOptions = getDataStore("api/Utilities/GetTaxCodes");
const forexOptions = getDataStore("api/Utilities/GetForexs");
const customerOptions = getDataStore("api/Utilities/GetCustomers");
const customerEmailOptions = getDataStore("api/Utilities/GetCustomersEmail");
const salesmanOptions = getDataStore("api/Utilities/GetSalesmans");
const stateOptions = getDataStore("api/Utilities/GetStates");
const countryOptions = getDataStore("api/Utilities/GetCountries");
const greenplusCountryOptions = getDataStore("api/register/GetGreenPlusCountries");
const greenplusPlanOptions = getDataStore("api/register/GetGreenPlusPlans");
const industryCodeOptions = getDataStore("api/Utilities/GetCompanyBusinessCode");
const areaOptions = getDataStore("api/Utilities/GetAreas");
const uomOptions = getDataStore("api/Utilities/GetUOMs");
const categoryOptions = getDataStore("api/Utilities/GetItemCategories");
const brandOptions = getDataStore("api/Utilities/GetBrands");
const itemTypeOptions = getDataStore("api/Utilities/GetItemTypes");
const materialOptions = getDataStore("api/Utilities/GetMaterials");
const stockGroupOptions = getDataStore("api/Utilities/GetStockGroups");
const stockClassOptions = getDataStore("api/Utilities/GetStockClasses");
const journalTypeOptions = getDataStore("api/Utilities/GetJournalTypes");
const transactionTypeOptions = getDataStore("api/Utilities/GetTransactionTypes");
const glChartAccountOptions = getDataStore("api/Utilities/GetGLChartAccounts");
const salesTypeOptions = getDataStore("api/Utilities/GetSalesType");
const stockOptions = getDataStore("api/Utilities/GetStock");
const userRoleOptions = getDataStore("api/Utilities/GetUserRoles");
const userOptions = getDataStore("api/Utilities/GetUsers");
const gstTranTypeOptions = getDataStore("api/Utilities/GetGSTTransactionType");
const taxTypeOptions = getDataStore("api/Utilities/GetTaxType");
const gstRateOptions = getDataStore("api/Utilities/GetGSTRate");
const locationOptions = getDataStore("api/Utilities/GetLocations");
const supplierOptions = getDataStore("api/Utilities/GetSuppliers");
const supplierTypeOptions = getDataStore("api/Utilities/GetSupplierTypes");
const chartAccountPayment = getDataStore("api/Utilities/GetCashSalesPaymentMethod");
const creditTermOptions = getDataStore("api/Utilities/GetCreditTerms");
const GetGLBKAndCSAccount = getDataStore("api/Utilities/GetGLBKAndCSAccount");
const workshopModel = getDataStore("api/Utilities/GetItemModels");
const workshopTypeOfService = getDataStore("api/Utilities/GetWKTypeOfService");
const workshopServiceAdvisor = getDataStore("api/Utilities/GetWKServiceAdvisor");
const workshopMechanic = getDataStore("api/Utilities/GetWKMechanic");
const workshopRefInv = getDataStore("api/Utilities/GetWKRefInv");
const workshopRegistration = getDataStore("api/Utilities/GetWKRegistration");
const workshopJobSheet = getDataStore("api/Utilities/GetWKJobsheet");
const workshopUsedInvoice = getDataStore("api/Utilities/GetUsedWKInvoiceFromJobsheet");
const scTranTypeOptions = getDataStore("api/Utilities/GetSCTransactionType");
const debtorChartAccount = getDataStore("api/Utilities/GetGLDebtorAccount");
const creditorChartAccount = getDataStore("api/Utilities/GetGLCreditorAccounts");
const planOptions = getDataStore("api/Utilities/GetPlans");

// Reports
// AR
const creditNoteReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=4&formName=AR Credit Note Report&formType=AR Credit Note&userID=" + userID?.userID); // AR Credit Note
const debitNoteReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=4&formName=AR Debit Note Report&formType=AR Debit Note&userID=" + userID?.userID); // AR Debit Note
const receiptReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=4&formName=Receipt Report&formType=Receipt&userID=" + userID?.userID); // Official Receipt
const arRefundReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=4&formName=AR Refund Report&formType=AR Refund&userID=" + userID?.userID); // AR Refund
const salesReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=4&formName=Sales Report&formType=Sales&userID=" + userID?.userID); // Invoice Sales
const arContra = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=4&formName=AR Contra Report&formType=AR Contra&userID=" + userID?.userID); // AR Contra

// AP
const apcreditNoteReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=3&formName=AP Credit Note Report&formType=AP Credit Note&userID=" + userID?.userID); // AP Credit Note
const apdebitNoteReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=3&formName=AP Debit Note Report&formType=AP Debit Note&userID=" + userID?.userID); // AP Debit Note
const paymentReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=3&formName=Payment Report&formType=Payment&userID=" + userID?.userID); // Payment
const purchaseReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=3&formName=Purchase Report&formType=Purchase&userID=" + userID?.userID); // Purchase
const apContra = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=3&formName=AP Contra Report&formType=AP Contra&userID=" + userID?.userID); // AP Contra
const apRefundReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=3&formName=AP Refund Report&formType=AP Refund&userID=" + userID?.userID); // AP Refund

// WK
const wkInvoiceReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=11&formName=WK Invoice Report&formType=WK Invoice&userID=" + userID?.userID); // WK Invoice
const wkJobbsheetReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=11&formName=WK Jobsheet Report&formType=WK Jobsheet&userID=" + userID?.userID); // WK Jobsheet

// GL
const glPaymentVoucher = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=5&formName=Cash Book PV Report&formType=Cash Book PV&userID=" + userID?.userID); // GL Payment Voucher
const glOfficialReceipt = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=5&formName=Cash Book OR Report&formType=Cash Book OR&userID=" + userID?.userID); // GL Official Receipt
const glJournalVoucher = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=5&formName=Journal Voucher Report&formType=Journal&userID=" + userID?.userID); // GL Journal Voucher

// SALES
const cashSalesReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=13&formName=Cash Sales Invoice Report&formType=Cash Sales&userID=" + userID?.userID); // Cash Sales
const invoiceReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=13&formName=Invoice Report&formType=Invoice&userID=" + userID?.userID); // Invoices
const salesDeliveryReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=13&formName=Sales Delivery Order Report&formType=Sales Delivery Order&userID=" + userID?.userID); // Sales Delivery Order
const salesCreditNoteReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=13&formName=Sales Credit Note Report&formType=Sales Credit Note&userID=" + userID?.userID); // Sales Credit Note
const salesDebitNoteReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=13&formName=Sales Debit Note Report&formType=Sales Debit Note&userID=" + userID?.userID); // Sales Debit Note
const salesOrderReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=13&formName=Sales Order Report&formType=Sales Order&userID=" + userID?.userID); // Sales Order
const salesOrderAdjustmentReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=13&formName=Sales Order Adjustment Report&formType=Sales Order Adjustment&userID=" + userID?.userID); // Sales Order Adjustment
const salesQuotationReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=13&formName=Sales Quotation Report&formType=Sales Quotation&userID=" + userID?.userID); // Sales Quotation

// PURCHASE
const purchaseOrderAdjustmentReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Order Adjustment Report&formType=Purchase Order Adjustment&userID=" + userID?.userID); // Purchase Order Adjustment
const purchaseInvoiceReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Invoice Report&formType=Purchase Invoice&userID=" + userID?.userID); // Purchase Credit Note
const purchaseDebitNoteReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Debit Note Report&formType=Purchase Debit Note&userID=" + userID?.userID); // Purchase Debit Note
const purchaseOrderReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Order Report&formType=Purchase Order&userID=" + userID?.userID); // Purchase Order
const purchaseQuotationReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Quotation Report&formType=Purchase Quotation&userID=" + userID?.userID); // Purchase Quotation
const purchaseReceivedReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Received Report&formType=Purchase Received&userID=" + userID?.userID); // Purchase Received
const purchaseCashPurchaseReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Cash Invoice Report&formType=Cash Purchase&userID=" + userID?.userID); // Purchase Cash Purchase
const purchaseRequestReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Request Report&formType=Purchase Request&userID=" + userID?.userID); // Purchase Request
const purchaseReturnReportNames = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=14&formName=Purchase Return Report&formType=Purchase Return&userID=" + userID?.userID); // Purchase Return

// REPORT LISTING
const statementListing = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByReportName?reportName=Customer Statement&userID=" + userID?.userID);
const customerBalance = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=4&formName=Balance Listing&formType=Customer&userID=" + userID?.userID);
const supplierBalance = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=3&formName=Balance Listing&formType=Supplier&userID=" + userID?.userID);
const customerAgeing = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=4&formName=Ageing Listing&formType=Customer&userID=" + userID?.userID);
const supplierAgeing = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=3&formName=Ageing Listing&formType=Supplier&userID=" + userID?.userID);
const apstatementListing = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByReportName?reportName=Supplier Statement&userID=" + userID?.userID);
const balanceSheetListing = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=5&formName=GL Balance Sheet Report&formType=GL Balance Sheet&userID=" + userID?.userID);
const profitLossListing = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=5&formName=GL Profit Loss Statement Report&formType=GL Profit Loss&userID=" + userID?.userID);
const trialBalanceListing = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByFormType?moduleID=5&formName=GL Trial Balance Report&formType=GL Trial Balance&userID=" + userID?.userID);
const ledgerListing = getDataStore("api/Utilities/GetLedgerReports");
const creditListing = getDataStore(util.ReportURL + "/api/Report/GetRelatedReportsByReportName?reportName=Credit&userID=" + userID?.userID);

export {
	departmentOptions,
	controlTypeOptions,
	accountTypeOptions,
	chartTypeOptions,
	statementTypeOptions,
	statementTypeDebtorOptions,
	taxCodeOptions,
	forexOptions,
	customerOptions,
	salesmanOptions,
	stateOptions,
	countryOptions,
	planOptions,
	industryCodeOptions,
	areaOptions,
	uomOptions,
	greenplusCountryOptions,
	greenplusPlanOptions,
	categoryOptions,
	brandOptions,
	scTranTypeOptions,
	itemTypeOptions,
	materialOptions,
	stockGroupOptions,
	stockClassOptions,
	journalTypeOptions,
	transactionTypeOptions,
	salesTypeOptions,
	stockOptions,
	userOptions,
	gstTranTypeOptions,
	taxTypeOptions,
	gstRateOptions,
	locationOptions,
	supplierOptions,
	supplierTypeOptions,
	getDataStore,
	userRoleOptions,
	chartAccountPayment,
	invoiceReportNames,
	debitNoteReportNames,
	creditNoteReportNames,
	apcreditNoteReportNames,
	apdebitNoteReportNames,
	receiptReportNames,
	arRefundReportNames,
	apRefundReportNames,
	paymentReportNames,
	salesReportNames,
	purchaseReportNames,
	cashSalesReportNames,
	wkInvoiceReportNames,
	wkJobbsheetReportNames,
	customerEmailOptions,
	workshopModel,
	workshopTypeOfService,
	workshopServiceAdvisor,
	workshopMechanic,
	workshopRefInv,
	workshopRegistration,
	workshopJobSheet,
	workshopUsedInvoice,
	statementListing,
	apstatementListing,
	balanceSheetListing,
	profitLossListing,
	trialBalanceListing,
	ledgerListing,
	creditListing,
	glChartAccountOptions,
	projectOptions,
	glPaymentVoucher,
	glOfficialReceipt,
	glJournalVoucher,
	arContra,
	apContra,
	creditTermOptions,
	supplierBalance,
	customerBalance,
	customerAgeing,
	supplierAgeing,
	debtorChartAccount,
	creditorChartAccount,
	salesDeliveryReportNames,
	salesCreditNoteReportNames,
	salesDebitNoteReportNames,
	salesOrderReportNames,
	salesQuotationReportNames,
	GetGLBKAndCSAccount,
	salesOrderAdjustmentReportNames,
	purchaseOrderAdjustmentReportNames,
	purchaseInvoiceReportNames,
	purchaseDebitNoteReportNames,
	purchaseOrderReportNames,
	purchaseQuotationReportNames,
	purchaseReceivedReportNames,
	purchaseCashPurchaseReportNames,
	purchaseRequestReportNames,
	purchaseReturnReportNames
};
