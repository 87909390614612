import { useEffect, useRef, useState, useCallback } from "react";
import utils from "../../../utils/common";
import PopupForm from "../../../components/popup-form/PopupFormV2";
import baseapi from "../../../api/baseapi";
import { loading, closeLoading } from "../../../utils/common";
import { NumberBox } from "devextreme-react/number-box";
import { CheckBox } from "devextreme-react/check-box";
import { DateBox } from "devextreme-react/date-box";
import { TextBox } from "devextreme-react/text-box";
import { TextArea } from "devextreme-react";
import { Column, Summary, TotalItem } from "devextreme-react/data-grid";
import CustomizedLookup from "../../../components/lookup/Lookup";
import CustomizedGridLookup from "../../../components/lookup/GridLookup";
import ChildrenDatagrid from "../../../components/children-datagrid/ChildrenDatagrid";
import { moduleCalculation, ParentValueCalculation } from "../../../utils/module-calculation";
import { Validator, RequiredRule as ValidatorRequired } from "devextreme-react/validator";
import AsyncTextBox from "../../../components/async-text-box/AsyncTextBox";
import { uomOptions } from "../../../utils/lookupstore";
import StockNegativeValueForm from "../../../components/popup-form/StockNegativeValueForm";
export default function StockIssueFrom(props) {
	const tempForm = useRef(null);
	const formID = useRef(null);
	const childrenGridRef = useRef(null);
	const formRead = useRef(false);
	const formRef = useRef(null);
	const [shelfList, setShelfList] = useState([]);
	const [locationList, setLocationList] = useState([]);
	const [isEditing, setIsEditing] = useState(false);
	const [startValidation, setStartValidation] = useState(0);
	const [formValue, setFormValue] = useState({});
	const [taxCodeList, setTaxCodeList] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const [uomList, setUomList] = useState([]);
	const [stockList, setStockList] = useState([]);
	const [forexList, setForexList] = useState([]);
	const [gridLookupOpen, setGridLookupOpen] = useState(false);
	const [nagativeValueChildren, setNagativeValueChildren] = useState([]);
	const GridValidatedColumns = ["StockID"];
	const forexLookupRef = useRef(null);
	const GridRequiredColumns = ["StockID"];

	const [defaultLocation, setDefaultLocation] = useState({ id: '', code: '' });
	const [displayStockNegativeValue, setDisplayStockNegativeValue] = useState(false);

	useEffect(() => {
		// console.log("Form ID Changed", props.formID);
		if (props.formID !== null) {
			formID.current = props.formID;
			utils.popupFormOpen(formRef);

			if (formID.current !== "new") {
				setIsEditing(true);
			}
			else {
				setIsEditing(false);
			}

			if (utils.isEmptyArray(uomList)) {
				uomOptions.getAll().then((list) => {
					setUomList(list.data);
				});
			}
		}
	}, [props.formID]);

	function onInitialized(e) {
		if (formID.current === "new") loading("Loading New Stock Issue...");
		else loading("Loading Existing Stock Issue...");

		// Fetch the default location as soon as the component is initialized
		baseapi.httpget('/api/Utilities/GetDefaultLocation').then((locationResponse) => {
			if (locationResponse.status === 200) {
				const locationData = locationResponse.data;
				setDefaultLocation({ id: locationData.id, code: locationData.code }); // Set the default location state
			} else {
				console.error('Default location not found');
				// Handle the case where there is no default location
			}
		});

		baseapi.httpget("/api/StockIssue/Get", { id: formID.current || "new" }).then((response) => {
			const { data } = response;

			if (formID.current === "new") {
				const copiedParent = { ...data.model.Parent };

				tempForm.current = copiedParent;
				setFormValue(copiedParent);
				console.log(copiedParent, "copiedParent")
				utils.childrenGridAddRow(childrenGridRef); //Add row for the frist grid
			}
			else {
				utils.childrenGridSetSource(childrenGridRef, data.model.Children);
				tempForm.current = data.model.Parent;
				setFormValue(tempForm.current);
			}

			closeLoading();
			formRead.current = true;
		});
	}

	const onInitNew = () => {
		return {
			Line: utils.childrenGridLength(childrenGridRef) + 1,
			BankInDate: new Date(),
			NettAmount: 0,
			NettLocalAmount: 0,
			BankCharges: 0,
			ProjectID: "53OsK3yBsKs%3d",
			ProjectCode: "P0",
			LocationID: defaultLocation.id,
			LocationCode: defaultLocation.code,
			DetailLN: 0,
			Point: 0,
			Quantity: 0
		};
	};

	const onRowUpdating = (e) => {

		if (e.newData.Quantity < 0) {
			e.newData.Quantity = 0; // Set the Quantity to 0 if a negative value is entered
			utils.popupFormSetErrorForm(formRef, {
				visible: true,
				message: "Negative values for stock quantity are not allowed",
				type: "Warning"
			});
		}

	};
	function calculateTotal(gridData) {
		return gridData.reduce((acc, row) => acc + (row.Quantity * row.UnitCost), 0);
	}
	const onRowUpdated = (e, children = null) => {
		const childrenDetails = children !== null ? children : utils.childrenGridGetSource(childrenGridRef);
		var totalLocal = 0;

		if (Array.isArray(childrenDetails)) {
			for (var i = 0; i < childrenDetails.length; i++) {
				const row = childrenDetails[i];

				//Reset the line
				if (!utils.isNullOrEmpty(row["Line"])) {
					row["Line"] = i + 1;
				}

				if (!utils.isNullOrEmpty(row["Quantity"])) {
					totalLocal = utils.add(totalLocal, row["Quantity"] * row["UnitCost"]);
					//Calculate MainForexAmount
					formValue["MainLocalAmount"] = totalLocal;
					setFormValue(prevState => {
						return { ...prevState, MainLocalAmount: totalLocal };
					});
				}
			}
		}
	};

	const gridOnSelectionChanged = (e, columnName) => {
		setGridLookupOpen(false);
		const currentFocusCellIndex = utils.childrenGridGetFocusCellIndex(childrenGridRef);
		const currentRecords = utils.childrenGridGetSource(childrenGridRef);
		const rowIndex = currentFocusCellIndex.rowIndex;
		const key = currentFocusCellIndex.rowID;
		const selectedItem = e.selectedItem;
		console.log("selecteditem", selectedItem)
		const dataSource = e.dataSource;
		currentRecords[rowIndex][columnName] = e.value;

		if (columnName === "StockID") {
			currentRecords[rowIndex]["Description"] = selectedItem?.name;
			//currentRecords[rowIndex]["UnitPrice"] = selectedItem?.price1;
			currentRecords[rowIndex]["Description2"] = selectedItem?.description2;
			currentRecords[rowIndex]["MoreDescription"] = selectedItem?.moreDescription;
			const uomID = dataSource.find((stock) => stock.id === e.value)?.uomID;
			currentRecords[rowIndex]["UOM"] = uomList.find((uom) => uom.id === uomID)?.code;
			baseapi.httpget(`/api/Utilities/GetStockTotalQuantity?StockID=${e.value}`)
				.then((response) => {
					if (response.status === 200) {
						const stockData = response.data.length > 0 ? response.data[0] : { unitCost: 0.00 }; // Check if data is empty and set unitCost to 0 if it is
						currentRecords[rowIndex]["UnitCost"] = stockData.unitCost;
					} else {
						console.error('stock not found');
					}
				});
			const copiedArr = [...stockList];
			if (!utils.isInArray(stockList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setStockList(copiedArr);

		} else if (columnName === "TaxCodeID") {
			currentRecords[rowIndex]["TaxRate"] = selectedItem?.rate;
			const copiedArr = [...taxCodeList];
			if (!utils.isInArray(taxCodeList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setTaxCodeList(copiedArr);
		} else if (columnName === "ProjectID") {
			const copiedArr = [...projectList];
			if (!utils.isInArray(projectList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setProjectList(copiedArr);
		} else if (columnName === "ShelfID") {
			const copiedArr = [...shelfList];
			if (!utils.isInArray(shelfList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setShelfList(copiedArr);
			console.log("currentrecord")
			// Check if shelfList has items and set the ShelfID to the first item
			if (shelfList && shelfList.length > 0) {
				const firstShelfItem = shelfList[0];
				currentRecords[rowIndex]["ShelfID"] = firstShelfItem.id; // Assuming 'id' is the correct property
				// Update the dataSourceURL for the ShelfID lookup
				console.log("currentrecord", currentRecords[rowIndex]["ShelfID"])
				// Trigger the onSelectionChanged for ShelfID with the first shelf item
				//gridOnSelectionChanged({ selectedRowKeys: [firstShelfItem.id] }, "ShelfID");
			}
		} else if (columnName === "LocationID") {
			const copiedArr = [...locationList];
			if (!utils.isInArray(locationList, selectedItem?.id, "id")) copiedArr.push(selectedItem);
			setLocationList(copiedArr);
		}

		e["key"] = key;
		e["data"] = currentRecords[rowIndex];
		onRowUpdated(e, currentRecords);
	};



	function valueOnChange(e, TakeDataField = null) {
		// console.log("I am in value changed", e, "data field", e.element.getAttribute("dataField"))
		try {

			const dataField = TakeDataField !== null ? TakeDataField : e.element.getAttribute("dataField");
			const copied = { ...tempForm.current };
			copied[dataField] = e.value;
			tempForm.current = copied;
			setFormValue(tempForm.current);
			if (!utils.isNullOrEmpty(e.value)) {
				if (dataField === "ForexID" && formRead.current) {
					baseapi
						.httpget(`/api/Forex/Get`, { id: e.value })
						.then((response) => {
							const { data } = response;
							const copied = { ...tempForm.current };
							copied["LocalRate"] = data.model.LocalRate;
							copied["ForexRate"] = data.model.ForexRate;

							tempForm.current = copied;
							setFormValue(tempForm.current);
						})
						.catch((error) => {
							console.log(error);
						});
				}
			}
		} catch (err) {
			console.log(err);
		}
	}


	const clearForm = () => {
		props.closePopup(null);
		formRead.current = false;
		formID.current = null;
		tempForm.current = {};
		setFormValue(tempForm.current);
	};


	function handleSubmit(e) {
		if(!utils.isNullOrEmpty(e.validData)){
			const validData = e.validData;
			const submittedForm = {
				Parent: formValue,
				Children: validData["Children"]
			};
			loading("Saving Stock Issue...");

			// Make a single API call with all stock shelf parameters
			baseapi.httppost('/api/Utilities/GetStockShelves', getStockShelfParams(submittedForm.Children))
			.then((response) => {
				const stockData = response.data;
				const negativeValueChildren = findNegativeValueChildren(submittedForm.Children, stockData);

				// Proceed with form submission if no negative netqty is found
				if (negativeValueChildren.length === 0) {
					submitFormToApi(submittedForm);
				} else {
					closeLoading();
					handleNegativeValues(negativeValueChildren);
				}
			})
			.catch((error) => {
				console.error('Error fetching stock shelf data:', error);
				closeLoading();
			});
		}
	}

	function prepareSubmittedForm(childrenDetails) {
		const childrenValid = utils.checkGridValid(childrenDetails, GridValidatedColumns);
		const validChildren = childrenDetails.filter((child, index) => {
			const deletedIndex = childrenValid.brokenRules.find((row) => row.rowIndex === index);
			return deletedIndex === undefined;
		});

		return {
			Parent: formValue,
			Children: validChildren
		};
	}

	function getStockShelfParams(children) {
		return children.map(child => ({
			StockID: child.StockID,
			LocationID: child.LocationID,
			ShelfID: child.ShelfID
		}));
	}

	function findNegativeValueChildren(children, stockData) {
		const negativeValueChildren = [];

		children.forEach((child) => {
			const stockShelf = stockData.find(s =>
				s.StockID === child.StockID &&
				s.LocationID === child.LocationID &&
				(child.ShelfID === null || s.ShelfID === child.ShelfID)
			);

			if (stockShelf && stockShelf.qtyOnHand < 0) {
				negativeValueChildren.push({
					id: stockShelf.id,
					StockCode: stockShelf.stockCode,
					Description: stockShelf.description,
					LocationCode: stockShelf.locationCode,
					ShelfCode: stockShelf.shelfCode,
					QtyOnHand: stockShelf.qtyOnHand,
					MinLevel: stockShelf.minLevel,
					InOutQty: -child.Quantity,
					BalQty: utils.subtract(stockShelf.qtyOnHand, child.Quantity),
				});
			}
		});

		return negativeValueChildren;
	}

	function submitFormToApi(submittedForm) {
		baseapi.httppost(
			utils.extendUrlVar("/api/StockIssue/save", {
				id: formID.current || "",
				del: false,
			}),
			submittedForm
		)
			.then((response) => {
				const { data } = response;
				utils.popupFormSuccessfulSubmit(formRef, data);
				if (data.status) {
					//setFormValue({});
					if (typeof props.onSubmitSuccess === 'function') {
						props.onSubmitSuccess(); // Call the passed function on success
					}
				}
				utils.popupFormSetErrorForm(formRef, {
					visible: true,
					message: data.message,
					type: data.status ? "Success" : "Danger"
				});
			})
			.catch((error) => {
				console.error('Error submitting form:', error);
				// Handle error condition if needed
			})
			.finally(() => {
				if (typeof props.onSubmitSuccess !== 'function') {
					closeLoading(); // Close loading only if onSubmitSuccess is not a function
				}
			});
	}

	function handleNegativeValues(negativeValueChildren) {
		setNagativeValueChildren(negativeValueChildren);
		setDisplayStockNegativeValue(true);

	}

	function handleDirectSubmit({ stay }) {
		loading("Saving Stock Issue...");
		const childrenDetails = utils.childrenGridGetSource(childrenGridRef);
		const submittedForm = prepareSubmittedForm(childrenDetails);

		submitFormToApi(submittedForm);
	}



	function calculateSubTotal(rowData) {

		const quantity = parseFloat(rowData.Quantity);
		const unitCost = parseFloat(rowData.UnitCost);
		if (isNaN(quantity) || isNaN(unitCost)) {
			return 0;
		}
		const subTotal = utils.multiply(rowData.Quantity, rowData.UnitCost);
		return subTotal;
	}
	const calculateDataGridTotal = useCallback((data) => {
		return data.valueText.replace("Sum: ", "");
	}, []);

	return <div><PopupForm
		ref={formRef}
		childrenGridRef={childrenGridRef}
		onClosing={clearForm}
		fullScreen={false}
		width={"95%"}
		height={"90%"}
		disableButtons={props.lockedForm === true}
		headerClassName={"inventory-module-form-title-bg popup-form-title"}
		title={"Stock Issue"}
		onShown={onInitialized}
		onSuccessfulSubmit={({ stay }) => { if (stay) onInitialized() }}
		requiredColumns={GridRequiredColumns}
		filterRules={{
			Children:[
				{ name: "StockID", rules: [{ name: "Required" }] },
				{ name: "Description", rules: [{ name: "Required" }] },
				{ name: "Description2", rules: [{ name: "Required" }] },
				{ name: "Quantity", rules: [{ name: "Min", value: 1 }] },
			]
		}}
		copyFields={{
			Parent: formValue,
			Children : utils.childrenGridGetSource(childrenGridRef)
		}}
		onPasteForm={(e) => {
			tempForm.current = e.Parent;
			setFormValue({
				...e.Parent,
				MainLocalAmount: e.Parent.MainLocalAmount 
			});
		}}
		copyStorage={"StockIssueCopiedData"}
		formData={formValue}
		isEditing={isEditing}
		apiURL={"/api/StockIssue"}
		onValidating={setStartValidation}
		startValidation={startValidation}
		onSaving={handleSubmit}
		cancelDocumentCustomization={
			<span className='popup-form-header-cancel'>
				<CheckBox
					value={formValue["Cancelled"]}
					elementAttr={{ dataField: "Cancelled" }}
					onValueChanged={(e) => {
						valueOnChange(e);
					}}
					alignment='left'
					width={"12px"}
					height={"12px"}
					iconSize={"12px"}
					readOnly={props.lockedForm === true}
					className='popup-header-cancel-input'
				/>

				<span className='popup-header-cancel-text'>Cancelled</span>
			</span>
		}
	>
		<div className='popup-form-main-content'>
			<div className='inventory-module-parent-section custom-form-grid-section-60-40'>
				<div className='popup-form-item-container2'>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label next-doc-label'>Stock Issue No: </div>
						<div className='popup-group-form-input'>
							<div className='next-doc-display'>Next Doc: {formValue["NextDocNo"]}</div>
							<AsyncTextBox
								value={formValue["DocumentNo"]}
								elementAttr={{ dataField: "DocumentNo" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								placeholder={"<NEW>"}
								disabled={formID.current !== "new" || props.lockedForm === true}
								asyncURL={"api/StockIssue/CheckDuplicateDocNo"}
								asyncMessage={"Stock Issue No is already taken!"}
							/>

						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Reference No: </div>
						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["RefNo"]}
								elementAttr={{ dataField: "RefNo" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								readOnly={props.lockedForm === true}

							>
							</TextBox>
						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Date: </div>
						<div className='popup-group-form-input'>
							<DateBox
								value={formValue["DocumentDate"]}
								elementAttr={{ dataField: "DocumentDate" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								displayFormat='dd/MM/yyyy'
								dateSerializationFormat='yyyy-MM-dd'
								useMaskBehavior={true}
								readOnly={props.lockedForm === true}
							>
								<Validator>
									{startValidation !== 0 && <ValidatorRequired message={"Date is required!"} />}
								</Validator>
							</DateBox>
						</div>
					</div>
					<div className='popup-group-form-item'>
						<div className='popup-group-form-label '>Issue By: </div>
						<div className='popup-group-form-input'>
							<TextBox
								value={formValue["IssueBy"]}
								elementAttr={{ dataField: "IssueBy" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								readOnly={true}
							/>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Forex: </div>

						<div className='popup-group-form-input'>
							<CustomizedLookup
								ref={forexLookupRef}
								className={"ar-listing-datagrid"}
								dataSource={forexList}
								displayExpr={"code"}
								valueExpr={"id"}
								value={formValue["ForexID"]}
								onSelectionChanged={(e) => {
									valueOnChange(e, "ForexID");
								}}
								required={true}
								requiredMessage={"Forex is required!"}

								dataSourceURL={"api/Utilities/GetForexs"}
								onDataSourceChanged={setForexList}
								preread={true}>
								<Column dataField='code'></Column>
								<Column dataField='name'></Column>
							</CustomizedLookup>
						</div>
					</div>

					<div className='popup-group-form-item'>
						<div className='popup-group-form-label'>Local Rate: </div>

						<div className='popup-group-form-input'>
							<NumberBox
								value={formValue["LocalRate"]}
								elementAttr={{ dataField: "LocalRate" }}
								onValueChanged={(e) => {
									valueOnChange(e);
								}}
								alignment='left'
								width={"auto"}
								format={utils.LocalRateFormat}
								readOnly={formValue["ForexID"] === utils.getDefaultForexRM()}>
								<Validator>{startValidation !== 0 && <ValidatorRequired message={"Local Rate is required!"} />}</Validator>
							</NumberBox>
						</div>
					</div>
				</div>

				<div>
					<br />
					<div className='popup-group-form-item group-form-item-align-top'>
						<div className='popup-group-form-label next-doc-label'>Remarks: </div>
						<div className='popup-group-form-input'>
							<TextArea
								value={formValue["Remarks"]}
								elementAttr={{ dataField: "Remarks" }}
								onValueChanged={(e) => valueOnChange(e)}
								alignment='left'
								width={"auto"}
								height={116}
								readOnly={props.lockedForm === true}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className='children-datagrid-container inventory-module-children-datagrid' style={{ padding: "0 0" }}>
				<ChildrenDatagrid
					ref={childrenGridRef}
					name={"Children"}
					defaultColumnValues={onInitNew}
					lookupFields={["StockID", "ProjectID", "ChartAccountID", "TaxCodeID"]}
					keyExpr='ID'
					showBorders={true}
					loadPanel={false}
					allowColumnReordering={true}
					allowColumnResizing={true}
					onRowUpdating={onRowUpdating}
					onRowUpdated={onRowUpdated}
					onRowRemoved={onRowUpdated}
					disabled={props.lockedForm === true}
					gridLookupOpen={setGridLookupOpen}
					storageName={"StockIssuedChildrenGrid"}
					onParentValueChanged={valueOnChange}
					defaultDescription={formValue["Description"]}>
					<Column dataField='Line' dataType='number' defaultColumnIndex={0} />

					<Column
						visible={true}
						dataField='StockID'
						dataType='string'
						cellRender={(e) => {
							const data = e.data;
							var result = stockList.find((c) => c.id === data.StockID)?.code;
							result = data.StockID === null ? null : result;
							return result === undefined ? data.StockCode : result;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "StockID")}
								dataSourceURL={"/api/Utilities/GetStock"}
								onDataSourceChanged={setStockList}
								sourceList={stockList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>

					<Column dataField='Description' />

					<Column dataField='Description2' />

					<Column visible={true} dataField='MoreDescription' dataType='string' isHtmlEditor={true} />

					<Column
						visible={true}
						dataField='ProjectID'
						dataType='string'
						cellRender={(e) => {
							const data = e.data;
							var result = projectList.find((c) => c.id === data.ProjectID)?.code;
							result = data.ProjectID === null ? null : result;
							return result === undefined ? data.ProjectCode : result;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "ProjectID")}
								dataSourceURL={"/api/Utilities/GetProjects"}
								onDataSourceChanged={setProjectList}
								sourceList={projectList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}
						editorOptions={{ readOnly: true }}></Column>
					<Column
						visible={true}
						dataField='LocationID'
						caption="Location"
						dataType='string'
						cellRender={(e) => {
							const data = e.data;

							var result = locationList.find((c) => c.id === data.LocationID)?.code;
							result = data.LocationID === null ? null : result;
							return result === undefined ? data.LocationCode : result;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "LocationID")}
								dataSourceURL={"api/Utilities/GetLocations"}
								onDataSourceChanged={setLocationList}

								sourceList={locationList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>

					<Column
						visible={true}
						dataField='ShelfID'
						dataType='string'
						caption="Shelf"
						cellRender={(e) => {
							const data = e.data;

							var result = shelfList.find((c) => c.id === data.ShelfID)?.code;
							result = data.ShelfID === null ? null : result;
							return result === undefined ? data.ShelfCode : result;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "ShelfID")}
								dataSourceURL={"api/Utilities/GetShelf"}
								onDataSourceChanged={setShelfList}
								sourceList={shelfList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>



					<Column visible={true} dataField='UOM' editorOptions={{ readOnly: true }} />

					<Column visible={true} dataField='Quantity' dataType='number' caption="Quantity" width="100px" format={{ type: "fixedPoint", precision: 2 }} />

					<Column visible={false} dataField='ItemDiscount' dataType='number' />

					<Column visible={true} dataField='UnitCost' dataType='number' format={{ type: "fixedPoint", precision: 2 }} />

					<Column
						dataField='Sub Total'
						dataType='number'
						format={{ type: "fixedPoint", precision: 2 }}
						editorOptions={{ readOnly: true }}
						allowEditing={false}
						calculateCellValue={calculateSubTotal}
					/>



					<Column visible={false} dataField='NettPrice' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />


					<Column visible={false} dataField='TaxRate' dataType='number' editorOptions={{ readOnly: true }} />

					<Column visible={false} dataField='TaxInclusive' dataType='boolean' />

					<Column visible={false} dataField='ForexTaxable' dataType='number' format='#,##0.00' editorOptions={{ readOnly: true }} />

					<Column visible={false} dataField='ForexTaxAmount' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='ForexTaxablePlusTax' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='ItemDiscountAmt' dataType='number' />

					<Column
						visible={false}
						dataField='TaxCodeID'
						width={150}
						cellRender={(e) => {
							const data = e.data;
							var result = taxCodeList.find((c) => c.id === data.TaxCodeID)?.code;
							result = data.TaxCodeID === null ? null : result;
							return result === undefined ? data.TaxCode : result;
						}}
						editCellComponent={(component) => (
							<CustomizedGridLookup
								{...component}
								opened={gridLookupOpen}
								className={"inventory-listing-datagrid"}
								onSelectionChanged={(e) => gridOnSelectionChanged(e, "TaxCodeID")}
								dataSourceURL={"api/Utilities/GetGSTRate"}
								onDataSourceChanged={setTaxCodeList}
								sourceList={taxCodeList}>
								<Column dataField='code'></Column>
								<Column dataField='name' caption={"Description"} width={"300px"}></Column>
							</CustomizedGridLookup>
						)}></Column>

					<Column visible={false} dataField='PartNo' />

					<Column visible={false} dataField='BrandCode' />

					<Column visible={false} dataField='LinePrint' />

					<Column visible={false} dataField='PrintPrice' dataType='boolean' />

					<Column visible={false} dataField='Confirmation' dataType='boolean' />

					<Column visible={false} dataField='Show' dataType='boolean' />

					<Column visible={false} dataField='SalesType' />

					<Column visible={false} dataField='PriceCheckID' />

					<Column visible={false} dataField='SalesCode' />

					<Column visible={false} dataField='OrderType' />

					<Column visible={false} dataField='BOM' dataType='boolean' />

					<Column visible={false} dataField='ProductionNo' />

					<Column visible={false} dataField='Details' dataType='boolean' />



					<Column visible={false} dataField='ModelCode' />


					<Column visible={false} dataField='SerialNo' />

					<Column visible={false} dataField='Project' />

					<Column visible={false} dataField='GroupNo' />

					<Column visible={false} dataField='GroupUp' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='GroupAmt' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='BaseRate' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='PriceDisplay' dataType='boolean' />

					<Column visible={false} dataField='ForexTaxAmountOrignal' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='ForexTaxAdjust' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='ForexAmount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxable' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAmount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAmountOrignal' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxAdjust' dataType='number' editorOptions={{ readOnly: true }} format='#,##0.00' />

					<Column visible={false} dataField='LocalTaxablePlusTax' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='NettAmount' caption='Nett Amount' dataType='number' format='#,##0.00' />

					<Column visible={false} dataField='LocalAmount' dataType='number' format='#,##0.00' />

					<Summary recalculateWhileEditing={true}>
						<TotalItem column='ForexAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxable' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAmountOrignal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxAdjust' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalTaxablePlusTax' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettLocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettPrice' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ForexTaxAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='UniCost' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='NettAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='ItemDiscountAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='GroupUp' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='GroupAmt' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='BaseRate' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />

						<TotalItem column='LocalAmount' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />


						<TotalItem column='SubTotal' summaryType='sum' valueFormat={"#,##0.00"} customizeText={calculateDataGridTotal} />
					</Summary>
				</ChildrenDatagrid>
			</div>

			<div className='inventory-module-total-section custom-form-grid-section-70-30'>


				<div></div>
				<div className='popup-group-form-item'>
					<div className='popup-group-form-label'>Total: </div>

					<div className='popup-group-form-input'>
						<NumberBox
							value={formValue["MainLocalAmount"]}
							elementAttr={{ dataField: "MainLocalAmount" }}
							onValueChanged={(e) => {
								valueOnChange(e);
							}}
							alignment='left'
							width={"auto"}
							readOnly={true}
							format='#,##0.00'>
							{/* <Validator 
							>
								<ValidatorRequired message={"Outstanding is required!"} />
							</Validator> */}
						</NumberBox>
					</div>
				</div>
			</div>
		</div>
	</PopupForm>
		{displayStockNegativeValue && (
			<StockNegativeValueForm
				errorVisible={displayStockNegativeValue}

				dataSource={nagativeValueChildren}
				onValueChanged={(e) => {
					setDisplayStockNegativeValue(e.value);
				}}
				ReturnSaveResult={(e) => {
					handleDirectSubmit(e.canSave);
				}}
			/>
		)}

	</div>
}